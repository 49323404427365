/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Axios from 'axios';
import Header from './Header'
import getSvg from '../../utils/getSvg';
import * as formValidation from '../../utils/formValidation';
import {copyToClipBoard} from "../../utils/copyToClipBoard";

export default function ProNetwork() {
    const navigate = useNavigate();
    const [pros, setPros] = useState([]);
    const [proSubmissions, setProSubmissions] = useState([]);
    const [showProInfo, setShowProInfo] = useState(false);
    const [singlePro, setSinglePro] = useState([]);
    const surveyorId = sessionStorage.getItem('userId');
    const [showLoader, setShowLoader] = useState(false);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const handleGoToLogin = () => navigate('/login');

    const getPros = () => {
        setShowLoader(true);
        try {
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/get-pros`, {surveyorId: surveyorId}).then((response) => {
                setPros(response.data);
                setShowLoader(false);
            }).catch(function (error) {
                setShowLoader(false);
                console.log(error);
            })
        } catch (error) {
            setShowLoader(false);
        }
    }

    const goToPackage = (packageName) => {
        navigate(`/survdetails/${packageName}`);
    }


    const getPackageAddress = (pro) => {
        let fullAddress = `${pro.packageAddress}`;
        if (pro.packageAddress2 !== null && pro.packageAddress2.trim() !== '') {
            fullAddress = `${fullAddress}, ${pro.packageAddress2}, `;
        } else {
            fullAddress = `${fullAddress}, `;
        }
        fullAddress = `${fullAddress}${pro.packageCity}, `;
        fullAddress = `${fullAddress}${pro.packageState} `;
        fullAddress = `${fullAddress}${pro.packageZip}`;
        return fullAddress;
    }

    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            getPros(surveyorId);
        }
    }, []);

    const getAddresses = (pck) => {
        let fullAddress = '';
        if (pck.packageAddress !== '') {
            fullAddress = `${pck.packageAddress}`;
        }
        if (pck.packageAddress2.trim() !== '') {
            fullAddress = `${fullAddress}, ${pck.packageAddress2}, `;
        } else {
            fullAddress = `${fullAddress}, `;
        }
        if (pck.packageCity !== '') {
            fullAddress = `${fullAddress}${pck.packageCity}, `;
        }
        if (pck.packageState !== '') {
            fullAddress = `${fullAddress}${pck.packageState} `;
        }
        if (pck.packageZip !== '') {
            fullAddress = `${fullAddress}${pck.packageZip}`;
        }
        return fullAddress;
    }

    const getProSubmissions = (Id) => {
        setShowLoader(true);
        try {
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/get-pro-submissions`, {userId: Id}).then((response) => {
                setProSubmissions(response.data);
                setShowLoader(false);
            }).catch(function (error) {
                setShowLoader(false);
                console.log(error);
            })
        } catch (error) {
            setShowLoader(false);
        }
    }

    const handleCopyToClipboard = (textToCopy) => {
        copyToClipBoard(textToCopy)
    };

    return (
        <div id="fazzad-site-container">
            <Header
                mode='default'
            />
            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <>
                                <li>
                                    <a href='' onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/survey')
                                    }}>Home</a>
                                    <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                </li>
                                <li aria-current="page">
                                    <span>My network</span>
                                </li>
                            </>
                        </ol>
                    </nav>
                    <div id="package-title" className="nowrap">
                        <h1 className="h3">My network of Pros & Vendors</h1>
                    </div>
                    {showLoader && (<span className="loader loader--l"></span>)}

                    <div className="pro-vendor__container">
                        <div className="pro-vendor">
                            <div className="content">
                                {pros.map((pro, index) => {
                                    return (
                                        <>
                                            <div style={((pros.length - 1) !== index) ? {
                                                borderBottom: '1px solid #5F5F5F',
                                                paddingBottom: '16px',
                                                marginBottom: '16px'
                                            } : {}}>
                                                <div key={`div_${index}`}>
                                                    <div className="pro-vendor__title" key={`divtitle_${index}`}>
                                                        <h3 className="h5">{pro.firstName} {pro.lastName}</h3>
                                                    </div>

                                                    {pro.packageName !== null && pro.packageName !== '' && (
                                                        <div>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                goToPackage(pro.packageName)
                                                            }}>{getPackageAddress(pro)}</a>
                                                            <button title="Copy!"
                                                                    className="button button-icon button-xs"
                                                                    onClick={() => handleCopyToClipboard(getPackageAddress(pro))}>
                                                                <span className="icon"><svg width="24" height="24"
                                                                                            viewBox="0 0 24 24"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"><path
                                                                    d="M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z"
                                                                    stroke="currentColor" strokeWidth="2"
                                                                    strokeLinecap="round" strokeLinejoin="round"></path></svg></span>
                                                            </button>
                                                        </div>
                                                    )}

                                                    <div>{pro.phone}
                                                        <button title="Copy!" className="button button-icon button-xs"
                                                                onClick={() => handleCopyToClipboard(formValidation.formatPhoneNumber(pro.phone))}>
                                                        <span className="icon"><svg width="24" height="24"
                                                                                    viewBox="0 0 24 24" fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"><path
                                                            d="M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z"
                                                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path></svg></span></button>
                                                    </div>
                                                    <div>{pro.email}
                                                        {<button title="Copy!" className="button button-icon button-xs"
                                                                 onClick={() => handleCopyToClipboard(pro.email)}><span
                                                            className="icon"><svg width="24" height="24"
                                                                                  viewBox="0 0 24 24"
                                                                                  fill="none"
                                                                                  xmlns="http://www.w3.org/2000/svg"><path
                                                            d="M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z"
                                                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path></svg></span></button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                    {/* <div className="package-files-container">
                        <div className="package-files__list">
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Company</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pros.map((pro, index) => {
                                            return(
                                                <tr key={`tr_${index}`}>
                                                    <td key={`td_${index}`} className="fz-break-all">
                                                            <a href="/" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowProInfo(true);
                                                                setSinglePro(pro);
                                                                getProSubmissions(pro.userId);
                                                            }}>{pro.firstName} {pro.lastName}</a>
                                                    </td>
                                                    <td key={`td_${index}`} className="fz-break-all">
                                                        {pro.company}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>
            </main>
            {showProInfo && (
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">{singlePro.firstName} {singlePro.lastName}</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => {
                                        setShowProInfo(false);
                                    }}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>Company:&nbsp;{singlePro.company}</p>
                            <p>License #:&nbsp;{singlePro.license}</p>
                            <p>
                                Email:&nbsp;{singlePro.email}&nbsp;
                                <button
                                    title="Copy!"
                                    className="button button-icon button-xs"
                                    onClick={() => handleCopyToClipboard(singlePro.email)}
                                >
                                    {getSvg('copy')}
                                </button>
                            </p>
                            <p>
                                Phone:&nbsp;{formValidation.formatPhoneNumber(singlePro.phone)}&nbsp;
                                <button
                                    title="Copy!"
                                    className="button button-icon button-xs"
                                    onClick={() => handleCopyToClipboard(singlePro.phone)}
                                >
                                    {getSvg('copy')}
                                </button>
                            </p>
                            {proSubmissions.length > 0 && (
                                <>
                                    <hr/>
                                    <div className="table-container">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>Projects contacted:</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {proSubmissions.map((submission, index) => {
                                                return (
                                                    <tr key={`tr_${index}`}>
                                                        <td key={`td_${index}`} className="fz-break-all">
                                                            <a href="" onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate(`/survdetails/${submission.packageName}`)
                                                            }}>{getAddresses(submission)}</a>
                                                            &nbsp;
                                                            <button
                                                                title="Copy!"
                                                                className="button button-icon button-xs"
                                                                onClick={() => handleCopyToClipboard(getAddresses(submission))}
                                                            >
                                                                {getSvg('copy')}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
