import React from 'react';
import getSvg from '../../utils/getSvg';
import { useNavigate } from 'react-router-dom';
function ModalInfo({messageHeader, messageBody, messageType='', showCloseButton=true, showLoader=false}){
    const navigate = useNavigate();
    let svgIcon = '';
    switch(messageType){
        case 'success':
            svgIcon = 'success';
            messageType = 'text-success';
            break;
        case 'error':
            svgIcon = 'error';
            messageType = 'text-danger';
            break;
        case 'warning':
            svgIcon = 'warning';
            messageType = 'text-danger';
            break;
        case 'informational':
            svgIcon = '';
            messageType = 'text-danger';
            break;
        default:
            break;

    }
    return(
        <div className="modal-container">
            <div className="modal">
                <div className="modal__header">
                    {getSvg(svgIcon)}
                    <h2 className={`modal-title h4 ${messageType}`}>
                        {messageHeader}
                    </h2>
                    {showLoader ?
                        <span className="loader loader--s"></span>
                    : ''
                    }
                    {showCloseButton ?
                        <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => navigate('/')}>
                            {getSvg('close')}
                        </button>
                    : ''
                    }
                </div>
                <div className="modal__content">
                    <p dangerouslySetInnerHTML={{ __html: messageBody}} />
                </div>
            </div>
        </div>
    )
}

export default ModalInfo;