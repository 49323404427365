const getSvg = (svgName, eleKey, size = 24, width = 1) => {
    switch (svgName) {
        case 'delete':
            return (
                <span id={eleKey} className="icon" key={eleKey}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'like':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'responses':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 8.5H12M7 12H15M9.68375 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V20.3355C3 20.8684 3 21.1348 3.10923 21.2716C3.20422 21.3906 3.34827 21.4599 3.50054 21.4597C3.67563 21.4595 3.88367 21.2931 4.29976 20.9602L6.68521 19.0518C7.17252 18.662 7.41617 18.4671 7.68749 18.3285C7.9282 18.2055 8.18443 18.1156 8.44921 18.0613C8.74767 18 9.0597 18 9.68375 18Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'image':
            return (
                <span className="icon">
                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.27209 20.7279L10.8686 14.1314C11.2646 13.7354 11.4627 13.5373 11.691 13.4632C11.8918 13.3979 12.1082 13.3979 12.309 13.4632C12.5373 13.5373 12.7354 13.7354 13.1314 14.1314L19.6839 20.6839M14 15L16.8686 12.1314C17.2646 11.7354 17.4627 11.5373 17.691 11.4632C17.8918 11.3979 18.1082 11.3979 18.309 11.4632C18.5373 11.5373 18.7354 11.7354 19.1314 12.1314L22 15M10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9ZM6.8 21H17.2C18.8802 21 19.7202 21 20.362 20.673C20.9265 20.3854 21.3854 19.9265 21.673 19.362C22 18.7202 22 17.8802 22 16.2V7.8C22 6.11984 22 5.27976 21.673 4.63803C21.3854 4.07354 20.9265 3.6146 20.362 3.32698C19.7202 3 18.8802 3 17.2 3H6.8C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8V16.2C2 17.8802 2 18.7202 2.32698 19.362C2.6146 19.9265 3.07354 20.3854 3.63803 20.673C4.27976 21 5.11984 21 6.8 21Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'construction':
            return (
                <span className="icon has-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L10.5 10.5M6 6H3L2 3L3 2L6 3V6ZM19.259 2.74101L16.6314 5.36863C16.2354 5.76465 16.0373 5.96265 15.9632 6.19098C15.8979 6.39183 15.8979 6.60817 15.9632 6.80902C16.0373 7.03735 16.2354 7.23535 16.6314 7.63137L16.8686 7.86863C17.2646 8.26465 17.4627 8.46265 17.691 8.53684C17.8918 8.6021 18.1082 8.6021 18.309 8.53684C18.5373 8.46265 18.7354 8.26465 19.1314 7.86863L21.5893 5.41072C21.854 6.05488 22 6.76039 22 7.5C22 10.5376 19.5376 13 16.5 13C16.1338 13 15.7759 12.9642 15.4298 12.8959C14.9436 12.8001 14.7005 12.7521 14.5532 12.7668C14.3965 12.7824 14.3193 12.8059 14.1805 12.8802C14.0499 12.9501 13.919 13.081 13.657 13.343L6.5 20.5C5.67157 21.3284 4.32843 21.3284 3.5 20.5C2.67157 19.6716 2.67157 18.3284 3.5 17.5L10.657 10.343C10.919 10.081 11.0499 9.95005 11.1198 9.81949C11.1941 9.68068 11.2176 9.60347 11.2332 9.44681C11.2479 9.29945 11.1999 9.05638 11.1041 8.57024C11.0358 8.22406 11 7.86621 11 7.5C11 4.46243 13.4624 2 16.5 2C17.5055 2 18.448 2.26982 19.259 2.74101ZM12.0001 14.9999L17.5 20.4999C18.3284 21.3283 19.6716 21.3283 20.5 20.4999C21.3284 19.6715 21.3284 18.3283 20.5 17.4999L15.9753 12.9753C15.655 12.945 15.3427 12.8872 15.0408 12.8043C14.6517 12.6975 14.2249 12.7751 13.9397 13.0603L12.0001 14.9999Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'alert':
            return (
                <span className="icon">
                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.9998 8.99999V13M11.9998 17H12.0098M10.6151 3.89171L2.39019 18.0983C1.93398 18.8863 1.70588 19.2803 1.73959 19.6037C1.769 19.8857 1.91677 20.142 2.14613 20.3088C2.40908 20.5 2.86435 20.5 3.77487 20.5H20.2246C21.1352 20.5 21.5904 20.5 21.8534 20.3088C22.0827 20.142 22.2305 19.8857 22.2599 19.6037C22.2936 19.2803 22.0655 18.8863 21.6093 18.0983L13.3844 3.89171C12.9299 3.10654 12.7026 2.71396 12.4061 2.58211C12.1474 2.4671 11.8521 2.4671 11.5935 2.58211C11.2969 2.71396 11.0696 3.10655 10.6151 3.89171Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'survey':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4C16.93 4 17.395 4 17.7765 4.10222C18.8117 4.37962 19.6204 5.18827 19.8978 6.22354C20 6.60504 20 7.07003 20 8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V8C4 7.07003 4 6.60504 4.10222 6.22354C4.37962 5.18827 5.18827 4.37962 6.22354 4.10222C6.60504 4 7.07003 4 8 4M9.6 6H14.4C14.9601 6 15.2401 6 15.454 5.89101C15.6422 5.79513 15.7951 5.64215 15.891 5.45399C16 5.24008 16 4.96005 16 4.4V3.6C16 3.03995 16 2.75992 15.891 2.54601C15.7951 2.35785 15.6422 2.20487 15.454 2.10899C15.2401 2 14.9601 2 14.4 2H9.6C9.03995 2 8.75992 2 8.54601 2.10899C8.35785 2.20487 8.20487 2.35785 8.10899 2.54601C8 2.75992 8 3.03995 8 3.6V4.4C8 4.96005 8 5.24008 8.10899 5.45399C8.20487 5.64215 8.35785 5.79513 8.54601 5.89101C8.75992 6 9.03995 6 9.6 6Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'copy':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'portfolio':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{height: size, width: size }}>
                        <path d="M8 21V7C8 6.07003 8 5.60504 8.10222 5.22354C8.37962 4.18827 9.18827 3.37962 10.2235 3.10222C10.605 3 11.07 3 12 3C12.93 3 13.395 3 13.7765 3.10222C14.8117 3.37962 15.6204 4.18827 15.8978 5.22354C16 5.60504 16 6.07003 16 7V21M5.2 21H18.8C19.9201 21 20.4802 21 20.908 20.782C21.2843 20.5903 21.5903 20.2843 21.782 19.908C22 19.4802 22 18.9201 22 17.8V10.2C22 9.07989 22 8.51984 21.782 8.09202C21.5903 7.71569 21.2843 7.40973 20.908 7.21799C20.4802 7 19.9201 7 18.8 7H5.2C4.07989 7 3.51984 7 3.09202 7.21799C2.71569 7.40973 2.40973 7.71569 2.21799 8.09202C2 8.51984 2 9.07989 2 10.2V17.8C2 18.9201 2 19.4802 2.21799 19.908C2.40973 20.2843 2.71569 20.5903 3.09202 20.782C3.51984 21 4.0799 21 5.2 21Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{strokeWidth: width}}/>
                    </svg>
                </span>
            );
        case 'close':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'x':
            return (
                <span className="icon icon-x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'person':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'error':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 8.00008V12.0001M12 16.0001H12.01M3 7.94153V16.0586C3 16.4013 3 16.5726 3.05048 16.7254C3.09515 16.8606 3.16816 16.9847 3.26463 17.0893C3.37369 17.2077 3.52345 17.2909 3.82297 17.4573L11.223 21.5684C11.5066 21.726 11.6484 21.8047 11.7985 21.8356C11.9315 21.863 12.0685 21.863 12.2015 21.8356C12.3516 21.8047 12.4934 21.726 12.777 21.5684L20.177 17.4573C20.4766 17.2909 20.6263 17.2077 20.7354 17.0893C20.8318 16.9847 20.9049 16.8606 20.9495 16.7254C21 16.5726 21 16.4013 21 16.0586V7.94153C21 7.59889 21 7.42756 20.9495 7.27477C20.9049 7.13959 20.8318 7.01551 20.7354 6.91082C20.6263 6.79248 20.4766 6.70928 20.177 6.54288L12.777 2.43177C12.4934 2.27421 12.3516 2.19543 12.2015 2.16454C12.0685 2.13721 11.9315 2.13721 11.7985 2.16454C11.6484 2.19543 11.5066 2.27421 11.223 2.43177L3.82297 6.54288C3.52345 6.70928 3.37369 6.79248 3.26463 6.91082C3.16816 7.01551 3.09515 7.13959 3.05048 7.27477C3 7.42756 3 7.59889 3 7.94153Z" stroke="#BD1809" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'warning':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.9998 8.99999V13M11.9998 17H12.0098M10.6151 3.89171L2.39019 18.0983C1.93398 18.8863 1.70588 19.2803 1.73959 19.6037C1.769 19.8857 1.91677 20.142 2.14613 20.3088C2.40908 20.5 2.86435 20.5 3.77487 20.5H20.2246C21.1352 20.5 21.5904 20.5 21.8534 20.3088C22.0827 20.142 22.2305 19.8857 22.2599 19.6037C22.2936 19.2803 22.0655 18.8863 21.6093 18.0983L13.3844 3.89171C12.9299 3.10654 12.7026 2.71396 12.4061 2.58211C12.1474 2.4671 11.8521 2.4671 11.5935 2.58211C11.2969 2.71396 11.0696 3.10655 10.6151 3.89171Z" stroke="#944B22" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'success':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#007000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'plus':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 5V19M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'minus':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'testimonial':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2827 3.45332C11.5131 2.98638 11.6284 2.75291 11.7848 2.67831C11.9209 2.61341 12.0791 2.61341 12.2152 2.67831C12.3717 2.75291 12.4869 2.98638 12.7174 3.45332L14.9041 7.88328C14.9721 8.02113 15.0061 8.09006 15.0558 8.14358C15.0999 8.19096 15.1527 8.22935 15.2113 8.25662C15.2776 8.28742 15.3536 8.29854 15.5057 8.32077L20.397 9.03571C20.9121 9.11099 21.1696 9.14863 21.2888 9.27444C21.3925 9.38389 21.4412 9.5343 21.4215 9.68377C21.3988 9.85558 21.2124 10.0372 20.8395 10.4004L17.3014 13.8464C17.1912 13.9538 17.136 14.0076 17.1004 14.0715C17.0689 14.128 17.0487 14.1902 17.0409 14.2545C17.0321 14.3271 17.0451 14.403 17.0711 14.5547L17.906 19.4221C17.994 19.9355 18.038 20.1922 17.9553 20.3445C17.8833 20.477 17.7554 20.57 17.6071 20.5975C17.4366 20.6291 17.2061 20.5078 16.7451 20.2654L12.3724 17.9658C12.2361 17.8942 12.168 17.8584 12.0962 17.8443C12.0327 17.8318 11.9673 17.8318 11.9038 17.8443C11.832 17.8584 11.7639 17.8942 11.6277 17.9658L7.25492 20.2654C6.79392 20.5078 6.56341 20.6291 6.39297 20.5975C6.24468 20.57 6.11672 20.477 6.04474 20.3445C5.962 20.1922 6.00603 19.9355 6.09407 19.4221L6.92889 14.5547C6.95491 14.403 6.96793 14.3271 6.95912 14.2545C6.95132 14.1902 6.93111 14.128 6.89961 14.0715C6.86402 14.0076 6.80888 13.9538 6.69859 13.8464L3.16056 10.4004C2.78766 10.0372 2.60121 9.85558 2.57853 9.68377C2.55879 9.5343 2.60755 9.38389 2.71125 9.27444C2.83044 9.14863 3.08797 9.11099 3.60304 9.03571L8.49431 8.32077C8.64642 8.29854 8.72248 8.28742 8.78872 8.25662C8.84736 8.22935 8.90016 8.19096 8.94419 8.14358C8.99391 8.09006 9.02793 8.02113 9.09597 7.88328L11.2827 3.45332Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'palette':
            return (
                <span className="icon has-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 12C2 17.5228 6.47715 22 12 22C13.6569 22 15 20.6569 15 19V18.5C15 18.0356 15 17.8034 15.0257 17.6084C15.2029 16.2622 16.2622 15.2029 17.6084 15.0257C17.8034 15 18.0356 15 18.5 15H19C20.6569 15 22 13.6569 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8C15 8.55228 15.4477 9 16 9Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                </span>
            );
        case 'filters':
            return (
                <span className="icon">
                    <svg width="100%" height="100%" viewBox="0 0 88 44" xmlns="http://www.w3.org/2000/svg" fill="currentColor" >
                        <path d="M30.625,35c1.283,0 2.333,0.438 3.15,1.313c0.817,0.874 1.225,1.895 1.225,3.062c0,1.167 -0.438,2.187 -1.313,3.063c-0.874,0.874 -1.895,1.312 -3.062,1.312l-26.25,0c-1.167,0 -2.187,-0.438 -3.062,-1.313c-0.875,-0.875 -1.313,-1.895 -1.313,-3.062c0,-1.167 0.408,-2.188 1.225,-3.063c0.817,-0.874 1.867,-1.312 3.15,-1.312l26.25,0Zm54.25,-17.5c1.75,-0 2.625,1.458 2.625,4.375c-0,2.917 -0.875,4.375 -2.625,4.375l-14.875,0l-0,14.875c-0,1.75 -1.458,2.625 -4.375,2.625c-2.917,0 -4.375,-0.875 -4.375,-2.625l-0,-14.875l-14.35,0c-1.75,0 -2.625,-1.458 -2.625,-4.375c0,-2.917 0.875,-4.375 2.625,-4.375l14.35,-0l-0,-14.875c-0,-1.75 1.458,-2.625 4.375,-2.625c2.917,0 4.375,0.875 4.375,2.625l-0,14.875l14.875,-0Zm-54.25,-0c1.283,-0 2.333,0.438 3.15,1.313c0.817,0.874 1.225,1.895 1.225,3.062c0,1.167 -0.438,2.187 -1.313,3.062c-0.874,0.875 -1.895,1.313 -3.062,1.313l-26.25,0c-1.167,0 -2.187,-0.438 -3.062,-1.313c-0.875,-0.875 -1.313,-1.895 -1.313,-3.062c0,-1.167 0.408,-2.188 1.225,-3.063c0.817,-0.875 1.867,-1.312 3.15,-1.312l26.25,-0Zm-0,-17.5c1.283,0 2.333,0.438 3.15,1.312c0.817,0.875 1.225,1.896 1.225,3.063c0,1.167 -0.438,2.187 -1.313,3.063c-0.874,0.874 -1.895,1.312 -3.062,1.312l-26.25,-0c-1.167,-0 -2.187,-0.438 -3.062,-1.313c-0.875,-0.875 -1.313,-1.895 -1.313,-3.062c0,-1.167 0.408,-2.188 1.225,-3.063c0.817,-0.874 1.867,-1.312 3.15,-1.312l26.25,0Z"/>
                    </svg>
                </span>
            );
        case 'chevron-up':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 15L12 9L6 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );            
        case 'chevron-right':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );            
        case 'chevron-left':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'chevron-down':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'back':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'reels':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 12H22M2 7H7M17 7H22M2 17H7M17 17H22M7 22V2M17 22V2M6.8 22H17.2C18.8802 22 19.7202 22 20.362 21.673C20.9265 21.3854 21.3854 20.9265 21.673 20.362C22 19.7202 22 18.8802 22 17.2V6.8C22 5.11984 22 4.27976 21.673 3.63803C21.3854 3.07354 20.9265 2.6146 20.362 2.32698C19.7202 2 18.8802 2 17.2 2H6.8C5.11984 2 4.27976 2 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803C2 4.27976 2 5.11984 2 6.8V17.2C2 18.8802 2 19.7202 2.32698 20.362C2.6146 20.9265 3.07354 21.3854 3.63803 21.673C4.27976 22 5.11984 22 6.8 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'circle-checkmark':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#007000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'gallery':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 9H21M3 15H21M12 3V21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'upload':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'download':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'liked':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'green-checkmark':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#007000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'edit':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 10L14 6M2.49997 21.5L5.88434 21.124C6.29783 21.078 6.50457 21.055 6.69782 20.9925C6.86926 20.937 7.03242 20.8586 7.18286 20.7594C7.35242 20.6475 7.49951 20.5005 7.7937 20.2063L21 7C22.1046 5.89543 22.1046 4.10457 21 3C19.8954 1.89543 18.1046 1.89543 17 3L3.7937 16.2063C3.49952 16.5005 3.35242 16.6475 3.24061 16.8171C3.1414 16.9676 3.06298 17.1307 3.00748 17.3022C2.94493 17.4954 2.92195 17.7021 2.87601 18.1156L2.49997 21.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'pdf':
            return (
                <span className="icon">
                    <svg width="39" height="48" viewBox="0 0 39 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.4 12.05L26.45 1.1C25.75 0.4 24.8 0 23.85 0H4.2C3.2 0 2.3 0.4 1.6 1.1C0.9 1.8 0.5 2.7 0.5 3.7V43.8C0.5 45.85 2.15 47.5 4.2 47.5H34.8C36.85 47.5 38.5 45.85 38.5 43.8V14.65C38.5 13.7 38.1 12.75 37.4 12.05ZM35.3 12.95H27.2C26.3 12.95 25.55 12.2 25.55 11.3V3.25L35.3 12.95ZM34.8 45.35H4.2C3.35 45.35 2.65 44.65 2.65 43.8V3.7C2.65 3.3 2.8 2.9 3.1 2.6C3.4 2.3 3.8 2.15 4.2 2.15H23.4V11.3C23.4 13.4 25.1 15.1 27.2 15.1H36.35V43.8C36.35 44.65 35.65 45.35 34.8 45.35Z" fill="currentColor"/>
                        <path d="M24.4341 32.0799C23.513 31.3284 22.592 30.3889 21.5394 29.3868C20.8157 28.6352 20.0262 27.5705 19.3683 26.4431C20.6183 22.372 21.2104 18.4889 19.8946 16.6099C19.3025 15.7331 18.3815 15.2947 17.1973 15.2947C15.8815 15.2947 14.8946 15.8584 14.4341 16.9231C13.3815 19.1778 14.9604 23.3115 16.8683 26.6936C16.342 28.322 15.6841 30.0757 14.8946 32.0173C14.4341 33.082 13.9078 34.1468 13.3815 35.1489C7.98673 37.1531 4.69725 39.4078 4.23672 41.5373C4.03936 42.5394 4.3683 43.4163 5.28936 44.1052C5.81567 44.481 6.40778 44.7315 7.06567 44.7315C7.32883 44.7315 7.59199 44.6689 7.85515 44.6063C10.3551 43.9799 13.0525 40.4726 15.0262 36.9026C16.2104 36.4642 17.592 36.0257 19.1051 35.6499C20.8815 35.1489 22.4604 34.7731 23.842 34.5226C26.4078 36.4641 28.5788 37.5289 30.4867 37.5289C31.9341 37.5915 32.9867 37.0905 33.9736 36.151C35.4209 34.7731 34.8288 33.5831 34.6315 33.2699C33.4473 31.5163 29.8288 31.1405 24.4341 32.0799ZM7.19725 42.4142C6.93409 42.4768 6.80251 42.4141 6.67094 42.3515C6.40778 42.1636 6.40778 42.0384 6.47357 41.9131C6.60515 41.1615 8.1183 39.7836 11.4078 38.2805C9.82883 40.5352 8.24988 42.1636 7.19725 42.4142ZM16.5394 17.7999C16.6051 17.6747 16.6709 17.4868 17.1973 17.4868C17.6578 17.4868 17.8551 17.6747 17.9867 17.7999C18.5788 18.6142 18.513 20.681 17.7894 23.4368C16.6709 21.0568 16.0788 18.802 16.5394 17.7999ZM18.3815 33.4578C17.7236 33.6457 17.0657 33.8336 16.4078 34.0215C16.6051 33.5831 16.8025 33.1447 16.9999 32.7689C17.4604 31.5789 17.9867 30.3889 18.4473 29.1363C18.9736 29.8252 19.4341 30.4515 19.8946 30.8899C20.4867 31.5163 21.0788 32.0799 21.6709 32.6436C20.6183 32.8941 19.4999 33.1447 18.3815 33.4578ZM32.263 34.5852C31.8025 35.0863 31.2104 35.2742 30.4867 35.2742C29.4999 35.2742 28.3157 34.8357 26.9341 33.9589C30.7499 33.4578 32.9209 33.8336 32.263 34.5852Z" fill="currentColor"/>
                    </svg>
                </span>
            );
        case 'cad':
            return (
                <span className="icon">
                    <svg width="32" height="32" viewBox="0 0 82 88" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <path d="M77.688,52.52c1.767,0.952 2.889,2.414 3.365,4.386c0.476,1.971 0.238,3.841 -0.714,5.609l-3.569,6.22c-1.02,1.768 -2.516,2.89 -4.487,3.366c-1.972,0.476 -3.808,0.238 -5.507,-0.714l-15.196,-8.668l0,17.438c0,1.972 -0.713,3.672 -2.141,5.099c-1.428,1.428 -3.128,2.176 -5.099,2.244l-7.241,-0c-1.972,-0 -3.705,-0.748 -5.201,-2.244c-1.496,-1.495 -2.21,-3.195 -2.142,-5.099l0,-17.438l-15.093,8.668c-1.768,1.02 -3.637,1.258 -5.609,0.714c-1.971,-0.544 -3.433,-1.666 -4.385,-3.366l-3.671,-6.22c-0.952,-1.768 -1.19,-3.638 -0.714,-5.609c0.476,-1.972 1.631,-3.434 3.467,-4.386l15.093,-8.77l-15.093,-8.77c-1.768,-0.952 -2.923,-2.414 -3.467,-4.386c-0.544,-1.971 -0.306,-3.841 0.714,-5.609l3.671,-6.22c1.02,-1.768 2.482,-2.89 4.385,-3.366c1.904,-0.476 3.773,-0.238 5.609,0.714l15.093,8.668l0,-17.438c0,-1.972 0.714,-3.672 2.142,-5.099c1.428,-1.428 3.161,-2.176 5.201,-2.244l7.241,-0c1.971,-0 3.671,0.748 5.099,2.244c1.428,1.495 2.141,3.195 2.141,5.099l0,17.438l15.196,-8.668c1.767,-1.02 3.603,-1.258 5.507,-0.714c1.903,0.544 3.399,1.666 4.487,3.366l3.569,6.22c1.02,1.768 1.258,3.638 0.714,5.609c-0.544,1.972 -1.666,3.434 -3.365,4.386l-15.094,8.77l15.094,8.77Z" fill="currentColor" />
                    </svg>
                </span>
            );
        case 'cad-illustration':
            return (
                <span className="icon">
                    <svg
width="48"
height="48"
viewBox="0 0 48 48"
xmlns="http://www.w3.org/2000/svg"
fill={sessionStorage.getItem("isPropertyOwner") === "true" ? 'var(--color-purple--400)' : 'currentColor'}
style={{
fillRule: 'evenodd',
clipRule: 'evenodd',
strokeLinejoin: 'round',
strokeMiterlimit: 2,
}}
>
<path fillRule="evenodd" clipRule="evenodd" d="M22.113 25.8338C22.485 25.7255 22.827 25.5326 23.1119 25.2701C23.4594 24.9199 23.7187 24.4922 23.8683 24.0222C24.0227 23.5365 24.0961 23.0288 24.0854 22.5193C24.0994 21.9542 24.0213 21.3907 23.8542 20.8507C23.7163 20.4241 23.487 20.0326 23.1824 19.7036C22.8922 19.3951 22.5242 19.1705 22.1172 19.0532C21.6771 18.9488 21.225 18.9035 20.7729 18.9187H18.1646V25.9824H20.849C21.2754 25.9957 21.7013 25.9456 22.113 25.8338ZM19.5928 24.793V20.1158H20.2336C20.6252 20.1039 21.0171 20.1275 21.4045 20.1863C21.6512 20.2339 21.8799 20.349 22.065 20.5188C22.2549 20.7096 22.3939 20.9448 22.4697 21.203C22.5808 21.6124 22.6295 22.0362 22.6142 22.4601C22.6274 22.8959 22.5788 23.3315 22.4697 23.7537C22.4072 24.0097 22.2784 24.2448 22.0961 24.4351C21.9316 24.5795 21.7336 24.6805 21.5201 24.7289C21.236 24.7843 20.9466 24.808 20.6573 24.7994L19.5928 24.793Z" fill="currentColor"/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.4131 25.9775H15.8622L15.244 24.3731H12.42L11.837 25.9768H10.3242L13.0735 18.9158H14.5821L17.4131 25.9775ZM13.8144 20.5632L12.8599 23.1837H14.7879L13.8144 20.5632Z" fill="currentColor"/>
<path d="M6.87025 25.0022C7.23033 25.0093 7.57978 24.8802 7.84871 24.6408C8.15876 24.3315 8.36432 23.9332 8.43663 23.5014L9.81972 23.9397C9.66851 24.6138 9.29635 25.2182 8.76231 25.6568C8.21667 26.0481 7.55611 26.2465 6.88506 26.2205C6.45333 26.2339 6.0237 26.1551 5.62486 25.9894C5.22601 25.8237 4.86712 25.5748 4.57215 25.2594C3.93604 24.5369 3.60901 23.5936 3.66137 22.6326C3.59944 21.6363 3.92585 20.6547 4.57215 19.8938C4.8788 19.5695 5.25139 19.3147 5.66482 19.1465C6.07825 18.9783 6.52294 18.9006 6.96895 18.9186C7.74488 18.8853 8.50244 19.1604 9.07601 19.6838C9.43023 20.044 9.67996 20.4935 9.79857 20.9845L8.38869 21.322C8.32348 20.9791 8.13738 20.6709 7.8642 20.4534C7.59101 20.236 7.24885 20.1236 6.89986 20.1369C6.65871 20.1292 6.41893 20.1758 6.19829 20.2734C5.97765 20.371 5.78183 20.517 5.62533 20.7005C5.2526 21.2327 5.07938 21.8791 5.13611 22.5262C5.07465 23.1983 5.2445 23.8711 5.61758 24.4336C5.76921 24.6175 5.96091 24.7644 6.17802 24.863C6.39513 24.9615 6.63191 25.0091 6.87025 25.0022Z" fill="currentColor"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.3404 44.0274L46.0269 34.3453C46.6462 33.7263 47 32.8863 47 32.0463L47 6.27158C47 4.45895 45.5404 3 43.7269 3L16.6577 3C14.8442 3 13.3846 4.45895 13.3846 6.27158L13.3846 15.3789H15.2866L15.2866 6.27158C15.2866 5.52 15.9058 4.90106 16.6577 4.90106L43.7269 4.90105C44.4789 4.90105 45.0981 5.52 45.0981 6.27158L45.0981 31.6484H37.0039C35.1462 31.6484 33.6423 33.1516 33.6423 35.0084V43.0989H16.6577C16.3039 43.0989 15.95 42.9663 15.6846 42.7011C15.4193 42.4358 15.2866 42.0821 15.2866 41.7284L15.2866 29.5263H24.6538C25.7584 29.5263 26.6538 28.6309 26.6538 27.5263V17.379C26.6538 16.2744 25.7584 15.379 24.6538 15.379H3C1.89543 15.379 1 16.2744 1 17.379V27.5263C1 28.6309 1.89543 29.5263 3 29.5263H13.3846L13.3846 41.7284C13.3846 42.6126 13.7385 43.4084 14.3577 44.0274C14.9769 44.6463 15.7731 45 16.6577 45H34.0404C34.8808 45 35.7212 44.6463 36.3404 44.0274ZM37.0039 33.5495H44.1693L35.5443 42.1263V35.0084C35.5443 34.2126 36.2077 33.5495 37.0039 33.5495ZM24.6538 17.379H3L3 27.5263H24.6538L24.6538 17.379Z" fill="currentColor"/>
</svg>
                </span>
            );
        case 'watching':
            return (
                <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" viewBox="0 0 365 205">
                        <path d="M182.292,0c22.362,0 43.875,3.038 64.533,9.117c20.658,6.075 37.796,13.608 51.404,22.604c13.613,8.992 25.646,18.35 36.096,28.071c10.45,9.72 18.108,18.35 22.967,25.887c4.862,7.533 7.291,13 7.291,16.404c0,3.404 -2.429,8.75 -7.291,16.042c-4.859,7.292 -12.517,15.921 -22.967,25.887c-10.45,9.963 -22.483,19.442 -36.096,28.438c-13.608,8.992 -30.746,16.525 -51.404,22.604c-20.658,6.075 -42.171,9.113 -64.533,9.113c-22.359,-0 -43.871,-3.038 -64.529,-9.113c-20.659,-6.079 -37.796,-13.612 -51.409,-22.604c-13.608,-8.996 -25.641,-18.475 -36.091,-28.438c-10.45,-9.966 -18.109,-18.595 -22.971,-25.887c-4.859,-7.292 -7.292,-12.638 -7.292,-16.042c0,-3.404 2.433,-8.871 7.292,-16.404c4.862,-7.537 12.521,-16.167 22.971,-25.887c10.45,-9.721 22.483,-19.079 36.091,-28.071c13.613,-8.996 30.75,-16.529 51.409,-22.604c20.658,-6.079 42.17,-9.117 64.529,-9.117Zm-0,180.104c22.362,0 41.441,-7.654 57.241,-22.966c15.8,-15.313 23.696,-33.788 23.696,-55.417c0,-21.633 -7.896,-40.104 -23.696,-55.417c-15.8,-15.312 -34.879,-22.971 -57.241,-22.971c-22.359,0 -41.438,7.659 -57.238,22.971c-15.8,15.313 -23.7,33.784 -23.7,55.417c0,21.629 7.9,40.104 23.7,55.417c15.8,15.312 34.879,22.966 57.238,22.966Zm-0,-78.021c1.945,1.946 6.441,2.188 13.491,0.729c7.05,-1.458 13.125,-2.795 18.23,-4.008c5.104,-1.217 8.141,-0.125 9.112,3.279c0,10.696 -4.008,19.809 -12.029,27.346c-8.021,7.534 -17.621,11.3 -28.804,11.3c-11.179,0 -20.659,-3.766 -28.438,-11.3c-7.775,-7.537 -11.666,-16.771 -11.666,-27.708c-0,-10.938 3.891,-20.175 11.666,-27.708c7.779,-7.538 17.259,-11.305 28.438,-11.305c3.404,0 4.621,2.796 3.646,8.388c-0.971,5.587 -2.43,11.3 -4.375,17.133c-1.942,5.834 -1.7,10.45 0.729,13.854Z" stroke="currentColor" />
                    </svg>
                </span>
            );
        case 'grid':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 9H21M3 15H21M12 3V21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'info':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'folder':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'outbound-link':
            return (
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 9.00001L21 3.00001M21 3.00001H15M21 3.00001L12 12M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            );
        case 'cloud':
            return (
                <span className="fz-circle">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 16.2422C2.79401 15.435 2 14.0602 2 12.5C2 10.1564 3.79151 8.23129 6.07974 8.01937C6.54781 5.17213 9.02024 3 12 3C14.9798 3 17.4522 5.17213 17.9203 8.01937C20.2085 8.23129 22 10.1564 22 12.5C22 14.0602 21.206 15.435 20 16.2422M8 17L12 21M12 21L16 17M12 21V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>   
                </span>   
            );
        case 'passive-income':
            return (
                <span className="fz-circle">
                    <svg width="24" height="24" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path d="M45.833,32.621l0,13.212l-9.166,0c-5.983,0 -8.998,0.256 -11.283,-0.908c-0.001,-0 -0.001,-0 -0.001,-0c-1.568,-0.799 -2.842,-2.074 -3.641,-3.641c-0.98,-1.925 -0.954,-4.365 -0.921,-8.659c4.862,-1.711 8.346,-6.345 8.346,-11.792c-0,-6.903 -5.597,-12.5 -12.5,-12.5c-6.904,0 -12.5,5.597 -12.5,12.5c-0,5.447 3.484,10.081 8.345,11.792c0.065,6.304 0.424,9.732 1.804,12.441c0,0.001 0.001,0.001 0.001,0.001c1.597,3.136 4.147,5.685 7.283,7.283c3.064,1.561 7.046,1.817 15.067,1.817l9.166,-0l0,13.212c-4.855,1.715 -8.333,6.345 -8.333,11.788c0,6.903 5.596,12.5 12.5,12.5c6.904,-0 12.5,-5.597 12.5,-12.5c0,-5.443 -3.478,-10.073 -8.333,-11.788l-0,-13.212l9.166,-0c8.021,-0 12.003,-0.256 15.067,-1.817c3.136,-1.598 5.686,-4.147 7.283,-7.283c1.381,-2.71 1.74,-6.138 1.805,-12.441c4.862,-1.712 8.345,-6.346 8.345,-11.793c0,-6.903 -5.596,-12.5 -12.5,-12.5c-6.903,0 -12.5,5.597 -12.5,12.5c0,5.447 3.484,10.081 8.346,11.792c0.033,4.294 0.06,6.735 -0.921,8.658c0,0 0,0 0,0.001c-0.799,1.567 -2.073,2.842 -3.641,3.641c-0,-0 -0,-0 -0,-0c-2.286,1.164 -5.3,0.908 -11.284,0.908l-9.166,0l-0,-13.212c4.855,-1.715 8.333,-6.345 8.333,-11.788c0,-6.903 -5.596,-12.5 -12.5,-12.5c-6.904,0 -12.5,5.597 -12.5,12.5c0,5.443 3.478,10.073 8.333,11.788Zm37.5,-7.621c-2.301,0 -4.166,-1.865 -4.166,-4.167c-0,-2.301 1.865,-4.166 4.166,-4.166c2.302,-0 4.167,1.865 4.167,4.166c0,2.302 -1.865,4.167 -4.167,4.167Zm-66.666,0c-2.302,0 -4.167,-1.865 -4.167,-4.167c0,-2.301 1.865,-4.166 4.167,-4.166c2.301,-0 4.166,1.865 4.166,4.166c0,2.302 -1.865,4.167 -4.166,4.167Zm33.333,0c-2.301,0 -4.167,-1.865 -4.167,-4.167c0,-2.301 1.866,-4.166 4.167,-4.166c2.301,-0 4.167,1.865 4.167,4.166c-0,2.302 -1.866,4.167 -4.167,4.167Z" />
                    </svg>
                </span> 
            );
        case 'video':
            return (
                <span className="fz-circle">
                    <svg width="24" height="24" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <path d="M8.333,32.5l0,35c0,8.021 0.256,12.003 1.817,15.066c-0,0.001 -0,0.001 -0,0.001c1.598,3.136 4.147,5.685 7.283,7.283c3.064,1.561 7.046,1.817 15.067,1.817l35,-0c8.021,-0 12.003,-0.256 15.066,-1.817c3.137,-1.598 5.686,-4.147 7.284,-7.283c1.561,-3.064 1.817,-7.046 1.817,-15.067l-0,-35c-0,-8.021 -0.256,-12.003 -1.817,-15.066c-1.598,-3.137 -4.147,-5.686 -7.283,-7.284c-0,-0 -0,-0 -0.001,-0c-3.063,-1.561 -7.045,-1.817 -15.066,-1.817l-35,0c-8.021,0 -12.003,0.256 -15.066,1.817c-3.137,1.598 -5.686,4.147 -7.284,7.283c-1.561,3.064 -1.817,7.046 -1.817,15.067Zm8.334,0c-0,-5.983 -0.256,-8.998 0.908,-11.283c-0,-0 -0,-0 -0,-0c0.799,-1.568 2.074,-2.843 3.642,-3.642c-0,-0 -0,-0 -0,-0c2.285,-1.164 5.3,-0.908 11.283,-0.908c-0,-0 35,-0 35,-0c5.983,-0 8.998,-0.256 11.283,0.908c1.568,0.799 2.843,2.074 3.642,3.642c-0,-0 -0,-0 0,-0c1.164,2.285 0.908,5.3 0.908,11.283c0,-0 0,35 0,35c0,5.983 0.256,8.998 -0.908,11.283c-0,0 -0,0 -0,0.001c-0.799,1.567 -2.074,2.842 -3.641,3.641c-0.001,-0 -0.001,-0 -0.001,-0c-2.285,1.164 -5.3,0.908 -11.283,0.908c0,0 -35,0 -35,0c-5.983,0 -8.998,0.256 -11.283,-0.908c-0,-0 -0,-0 -0,-0c-1.568,-0.799 -2.843,-2.074 -3.642,-3.641c-1.164,-2.286 -0.908,-5.301 -0.908,-11.284c-0,0 -0,-35 -0,-35Zm18.75,4.856l-0,25.289c-0,3.44 0.528,5.075 1.247,6.035c1.086,1.451 2.749,2.359 4.557,2.488c1.197,0.086 2.858,-0.355 5.751,-2.215l19.67,-12.644c2.577,-1.657 3.567,-2.823 4.013,-3.877c0.657,-1.555 0.657,-3.309 -0,-4.864c-0.446,-1.054 -1.436,-2.22 -4.014,-3.877l-19.669,-12.644c-2.894,-1.861 -4.554,-2.301 -5.75,-2.215c-0.001,-0 -0.001,-0 -0.001,-0c-1.808,0.129 -3.471,1.037 -4.558,2.488c-0.719,0.961 -1.246,2.596 -1.246,6.036Z"/>
                    </svg>
                </span>
            );
        case 'delete-folder':
            return (
                <span className="fz-circle">
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.43171 1H8.56829C8.91161 0.999978 9.22691 0.999956 9.4905 1.02102C9.77467 1.04374 10.0856 1.09573 10.3926 1.24879C10.8317 1.46763 11.1886 1.81683 11.4123 2.24633C11.5688 2.54673 11.622 2.85087 11.6452 3.12887C11.6573 3.27413 11.6626 3.43546 11.6649 3.6087H14C14.5523 3.6087 15 4.04668 15 4.58696C15 5.12724 14.5523 5.56522 14 5.56522H13.6667V11.929C13.6667 12.4447 13.6667 12.8896 13.6361 13.256C13.6038 13.6428 13.5325 14.0274 13.3397 14.3976C13.0521 14.9498 12.5931 15.3988 12.0286 15.6801C11.6503 15.8687 11.257 15.9385 10.8617 15.9701C10.4871 16 10.0323 16 9.50519 16H6.49481C5.96765 16 5.51288 16 5.13831 15.9701C4.74296 15.9385 4.34974 15.8687 3.97136 15.6801C3.40688 15.3988 2.94793 14.9498 2.66031 14.3976C2.46752 14.0274 2.3962 13.6428 2.3639 13.256C2.3333 12.8896 2.33331 12.4447 2.33333 11.929L2.33333 5.56522H2C1.44772 5.56522 1 5.12724 1 4.58696C1 4.04668 1.44772 3.6087 2 3.6087H4.3351C4.3374 3.43546 4.34269 3.27413 4.35482 3.12887C4.37804 2.85087 4.43119 2.54673 4.58765 2.24633C4.81136 1.81683 5.16831 1.46763 5.60736 1.24879C5.91443 1.09573 6.22533 1.04374 6.5095 1.02102C6.77309 0.999956 7.0884 0.999978 7.43171 1Z" fill="currentColor"></path>
                                                    </svg>
                </span>
            );
        case 'add-folder':
            return (
                <span className="fz-circle">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 3C0 1.89539 0.895508 1 2 1H7C7.90575 1 8.55863 1.97928 9.16156 2.88362C9.44505 3.30885 9.7175 3.7175 10 4H14C15.1045 4 16 4.89539 16 6V14C16 15.1046 15.1045 16 14 16H2C0.895508 16 0 15.1046 0 14V3ZM9 8C9 7.26362 8.73638 6.5 8 6.5C7.26362 6.5 7 7.26362 7 8V9H6C5.26362 9 4.5 9.26362 4.5 10C4.5 10.7364 5.26362 11 6 11H7V12C7 12.7364 7.26362 13.5 8 13.5C8.73638 13.5 9 12.7364 9 12V11H10C10.7364 11 11.5 10.7364 11.5 10C11.5 9.26362 10.7364 9 10 9H9V8Z" fill="currentColor"></path>
</svg>

                </span>
            );
        case 'edit-folder':
            return (
                <span className="fz-circle">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_5858_3947)">
<path d="M2 11.5V14H4.5L11.8733 6.62668L9.37333 4.12669L2 11.5ZM13.8067 4.69335C14.0667 4.43335 14.0667 4.01335 13.8067 3.75335L12.2467 2.19335C11.9867 1.93335 11.5667 1.93335 11.3067 2.19335L10.0867 3.41335L12.5867 5.91335L13.8067 4.69335V4.69335Z" fill="currentColor"></path>
</g>
<defs>
<clipPath id="clip0_5858_3947">
<rect width="16" height="16" fill="white"></rect>
</clipPath>
</defs>
</svg>
                </span>
            );
        default:
            return null;
    }
};


export default getSvg;