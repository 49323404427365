import React from 'react';
import getSvg from '../../utils/getSvg';
function Pagination(props) {
    const totalPages = Math.ceil(props.totalNumberOfRecords / props.recordsPerPage);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handleClick = (page) => {
        props.setCurrentPage(page);
        props.resetPage();
    };

    return (
        <div className="pagination">
            <div className="previous">
                <div className="button-group">
                    <button
                        type="button"
                        className={`button-s ${props.currentPage === 1 ? 'button-disabled' : 'button-tertiary'} button-icon`}
                        title="Previous Page"
                        disabled={props.currentPage === 1}
                        onClick={() => props.setCurrentPage(props.currentPage - 1)}
                    >
                        {getSvg('chevron-left')}
                    </button>
                </div>
            </div>
            <div className="pages">
                <div className="button-group">
                    {pageNumbers.map((number) => (
                        <button
                            key={number}
                            type="button"
                            className={`button-s ${number === props.currentPage ? 'button-primary' : 'button-tertiary'} button-icon`}
                            onClick={() => handleClick(number)}
                        >
                            <span className="text">{number}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div className="next">
                <div className="button-group">
                    <button
                        type="button"
                        className={`button-s ${props.currentPage === totalPages ? 'button-disabled' : 'button-tertiary'} button-icon`}
                        title="Next Page"
                        disabled={props.currentPage === totalPages}
                        onClick={() => props.setCurrentPage(props.currentPage + 1)}
                    >
                        {getSvg('chevron-right')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Pagination;
