import axiosInstance from './axiosInstance';


export async function getLoginUser() {
    const baseUrl = `${process.env.REACT_APP_LOCALHOST}/api/user`;

    try {
        const response = await axiosInstance.get(baseUrl);
        return await Promise.resolve(response.data);
    } catch (error) {
        return await Promise.reject(error);
    }
}


export async function updateUser(user) {
    const baseUrl = `${process.env.REACT_APP_LOCALHOST}/api/user/updateUser`;

    try {
        const response = await axiosInstance.post(baseUrl, { ...user });
        return await Promise.resolve(response.data);
    } catch (error) {
        return await Promise.reject(error);
    }
}

export async function changePassword(oldPassword, newPassword) {
    const baseUrl = `${process.env.REACT_APP_LOCALHOST}/api/user/changepassword`;

    try {
        const response = await axiosInstance.post(baseUrl, { oldPassword, newPassword });
        return await Promise.resolve(response.data);
    } catch (error) {
        return await Promise.reject(error);
    }
}