import React from 'react';
import getSvg from '../../utils/getSvg';
function UploadingFilesSection({ title, currentIndex, selectedFiles, fileProgressScopeOfWork, fileType="all"}) {
  return (
    <section>
        <h5>{title}</h5>
        {fileType === 'video' ?
            fileProgressScopeOfWork > 0 &&  selectedFiles.length > 0 && fileProgressScopeOfWork !== 100 ?
                <p className="message">
                    Uploading file <progress className='progress-bar' value={fileProgressScopeOfWork} max="100"></progress>  {fileProgressScopeOfWork} of 100% completed <span className="loader loader--s"></span>
                </p>
            :
            ''
        : ''
        }
        {fileType === 'video' ?
            currentIndex === selectedFiles.length && fileProgressScopeOfWork > 0 && fileProgressScopeOfWork === 100 ?
                <p className='message'>
                    File {selectedFiles.length > 1 ? 's have' : 'has'} been uploaded!&nbsp; {getSvg('green-checkmark')}
                </p>
            :
                fileProgressScopeOfWork === 0 ?
                    <p className="message">
                        Preparing {title}{selectedFiles.length > 1 ? 's' : ''} to be uploaded  
                        <span className="loader loader--s"></span>
                    </p>
                : ''
        : 
            selectedFiles.length > 0 && currentIndex > 0 && currentIndex === selectedFiles.length ?
                <p className='message'>
                    Picture {selectedFiles.length > 1 ? 's have' : 'has'} been uploaded!&nbsp; {getSvg('green-checkmark')}
                </p>
            : ''
        }   
    </section>
  );
}

export default UploadingFilesSection;
