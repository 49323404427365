/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';

export default function NewPassword() {
    const navigate = useNavigate();
    const handleGoToFeeds = (user_id) => navigate('profeeds', {
      state: {
        userId: user_id,
      }
    });
    const [email, setEmail] = useState('');
    const form = useRef();
    
  return(
    <div>
        <div className='row full'>
            <div className='row'>
                <div className='fz-credentials col six'>
                    <div className="block fz-center">                    
                        <h1>Reset your password</h1>
                    </div>
                    <form ref={form} className="scol">
                        <div className="block">
                            <label className="fz-label" htmlFor="email">Email</label>
                            <input type="hidden" name='message' value='testing message' />
                            <input
                                id="email"
                                name='email'
                                className="fz-input"
                                type="email"
                                autoComplete="email"
                            />                                             
                        </div>                            
                        <div className="fz-center block">                    
                            <button type="submit"
                                id="fz-continue"
                                aria-label="Reset your password"
                                className="fz-btn-lrg full"
                            >
                                Continue
                            </button>
                        </div>
                        <div className="block fz-center">                                        
                            <div className="col twelve block"><a href="/">Cancel</a></div>                                                                    
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}