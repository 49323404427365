/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import Header from './Header';

export default function ApproveProAccount() {
   sessionStorage.setItem('userType',  500002);
    const navigate = useNavigate();
    const { recordid } = useParams();
    const [userInfo, setUserInfo] = useState([]);
    const [showNoUser, setShowNoUser] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [showRejectionReasonModal, setShowRejectionReasonModal] = useState(false);
    const handleGoToLogin = () =>  navigate('/login');
    useEffect(() => {
        if(recordid.substring(0, 4) === process.env.REACT_APP_USER_STRING && recordid.length === 41 ){
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/getNewAccountRequest`, {recordid: recordid}).then((response) => {
                if(response.data.length > 0){
                   sessionStorage.setItem('isLoggedIn',  'true');
                    setShowNoUser(false);
                    setUserInfo(response.data[0]);
                } else {
                    setShowNoUser(true);
                }
            }).catch(function (error) {
                console.log('error approving pro account = ',error);
            })
        } else {
            handleGoToLogin();
        }
    }, []);

    const approveRejectAccount = (isApproved) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/approveRejectAccount`, {recordid: recordid, isApproved: isApproved, rejectionReason: rejectionReason}).then((response) => {
            setShowNoUser(true);
        }).catch(function (error) {
            console.log('error approving pro account = ',error);
        })
    }

    return(
    <div>
        <Header />
        <div className='row full'>
            <div className='row'>
                <div className='col six'>
                    <div className='fz-header-title fz-container'>
                        <h1>Account approval</h1>
                    </div>
                    {showNoUser ?
                        <div className='block'>
                            <div className="fz-alert alert-success">
                                <i className="icon-success"></i>                                
                                <strong>Info</strong>
                                <p>No new user account request found to be approved</p>
                            </div>
                        </div>
                    :
                    <main className='block-xx'>
                        <div className='fz-container'>
                            <p>
                                {userInfo.firstName} {userInfo.lastName} is request approval for his account.<br />
                                Review information below and accept or reject the request
                            </p>
                            <br />
                            <div className='fz-response-container'>
                                <div><strong>Company:</strong> <span style={{padding: "10px"}}>{userInfo.company}</span></div>
                                <div><strong>Phone:</strong> <span style={{padding: "10px"}}>{userInfo.phone}</span></div>
                                <div><strong>License:</strong> <span style={{padding: "10px"}}>{userInfo.license}</span></div>
                                <div><strong>Email:</strong> <span style={{padding: "10px"}}>{userInfo.email}</span></div>
                                <div><strong>Name:</strong> <span style={{padding: "10px"}}>{userInfo.firstName} {userInfo.lastName}</span></div>
                                <div><strong>Address:</strong> <span style={{padding: "10px"}}>{userInfo.address} {userInfo.address2}, {userInfo.city}, {userInfo.state}, {userInfo.zip}</span></div>  
                                <div>&nbsp;</div>
                                <div className='button-group fz-container fz-center block'>                    
                                    <button 
                                        type='button'
                                        id="approve"
                                        className='btn fz-btn-med'
                                        onClick={() => { approveRejectAccount(1)}}
                                    >  
                                        Approve
                                    </button>
                                    <span className='file-input__files'></span>
                                    <button 
                                        type='reset'                        
                                        aria-label='Reject'
                                        value='reject'
                                        className='btn fz-btn-med btn-secondary'
                                        onClick={() => {setShowRejectionReasonModal(true)}}
                                    >
                                    Reject
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main> 
                    } 
                </div>
            </div>
            
            { showRejectionReasonModal ?
                    <div id='modal-success' className='fz-overlay'>
                        <div className='fz-overlay-container'>
                            <div className='modal-content'>
                                <div className='block-xx'>
                                    <h4 className='h2-like'>Rejection message</h4>
                                </div>
                                <div>
                                    <textarea onBlur={(e) => setRejectionReason(e.target.value)} name='rejectionReason' id='rejectionReason' rows='5' cols='50' style={{width: "100%"}}></textarea>
                                </div>  
                                <div className='row'><br /></div>
                                <div className='row'>
                                    <div className='button-group fz-container fz-center block'>                    
                                        <button 
                                            type='button'
                                            id="deletebtn"
                                            className='btn fz-btn-med'
                                            onClick={() => {approveRejectAccount(0)}}
                                        >  
                                            Submit
                                        </button>
                                        <span className='file-input__files'></span>
                                        <button 
                                            type='reset'                        
                                            aria-label='Exit'
                                            value='Exit'
                                            className='btn fz-btn-med btn-secondary'
                                            onClick={() => { setShowRejectionReasonModal(false)}}
                                        >
                                        Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : ''
                }                           
        </div>
    </div>
  )
}
