import axiosInstance from "./axiosInstance";
import axios from "axios";


async function signedUrl(operation, bucketName, key, fileType) {
    const baseUrl = `${process.env.REACT_APP_LOCALHOST}/api/s3/${operation}`;
    try {
        const response = await axiosInstance.post(baseUrl, {bucketName, key, fileType});
        return response.data;
    } catch (error) {
        return null;
    }
}

export async function getPutObjectSignedUrl(bucketName, key, fileType) {
    const operation = "getPutObjectSignedUrl";
    return await signedUrl(operation, bucketName, key, fileType);
}


export async function getObjectSignedUrl(bucketName, key, fileType) {
    const operation = "getObjectSignedUrl";
    return await signedUrl(operation, bucketName, key, fileType);
}

export function uploadFile(url, fileData) {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);

    const promises = {
        onProgress: new Promise((resolve) => {
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    resolve(progress);
                }
            };
        }),
        done: new Promise((resolve, reject) => {
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.responseText);
                } else {
                    reject(new Error(`Request failed with status: ${xhr.status}`));
                }
            };

            xhr.onerror = (ev) => {
                reject(new Error(`Request failed due to an error ${ev}`));
            };
        }),
        onError: new Promise((resolve, reject) => {
            xhr.onerror = () => {
                resolve(new Error('Request failed due to an error'));
            };
        }),
    };

    xhr.send(fileData);

    return promises;
};


/**
 * uploads a file to a specified bucket using a signed URL with progress tracking and error handling.
 * @param fileData - represents the data of the file that you want to upload. It typically includes
 * the content of the file and its type. This data is used to upload the file to the specified bucket
 * using a signed URL.
 * @param bucketName - refers to the name of the bucket in which you want to upload the file.
 * Buckets are containers for storing objects in cloud storage services like Amazon S3.
 * When uploading a file using a signed URL,
 * @param key - represents the unique identifier or name for the file being uploaded to the specified `bucketName`.
 * It is used as part of the object key in the storage bucket to store the uploaded file. The `key` should be a string value
 * @param onPrgress - is a callback function that is called during the upload process to
 * report the progress of the file upload. It takes a single argument, which is the progress
 * percentage of the upload operation. This callback function can be used to
 */
export async function uploadWithSignedUrl(fileData, bucketName, key, onPrgress = null, onLoad = null) {
    try {
        const signedUrl = await getPutObjectSignedUrl(bucketName, key, fileData.type);

        if (!signedUrl) {
            throw new Error("Failed to generate signed URL");
        }

        // const uploadConfig = {
        //     headers: {
        //         'Content-Type': fileData.type,
        //     },
        //     onUploadProgress: (progressEvent) => {
        //         if (onPrgress && progressEvent.total) {
        //             const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //             onPrgress(percentCompleted);
        //         }
        //     }
        // };

        await axios({
            method: 'put',
            url: signedUrl,
            data: fileData,
            headers: {
                'Content-Type': fileData.type,
            },
            onUploadProgress: (progressEvent) => {
                if (onPrgress && progressEvent.total) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onPrgress(percentCompleted);
                }
            }
        })

        onLoad?.();

        // await axios.put(signedUrl, fileData, uploadConfig);
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
}
