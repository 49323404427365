import React, {useEffect, useState, useRef} from 'react';
import * as formValidator from '../../utils/formValidation';
import {cleanMediaUrl} from '../../utils/clearMediaUrl';
import {useNavigate} from 'react-router-dom';
import AccountDetailsForm from '../libs/AccountDetailsForm';
import Axios from 'axios';
import AlertMessage from '../libs/AlertMessage';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header';
import MailingAddressForm from '../libs/MailingAddressForm';
import PageTitle from '../libs/PageTitle';
import useGooglePlaceAutoComplete from "../../service/google_place_autocomplete";
import uuid from 'react-uuid';
import {loadGoogleMapsAPI} from '../../utils/loadGoogleMapsAPI';

export default function FreeLead() {
    const videoRef = useRef(null);
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
    const [fileToBeUploaded, setFileToBeUploaded] = useState('');
    const [firstName, setFirstName] = useState('');
    const [hasAddress, setHasAddress] = useState(true);
    const [hasCity, setHasCity] = useState(true);
    const [hasConfirmPassword, setHasConfirmPassword] = useState(true);
    const [hasFirstName, setHasFirstName] = useState(true);
    const [hasLastName, setHasLastName] = useState(true);
    const [hasPassword, setHasPassword] = useState(true);
    const [hasProjectName, setHasProjectName] = useState(true);
    const [hasProjectDescription, setHasProjectDescription] = useState(true);
    const [hasState, setHasState] = useState(true);
    const [hasZip, setHasZip] = useState(true);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [hasEmail, setHasEmail] = useState(true);
    const [hasPhoneNumber, setHasPhoneNumber] = useState(true);
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectName, setProjectName] = useState('');
    const [showInfo, setShowInfo] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showProcessingVideo, setShowProcessingVideo] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [selectedFiles, setSelectedFiles] = React.useState(null);
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const addressRef = useRef();
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
    const userType = 500003;
    const videoFormatError = "Only these picture formats are valid: png, jpg and jpeg.";
    const navigate = useNavigate();
    let autoComplete = "";
    const uuids = uuid();
    const imageExtensionsRegex = /\.(jpg|jpeg|png)$/i;

    useEffect(() => {
        async function initGooglePlaces() {
            try {
                await loadGoogleMapsAPI('initGoogleMaps');
                autoComplete = await googleAutoCompleteSvc.initAutoComplete(addressRef.current, handleAddressSelect);
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        }

        initGooglePlaces();
        sessionStorage.setItem('isLoggedIn', 'false');
        sessionStorage.setItem('userType', 500003);
        document.body.classList.add('dark-mode');
        return () => {
            document.body.classList.remove('dark-mode');
        };
    }, []);

    const handleAddressSelect = async () => {
        let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
        addressRef.current.value = addressObj.address1;
        setAddress(addressObj.address1);
        setState(addressObj.adminArea1Short);
        setCity(addressObj.locality);
        setZip(addressObj.postalCode);
        setHasAddress(true);
        setHasCity(true);
        setHasState(true);
        setHasZip(true);
    };

    const checkPhoneNumber = (phone) => {
        if (phone.trim().length > 0) {
            if (!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
                setHasPhoneNumber(false);
            } else {
                setHasPhoneNumber(true);
            }
        } else {
            setHasPhoneNumber(false);
        }
    };

    const saveVideoUploaded = (uploadFileName, uuids, projectDescription, projectName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveFreeLeadVideos`, {
            email: email,
            uploadFileName: uploadFileName,
            address: addressRef.current.value,
            address2: address2,
            city: city,
            phone: phone,
            zip: zip,
            state: state,
            packageName: uuids,
            projectDescription: projectDescription,
            projectName: projectName,
            firstName: firstName,
            lastName: lastName,
            isJustLead: false,
        }).then(() => {
            setShowInfo(true);
            setShowProcessing(false);
            setShowUploadVideo(false);
            navigate(`/underway`);
        }).catch(function (error) {
            console.log('error = ', error);
        })
    }

    const createLead = () => {
        checkEmail(email);
        checkFirstName(firstName);
        checkLastName(lastName);
        checkAddress(address);
        checkCity(city);
        checkPhoneNumber(phone);

        let isValid = true;
        if (checkForm()) {
            if (
                firstName.trim().length === 0 || lastName.trim().length === 0 ||
                city.trim().length === 0 || state.trim().length === 0 ||
                email.trim().length === 0 || city.trim().length === 0 || zip.trim().length < 5 || !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) ||
                !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            ) {
                isValid = false;
            } else {

                // saveVideoUploaded('', '', '', '')
                // TODO: Disabled until fix
                setShowUploadVideo(true);
            }
        }
        if (typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean') {
            if (addressRef.current.value.trim().length === 0) {
                isValid = false;
            } else {
                setHasAddress(true);
            }
        }
        return isValid;
    }

    const checkCity = (city) => {
        if (city.trim().length === 0) {
            setHasCity(false);
        } else {
            setHasCity(true);
        }
    }

    const checkFirstName = (firstName) => {
        if (firstName.trim().length === 0) {
            setHasFirstName(false);
        } else {
            setHasFirstName(true);
        }
    }

    const checkLastName = (lastName) => {
        if (lastName.trim().length === 0) {
            setHasLastName(false);
        } else {
            setHasLastName(true);
        }
    }

    const checkProjectName = (projectName) => {
        if (projectName.trim().length === 0) {
            setHasProjectName(false);
        } else {
            setHasProjectName(true);
        }
    }

    const checkProjectDescription = (projectDescription) => {
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
        } else {
            setHasProjectDescription(true);
        }
    }

    const checkEmail = (email) => {
        if (email.trim().length > 0) {
            const matchEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (Array.isArray(matchEmail) && matchEmail.length > 0) {
                setHasEmail(true);
            } else {
                setHasEmail(false);
            }
        } else {
            setHasEmail(false);
        }
    }

    const checkAddress = (address) => {
        if (typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean' && address !== '') {
            if (address.trim().length === 0) {
                setHasAddress(false);
            }
        } else {
            setHasAddress(true);
        }

        if (addressRef.current.value.trim().length > 0) {
            setAddress(addressRef.current.value);
        }
    }

    const formatPhoneNumber = (phone) => {
        const cleaned = ('' + phone).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            setPhone(['(', match[2], ') ', match[3], '-', match[4]].join(''));
        }
    }

    const checkForm = () => {
        let isValid = true;
        if (email.trim().length === 0 || state.trim().length === 0 || phone.trim().length === 0 || firstName.trim().length === 0 || lastName.trim().length === 0) {
            isValid = false;
        }
        if (typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean') {
            if (addressRef.current.value.trim().length === 0) {
                isValid = false;
            } else {
                setHasAddress(true);
            }
        }
        return isValid;
    }
    const checkUploadForm = () => {
        let isValid = true;
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }

        if (projectName.trim().length === 0) {
            setHasProjectName(false);
            isValid = false;
        } else {
            setHasProjectName(true);
        }

        // TODO: Remove in future versions
        // if (!isValidVideo) {
        //     setShowErrorMessage(false);
        //     isValid = false;
        // }
        return isValid;
    }

    const handleAlertButtonClick = () => {
        setErrorUploadingVideo(false);
    };

    const handleFileChange = (event) => {
        if (!imageExtensionsRegex.test(event.target.files[0].name)) {
            setIsValidVideo(false);
            return;
        }
        setSelectedFiles(event.target.files);
        setFileToBeUploaded(cleanMediaUrl(event.target.files[0].name));
    };

    const handleUpload = async () => {
        // TODO: Remove in future versions
        // if (selectedFiles !== null && selectedFiles.length > 0) {
            setShowErrorMessage(false);
            setIsValidVideo(true);
            // TODO: Remove in future versions
            // const chosenFile = cleanMediaUrl(selectedFiles[0].name);
            // const uploadPath = `${uuids}/free-lead-image/`;
            // if (!imageExtensionsRegex.test(chosenFile)) {
            //     setIsValidVideo(false);
            //     return;
            // }
            if (checkUploadForm()) {
                setIsValidVideo(true);
                // TODO: Remove in future versions
                // setFileToBeUploaded(chosenFile);
                setShowErrorMessage(false);
                // TODO: Remove in future versions
                // setShowProcessingVideo(true);
                // setShowProcessing(false);
                // const formData = new FormData();
                // for (let i = 0; selectedFiles && i < selectedFiles.length; i++) {
                //     formData.append("files", selectedFiles[i]);
                // }
                // formData.append('path', uploadPath);
                // formData.append('bucket', process.env.REACT_APP_PROJECT_VIDEOS_BUCKET_NAME);

                // TODO: Remove in future versions
                // formData.append('fileName', chosenFile);
                // try {
                //     await Axios.post(`${process.env.REACT_APP_LOCALHOST}/upload`, formData, {
                //         headers: {'Content-Type': 'multipart/form-data'}
                //     }).then(() => {
                //         saveVideoUploaded(chosenFile, uuids, projectDescription, projectName);
                        saveVideoUploaded(null, uuids, projectDescription, projectName);
                //     }).catch((error) => {
                //             console.error('Error uploading file:', error);
                //             setErrorUploadingVideo(true);
                //         }
                //     );
                // } catch (error) {
                //     console.error('Error uploading file:', error);
                // }
            } else {
                setShowErrorMessage(true);
            }
        // } else {
        //     setShowErrorMessage(true);
        // }
    };

    return (
        <div id="fazzad-site-container">
            <Header
                mode={'dark'}
            />
            <main id="fazzad-main">
                <div id="app-container">
                    <div className="form-container" id="proprofile">
                        <form action="">
                            <section className="form-details">
                                <PageTitle
                                    headerText={'Lead creation'}
                                    subHeaderText={'Upload and create your project, so that Fazzad Pros & Vendors can review and get in touch.'}
                                    showSubHeader={true}
                                />

                                <AccountDetailsForm
                                    accountType={'propertyowner'}
                                    companyName={''}
                                    hasCompanyName={true}
                                    setCompanyName={null}
                                    setHasCompanyName={true}
                                    license={null}
                                    setLicense={null}
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    hasFirstName={hasFirstName}
                                    setHasFirstName={setHasFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    setHasLastName={setHasLastName}
                                    hasLastName={hasLastName}
                                    phone={phone}
                                    setPhone={setPhone}
                                    hasPhoneNumber={hasPhoneNumber}
                                    setHasPhoneNumber={setHasPhoneNumber}
                                    formatPhoneNumber={formatPhoneNumber}
                                    emailProfile={email}
                                    setEmailProfile={setEmail}
                                    hasEmailProfile={hasEmail}
                                    setHasEmailProfile={setHasEmail}
                                    password={password}
                                    setPassword={setPassword}
                                    hasPassword={hasPassword}
                                    setHasPassword={setHasPassword}
                                    confirmPassword={confirmPassword}
                                    setConfirmPassword={setConfirmPassword}
                                    hasConfirmPassword={hasConfirmPassword}
                                    setHasConfirmPassword={setHasConfirmPassword}
                                    isInvitation={false}
                                    formValidator={formValidator}
                                    userType={parseInt(userType)}
                                />
                            </section>
                            <section className="form-details">
                                <MailingAddressForm
                                    setAddress={setAddress}
                                    addressRef={addressRef}
                                    hasAddress={hasAddress}
                                    setHasAddress={setAddress}
                                    setAddress2={setAddress2}
                                    address2={address2}
                                    city={city}
                                    setCity={setCity}
                                    hasCity={hasCity}
                                    setHasCity={setHasCity}
                                    state={state}
                                    setState={setState}
                                    hasState={hasState}
                                    setHasState={setHasState}
                                    zip={zip}
                                    setHasZip={setHasZip}
                                    hasZip={hasZip}
                                    setZip={setZip}
                                    formValidator={formValidator}
                                    showHeader={true}
                                    isNewPackage={true}
                                    addressHeader={"Project Address"}
                                />
                            </section>
                            <div className="button-group">
                                <button
                                    type="button"
                                    className="button-m button-primary"
                                    onClick={() => {
                                        createLead()
                                    }}
                                >
                                    <span className="text">Continue</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            {showUploadVideo ?
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4g">Project details</h2>
                        </div>
                        <div className="modal__content">
                            {showProcessingVideo && (
                                <>
                                    <AlertMessage
                                        messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                        messageBody={''}
                                        messageType={'warning'}
                                        showButton={false}
                                        showLoader={true}
                                    />
                                    <div className='fz-block-sm'>&nbsp;</div>
                                    {showProcessingVideo && (
                                        <p className="fz-align-center">Uploading picture&nbsp; &nbsp;<span
                                            className="loader loader--s"></span></p>
                                    )}
                                </>
                            )}
                            {!showProcessingVideo && showInfo ?
                                <>
                                    <div className={`form-field text-input ${!hasProjectName ? 'error' : ''}`}>
                                        {/*User*/}
                                        {/* <div>
                                    <input type="file" multiple onChange={handleFileChange} />
                                    <button onClick={handleUpload}>Upload</button>
                                </div>
                                <button onClick={handleUpload}>Upload</button> */}
                                        <label htmlFor="project-name">Project name</label>
                                        <input
                                            name="project-name"
                                            id="project-name"
                                            type="text"
                                            aria-required="true"
                                            placeholder=""
                                            value={projectName}
                                            onFocus={(e) => {
                                                setHasProjectName(true);
                                            }}
                                            onBlur={(e) => {
                                                formValidator.clearEmojis(e.target.value, 'projectName');
                                                checkProjectName(e.target.value);
                                            }}
                                            onChange={(e) => {
                                                setProjectName(e.target.value);
                                            }}
                                        >
                                        </input>
                                        {!hasProjectName ?
                                            <p className="validation-message">Enter project name.</p>
                                            : ''}
                                    </div>
                                    <div className={`form-field text-area ${!hasProjectDescription ? 'error' : ''}`}>
                                        <label htmlFor="project-description">Project Description</label>
                                        <div className="input-container">
                                        <textarea
                                            name="Project description"
                                            id="project-description"
                                            type="text"
                                            aria-required="true"
                                            placeholder=""
                                            value={projectDescription}
                                            onFocus={(e) => {
                                                setHasProjectDescription(true);
                                            }}
                                            onBlur={(e) => {
                                                formValidator.clearEmojis(e.target.value, 'projectDescription');
                                                checkProjectDescription(e.target.value);
                                            }}
                                            onChange={(e) => {
                                                setProjectDescription(e.target.value);
                                            }}
                                        >
                                        </textarea>
                                        </div>
                                        {!hasProjectDescription ?
                                            <p className="validation-message">Enter project description</p>
                                            : ''
                                        }
                                    </div>
                                </>
                                : ''
                            }
                            {/*TODO: Remove in future version*/}
                            {/*{!showProcessingVideo && showInfo && !errorUploadingVideo ?*/}
                            {/*    <div*/}
                            {/*        className={`form-field file-input ${!isValidVideo || showErrorMessage ? 'error' : ''}`}>*/}
                            {/*        <input type="file" id="fileupload" accept={'image/*'} className="file-input__input"*/}
                            {/*               onChange={(e) => {*/}
                            {/*                   const file = e.target.files[0];*/}

                            {/*                   if (!file) {*/}
                            {/*                       setShowErrorMessage(true);*/}
                            {/*                       setIsValidVideo(false);*/}
                            {/*                       return;*/}
                            {/*                   }*/}

                            {/*                   if (file.size > 10000000) {*/}
                            {/*                       setShowErrorMessage(true);*/}
                            {/*                       setIsValidVideo(false);*/}
                            {/*                       return;*/}
                            {/*                   }*/}

                            {/*                   setShowErrorMessage(false);*/}
                            {/*                   setIsValidVideo(true);*/}
                            {/*                   handleFileChange(e);*/}
                            {/*               }} ref={videoRef}/>*/}
                            {/*        <label htmlFor="fileupload" className="button button-m button-tertiary">*/}
                            {/*            {getSvg('upload')}*/}
                            {/*            <span className="text">Choose picture&hellip;</span>*/}
                            {/*        </label>*/}
                            {/*        <p style={{fontSize: 12, marginBottom: 8, marginTop: 8}}>Upload picture up to 10 MB.</p>*/}
                            {/*        {fileToBeUploaded.trim().length > 0 && (*/}
                            {/*            <p className='message'>{fileToBeUploaded}</p>)}*/}
                            {/*        {showErrorMessage ?*/}
                            {/*            <p className="validation-message">Choose a picture first.</p>*/}
                            {/*            : ''*/}
                            {/*        }*/}
                            {/*        {!isValidVideo ?*/}
                            {/*            <p className="validation-message">{videoFormatError}</p>*/}
                            {/*            : ''*/}
                            {/*        }*/}

                            {/*    </div>*/}
                            {/*    : errorUploadingVideo && (*/}
                            {/*    <AlertMessage*/}
                            {/*        messageHeader={'There was an error uploading the picture. Please try it again.'}*/}
                            {/*        messageBody={''}*/}
                            {/*        messageType={'warning'}*/}
                            {/*        showButton={true}*/}
                            {/*        showLoader={true}*/}
                            {/*        onButtonClick={handleAlertButtonClick}*/}
                            {/*    />*/}

                            {/*)}*/}
                            <div className='file-input-container block'>
                                <div className='file-input-container block'>
                                    {showProcessing ?
                                        <div className="alert-container">
                                            <div className="alert alert--success">
                                                <div className="alert__content">
                                                    <p className="alert__heading">Processing picture... <span
                                                        className="loader loader--s"></span></p>
                                                    <p className="alert__heading">{process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                            {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                <div className="modal__action">
                                    <div className="button-group">
                                        <button
                                            type='button'
                                            id='uploadbutton'
                                            className='button button-m button-primary'
                                            onClick={() => {
                                                handleUpload()
                                            }}
                                        >
                                            {getSvg('upload')}
                                            <span className="text">Submit project</span>
                                        </button>

                                        <button
                                            type='button'
                                            aria-label='Exit'
                                            value='Exit'
                                            className='button button-m button-secondary'
                                            onClick={() => {
                                                setShowUploadVideo(false);
                                                setShowErrorMessage(false);
                                                setIsValidVideo(true);
                                            }}
                                        >
                                            <span className="text">Exit</span>
                                        </button>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
                : ''
            }
        </div>
    )
}
