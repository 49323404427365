import axios from "axios";

const downloadCadTemplate = (templateName) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_LOCALHOST}/downloadCadTemplate`, { templateName: templateName }, {
            responseType: 'blob', // Set the response type to 'blob'
        })
        .then((response) => {
            // Create a blob URL from the response data
            const blob = new Blob([response.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element to trigger the download
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${templateName}.zip`;
            
            // Append the anchor element to the document and trigger a click event
            document.body.appendChild(a);
            a.click();
            
            // Clean up
            window.URL.revokeObjectURL(url);
            a.remove();

            resolve(); // Resolve the promise after initiating the download
        })
        .catch((error) => {
            reject(error); // Reject the promise if there's an error
        });
    });
};

export { downloadCadTemplate };
