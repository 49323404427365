import React from "react";
import ThreeSixtyComponent from "../../../ThreeSixtyComponent";


const PannablePhoto = ({ src }) => {
  return (
      <ThreeSixtyComponent
          image={src}
      />
  );
};

export default PannablePhoto;