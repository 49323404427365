/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import * as formValidator from '../../utils/formValidation';
import Header from '../libs/Header';
import MailingAddressForm from '../libs/MailingAddressForm';
import ModalInfo from '../libs/ModalInfo';
import useGooglePlaceAutoComplete from "../../service/google_place_autocomplete";
import { loadGoogleMapsAPI } from '../../utils/loadGoogleMapsAPI';
import AppContext from '../../AppContext';
import { signUp } from "../../utils/authHelpers";

export default function CreateProVendorAccount() {
    const navigate = useNavigate();
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [company, setCompany] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [hasAddress, setHasAddress] = useState(false);
    const [hasCity, setHasCity] = useState(true);
    const [hasCompanyName, setHasCompanyName] = useState(false);
    const [hasConfirmPassword, setHasConfirmPassword] = useState(false);
    const [hasEmail, setHasEmail] = useState(false);
    const [hasFirstName, setHasFirstName] = useState(false);
    const [hasLastName, setHasLastName] = useState(false);
    const [hasLicense, setHasLicense] = useState(false);
    const [hasPassword, setHasPassword] = useState(false);
    const [hasPhoneNumber, setHasPhoneNumber] = useState(false);
    const [hasState, setHasState] = useState(true);
    const [hasZip, setHasZip] = useState(true);
    const [isInvitation, setIsInvitation] = useState(false);
    const [lastName, setLastName] = useState('');
    const [license, setLicense] = useState('');
    const [messageContent, setMessageContent] = useState('Account created successfully.');
    const [messageType, setMessageType] = useState('success');
    const [password, setPassword] = useState('');
    const [packageName, setPackageName] = useState(0);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [phone, setPhone] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showAlreadyExists, setShowAlreadyExists] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [userId, setUserId] = useState(0);
    const addressRef = useRef();
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
    const { inviteeId, setInviteeId, setUserType, setUserInfo, setAccessToken } = useContext(AppContext);
    let autoComplete = "";

    const { setFocus, formState: { errors } } = useForm({});

    const closeAlertMessage = () => {
        setShowAlreadyExists(false);
        setShowConfirmation(false);
        setShowErrors(false);
    };

    useEffect(() => {
        sessionStorage.setItem('isLoggedIn', 'false');
        sessionStorage.setItem('userName', '')
        sessionStorage.setItem('userId', '');
        sessionStorage.setItem('userType', 0);
        sessionStorage.setItem('email', '');
        sessionStorage.setItem('isAdmin', false);
        sessionStorage.setItem('isPro', false);
        sessionStorage.setItem('isPropertyOwner', 'false');
        const queryParams = new URLSearchParams(window.location.search);

        const inviteeEmail = queryParams.get('email');
        const qPackageName = queryParams.get('packageName');

        if (typeof inviteeEmail !== 'undefined' && inviteeEmail !== null) {
            setEmail(inviteeEmail);
        } else {
            setEmail('');
        }
        if (typeof qPackageName !== 'undefined' && qPackageName !== null) {
            setPackageName(qPackageName);
        }

        async function initGooglePlaces() {
            try {
                await loadGoogleMapsAPI('initGoogleMaps');
                autoComplete = await googleAutoCompleteSvc.initAutoComplete(addressRef.current, handleAddressSelect);
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        }

        initGooglePlaces();
        setHasCompanyName(true);
        setHasLicense(true);
        setHasFirstName(true);
        setHasLastName(true);
        setHasEmail(true);
        setHasPhoneNumber(true);
        setHasPassword(true);
        setHasConfirmPassword(true);
        setHasAddress(true);
        setHasZip(true);
        setHasCity(true);
        checkIfIsInvitation(queryParams);
    }, [userId]);

    const checkIfIsInvitation = (queryParams) => {
        const invitee = parseInt(queryParams.get('invitee'));
        if (typeof invitee !== 'undefined' && invitee !== null) {
            setIsInvitation(true);
            getUserProfile(invitee);
            setInviteeId(invitee);
        } else {
            setIsInvitation(false);
        }
    }

    const checkForm = () => {
        let isValid = true;
        if (formValidator.checkAddress(addressRef.current.value)) { setHasAddress(true); } else { setHasAddress(false); isValid = false; }
        if (formValidator.checkEmail(email)) { setHasEmail(true); } else { setHasEmail(false); isValid = false; }
        if (formValidator.checkCity(city)) { setHasCity(true); } else { setHasCity(false); isValid = false; }
        if (formValidator.checkZip(zip)) { setHasZip(true); } else { setHasZip(false); isValid = false; }
        if (formValidator.checkState(state)) { setHasState(true); } else { setHasState(false); isValid = false; }
        if (formValidator.checkFirstName(firstName)) { setHasFirstName(true); } else { setHasFirstName(false); isValid = false; }
        if (formValidator.checkLastName(lastName)) { setHasLastName(true); } else { setHasLastName(false); isValid = false; }
        if (formValidator.checkPhoneNumber(phone)) { setHasPhoneNumber(true); } else { setHasPhoneNumber(false); isValid = false; }
        if (formValidator.checkPassword(password)) { setHasPassword(true); } else { setHasPassword(false); isValid = false; }
        if (formValidator.checkConfirmPassword(confirmPassword)) { setHasConfirmPassword(true); } else { setHasConfirmPassword(false); isValid = false; }
        if (formValidator.checkBothPasswords(password, confirmPassword)) { setPasswordMatch(true); } else { setPasswordMatch(false); isValid = false; }
        if (isInvitation) {
            if (formValidator.checkCompany(company)) {
                setHasCompanyName(true);
            } else {
                setHasCompanyName(false); isValid = false;
            }
        }
        if (password !== confirmPassword) {
            isValid = false;
        }
        return isValid;
    }

    const createNewAccount = () => {
        if (checkForm()) {
            signUp({
                email: email,
                address: addressRef.current.value,
                address2: address2,
                city: city,
                state: state,
                zip: zip,
                firstName: firstName,
                lastName: lastName,
                company: company,
                phone: phone,
                license: license,
                password: password,
                userType: isInvitation ? 500002 : 500001,
                isInvitation: isInvitation,
                inviteeId: inviteeId,
                usePayPal: 0,
            }).then((response) => {
                if (typeof response !== 'undefined' && response !== null) {
                    if (response.msg === 'email already exists') {
                        setMessageType('error');
                        setMessageContent('Account already exists with the email provided.');
                        setShowAlreadyExists(true);
                    } else {
                        sessionStorage.setItem('userId', response.userId);
                        setUserId(response.userId);
                        setUserInfo(response);
                        response?.accessToken && setAccessToken(response.accessToken);
                        if (isInvitation) {
                            sessionStorage.setItem('userType', 500002);
                            setUserType(500002);
                            sessionStorage.setItem('isLoggedIn', 'true');
                            sessionStorage.setItem('userName', firstName);
                            sessionStorage.setItem('email', email);
                            sessionStorage.setItem('isAdmin', 0);
                            if (Number(packageName) === 0) {
                                navigate('/profeed');
                            } else {
                                navigate(`/prodetails/${packageName}`);
                            }
                        }
                    }
                } else {
                    console.log('it did not work');
                }
            }).catch(function (error) {
                console.log('catch error = ', error);
            });
        } else {
            setShowErrors(true);
        }
    }

    const getUserProfile = async (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getUserInfoById`, { userId: userId }).then((response) => {
            if (response.data.length > 0) {
                if (response.data[0].userType === 500001) {
                    setIsInvitation(true);
                    setInviteeId(response.data[0].userId);
                } else {
                    setIsInvitation(false);
                }
            } else {
                setIsInvitation(false);
            }
        });
    }

    const handleAddressSelect = async () => {
        let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
        addressRef.current.value = addressObj.address1;
        setAddress(addressObj.address1);
        setCity(addressObj.locality);
        setState(addressObj.adminArea1Short);
        setZip(addressObj.postalCode);
        setFocus("address2");
    };

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <div className="form-container" id="proprofile">
                        <form action="">
                            <section className="form-details">
                                <h2 className="h4">New Account</h2>
                                <div className="form-row col-2">
                                    <div className={`form-field text-input ${!hasCompanyName ? 'error' : ''}`}>
                                        <label htmlFor="company">Company Name</label>
                                        <input
                                            type="text"
                                            id="company"
                                            value={company}
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'company'); }}
                                            onFocus={(e) => { setHasCompanyName(true); }}
                                            onChange={(e) => { setCompany(e.target.value); }}
                                        />
                                        {!hasCompanyName ?
                                            <p className="validation-message">Enter company name.</p>
                                            : ''
                                        }
                                    </div>
                                    <div className="form-field text-input">
                                        <label htmlFor="license">License Number (optional)</label>
                                        <input
                                            id='license'
                                            name='license'
                                            type='text'
                                            value={license}
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'license'); }}
                                            onChange={(e) => { setLicense(e.target.value); }}
                                        />
                                    </div>
                                </div>
                                <div className="form-row col-2">
                                    <div className={`form-field text-input ${!hasFirstName ? 'error' : ''}`}>
                                        <label htmlFor="firstName">First Name</label>
                                        <input
                                            id='firstName'
                                            name='firstName'
                                            type='text'
                                            value={firstName}
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'firstName'); }}
                                            onFocus={(e) => { setHasFirstName(true); setShowErrors(false); }}
                                            onChange={(e) => { setFirstName(e.target.value); }}
                                        />
                                        {!hasFirstName ?
                                            <p className="validation-message">Enter first name.</p>
                                            : ''
                                        }
                                    </div>
                                    <div className={`form-field text-input ${!hasLastName ? 'error' : ''}`}>
                                        <label htmlFor="lastName">Last Name</label>
                                        <input
                                            id='lastName'
                                            name='lastName'
                                            type='text'
                                            value={lastName}
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'lastName'); }}
                                            onFocus={(e) => { setHasLastName(true); setShowErrors(false); }}
                                            onChange={(e) => { setLastName(e.target.value); }}
                                        />
                                        {!hasLastName ?
                                            <p className="validation-message">Enter last name.</p>
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="form-row col-2">
                                    <div className={`form-field text-input ${!hasEmail ? 'error' : ''}`}>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            id='email'
                                            type='email'
                                            autoComplete=''
                                            value={email}
                                            onBlur={(e) => { formValidator.checkEmail(e.target.value); }}
                                            onFocus={(e) => { setHasEmail(true); setShowAlreadyExists(false); setShowErrors(false); }}
                                            onChange={(e) => { setEmail(e.target.value); }}
                                        />
                                        {!hasEmail ?
                                            <p className="validation-message">Enter valid email.</p>
                                            : ''
                                        }
                                    </div>
                                    <div className={`form-field text-input ${!hasPhoneNumber ? 'error' : ''}`}>
                                        <label htmlFor="phone">Phone number (10 digits only)</label>
                                        <input
                                            id='phone'
                                            name='phone'
                                            type='tel'
                                            value={phone}
                                            maxLength={10}
                                            onBlur={(e) => { formValidator.formatPhoneNumber(e.target.value, setPhone); }}
                                            onChange={(e) => { setPhone(e.target.value); }}
                                            onFocus={(e) => { setHasPhoneNumber(true); setShowErrors(false); }}
                                        />
                                        {!hasPhoneNumber ?
                                            <p className="validation-message">Enter a 10 digits phone number.</p>
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="form-row col-2">
                                    <div className={`form-field text-input ${!hasPassword ? 'error' : ''}`}>
                                        <label htmlFor="current-password">Password</label>
                                        <input
                                            id='current-password'
                                            type='password'
                                            autoComplete='password'
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'password'); }}
                                            onFocus={(e) => { setHasPassword(true); setHasConfirmPassword(true); setShowErrors(false); }}
                                            value={password} onChange={(e) => { setPassword(e.target.value); }}
                                        />
                                        {!hasPassword ?
                                            <p className="validation-message">Enter password.</p>
                                            : ''
                                        }
                                    </div>
                                    <div className={`form-field text-input ${!hasConfirmPassword || !passwordMatch ? 'error' : ''}`}>
                                        <label htmlFor="confirm-password">Confirm password</label>
                                        <input
                                            id='confirm-password'
                                            type='password'
                                            autoComplete='password'
                                            value={confirmPassword}
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'confirmPassword'); }}
                                            onFocus={(e) => { setHasConfirmPassword(true); setPasswordMatch(true); setShowErrors(false); }}
                                            onChange={(e) => { setConfirmPassword(e.target.value); }}
                                        />
                                        {!hasConfirmPassword ?
                                            <p className="validation-message">Enter matching confirm password.</p>
                                            : ''
                                        }
                                        {!passwordMatch ?
                                            <p className="validation-message">Passwords don't match.</p>
                                            : ''
                                        }

                                    </div>
                                </div>
                            </section>
                            <section><div className="fz-block-sm"></div></section>
                            <MailingAddressForm
                                setAddress={setAddress}
                                addressRef={addressRef}
                                hasAddress={hasAddress}
                                setHasAddress={setAddress}
                                setAddress2={setAddress2}
                                address2={address2}
                                city={city}
                                setCity={setCity}
                                hasCity={hasCity}
                                setHasCity={setHasCity}
                                state={state}
                                setState={setState}
                                hasState={hasState}
                                setHasState={setHasState}
                                zip={zip}
                                setHasZip={setHasZip}
                                hasZip={hasZip}
                                setZip={setZip}
                                formValidator={formValidator}
                                showHeader={true}
                                disableAddress={false}
                            />
                            <div className="button-group">
                                <button
                                    type="button"
                                    className="button-m button-primary"
                                    onClick={() => { createNewAccount() }}
                                >
                                    <span className="text">{isInvitation ? 'Create account' : 'Buy Courses'}</span>
                                </button>
                            </div>
                            <div className='fz-block-sm'></div>
                        </form>
                        {showConfirmation ?
                            <AlertMessage
                                messageType={messageType}
                                messageHeader={messageContent}
                                messageBody=''
                                onButtonClick={closeAlertMessage}
                            />
                            : ''
                        }
                        {showAlreadyExists ?
                            <AlertMessage
                                messageType='warning'
                                messageHeader='Account already exists with the email address provided.'
                                messageBody=''
                                onButtonClick={closeAlertMessage}
                            />
                            : ''
                        }
                        {showErrors ?
                            <AlertMessage
                                messageType='error'
                                messageHeader='Make sure to fill the form fields.'
                                messageBody=''
                                onButtonClick={closeAlertMessage}
                            />
                            : ''
                        }
                    </div>
                    {!isInvitation ?
                        <ModalInfo
                            messageHeader='Invalid invitation.'
                            messageBody='Please check your email and click on the <strong>Join Fazzad Network</strong> button again.'
                            messageType='error'
                            showCloseButton={true}
                            showLoader={false}
                        />
                        : ''}
                </div>
            </main>
        </div>
    )
}
