import React from 'react';
export default function StateFilter({setSelectedState, selectedState, statesArray}) {
    return (
        <div className="form-field select">
            <label htmlFor="sort-by-state">Choose State</label>
            <select id="sort-by-state" value={selectedState} onChange={e => setSelectedState(e.target.value)}>
                <option value="">All</option>
                {statesArray.map((state, index) => (
                    <option key={`state_${index}`} value={state}>{state}</option>
                ))}
            </select>
        </div>
    )
};
