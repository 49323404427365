import React from 'react';
export default function ShowPackageAddress({pck, headerText, showReadMore, showMore, getPackageDescription, handleReadMore}) {
  console.log('ShowPackageAddress.js - pck: ', pck.packageAddress);
  return (
    <>
        <h2 className="h5">{headerText}</h2>
        <p>{pck.packageAddress}{pck.packageAddress2.length > 0 ? ` ${pck.packageAddress2},` : ','} {pck.packageCity}, {pck.packageState} {pck.packageZip}</p>
    </>
  )
};
