import React from 'react';
import {useNavigate} from 'react-router-dom';
export default function Tabs({onTabClick, currentTab}) {
    const navigate = useNavigate();
    const handleTabClick = (currentTab) => {
        if (onTabClick) {
            onTabClick(currentTab); // Call the callback function
        }
      };
  return (
    <div className="tab-container tab-style--underline">
        <ul>
            <li className={`${currentTab === 'leads' ? "current" : ""}`}>
                <a href="" onClick={(e) => {{e.preventDefault(); navigate(`/profeed`);}}} className="tab">
                    <span className="text">Leads</span>
                </a>
            </li>
            <li className={`${currentTab === 'replies' ? "current" : ""}`}>
                <a href="" onClick={(e) => {{e.preventDefault(); navigate(`/proreplies`);}}} className="tab">
                    <span className="text">Replies</span>
                </a>
            </li>
            <li className={`${currentTab === 'testimonial' ? "current" : ""}`}>
                <a href="" onClick={(e) => {{e.preventDefault(); handleTabClick('testimonial'); navigate(`/promedia/testimonial`);}}} className="tab">
                    <span className="text">Testimonials</span>
                </a>
            </li>
            <li className={`${currentTab === 'portfolio' ? "current" : ""}`}>
                <a href="" onClick={(e) => {{e.preventDefault(); handleTabClick('portfolio'); navigate(`/promedia/portfolio`);}}} className="tab">
                    <span className="text">Portfolio</span>
                </a>
            </li>
        </ul>
    </div> 
  )
};
