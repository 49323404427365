// import React, { useState, useEffect } from 'react';
// import ForgeViewer from 'react-forge-viewer';
// import Axios from 'axios';

// export default function Viewer() {
 
//   useEffect(() => {
//         Axios.post(`${process.env.REACT_APP_LOCALHOST}/authenticate`).then((response) => {
//         console.log('response = ',response.data);
//         }).catch(function (error) {
//             console.log('download package error = ',error);
//         });
//     }, []);
 


//     return (
//       <div className="App">
//        Rodrigo
//       </div>
//     );
// }
 

import React, { useState, useCallback } from 'react';
import ForgeViewer from 'react-forge-viewer';
// import './App.css';

function App() {
  const [view, setView] = useState(null);

  const handleViewerError = useCallback((error) => {
    console.error('Error loading viewer.');
  }, []);

  const handleDocumentLoaded = useCallback((doc, viewables) => {
    if (viewables.length === 0) {
      console.error('Document contains no viewables.');
    } else {
      setView(viewables[0]);
    }
  }, []);

  const handleDocumentError = useCallback((viewer, error) => {
    console.error('Error loading a document');
  }, []);

  const handleModelLoaded = useCallback((viewer, model) => {
    console.log('Loaded model:', model);
  }, []);

  const handleModelError = useCallback((viewer, error) => {
    console.error('Error loading the model.');
  }, []);

  const getForgeToken = () => {
    return {
      access_token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjY0RE9XMnJoOE9tbjNpdk…70hvXAUsyvoVCgXfwyiQmADiXPYR7KmUlAEfyo19QWXvy8jOA',
      token_type: "Bearer"
    };
  };

  const handleTokenRequested = useCallback((onAccessToken) => {
    console.log('Token requested by the viewer.');
    let token = getForgeToken();
    if (token && onAccessToken) {
      onAccessToken(token.access_token, token.expires_in);
    }
  }, []);

  return (
    <div className="App">
      <ForgeViewer
        version="6.0"
        urn='dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6eHBpcHR2djNybmc0NWFtbm9seWRpZW05ZTVsbXlub3hudHdlbnExeGR2aTF4NzhyLWJhc2ljLWFwcC81NzA1JTIwUml2aWVyYSUyMERyaXZlJTIwQ29yYWwlMjBHYWJsZXMsJTIwRkwlMjAzMzE0Ni5kd2c'
        view={view}
        headless={false}
        onViewerError={handleViewerError}
        onTokenRequest={handleTokenRequested}
        onDocumentLoad={handleDocumentLoaded}
        onDocumentError={handleDocumentError}
        onModelLoad={handleModelLoaded}
        onModelError={handleModelError}
      />
    </div>
  );
}

export default App;
