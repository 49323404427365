import React, { useEffect, useState, useRef } from 'react';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { S3 } from '@aws-sdk/client-s3';
import { useNavigate } from 'react-router-dom';
import { Upload } from '@aws-sdk/lib-storage';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import checkUserProfile from '../../utils/checkUserProfile';
import Header from '../libs/Header'
import ModalInfo from '../libs/ModalInfo';
import Tabs from '../libs/Tabs';
import PageTitle from "../libs/PageTitle";
import uuid from 'react-uuid';
import MediaLibrary from '../libs/MediaLibrary';
import { uploadWithSignedUrl } from "../../utils/awsHelpers";

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function ProPortfolios() {
    const navigate = useNavigate();
    const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
    const [fileToBeUploaded, setFileToBeUploaded] = useState('');
    const [fileProgressSelfVideo, setFileProgressSelfVideo] = useState(5);
    const [hasPortfolioName, setHasPortfolioName] = useState(true);
    const [hasProjectDescription, setHasProjectDescription] = useState(true);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [portfolioName, setPortFolioName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [showPortfolios, setShowPortfolios] = useState(true);
    const [showProcessingVideo, setShowProcessingVideo] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [videosResponses, setVideosResponses] = useState([]);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const uploadType = 'portfolio';
    const proTestimonialProfileBucketName = 'fazzad-pros-portfolio-testimonial-videos-source';
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const thumbNailPrefix = 'fazzad-thumbnail-00001';
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    const userType = 500002;
    const userId = sessionStorage.getItem('userId');
    const userTypeToBeChecked = process.env.REACT_APP_PAID_PRO_USER_TYPE_TO_BE_CHECKED;
    const handleGoToLogin = () => navigate('/login');
    const videoRef = useRef(null);
    sessionStorage.setItem('isPropertyOwner', 'false');

    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            getUserProfile(userId, userType, userTypeToBeChecked);
        }
    }, []);

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const { isGoodToGo } = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (isGoodToGo) {
            getProsVideos('portfolio', userId, 2);
        } else {
            setShowNotAllowed(true);
        }
    }

    const checkProjectDescription = (projectDescription) => {
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
        } else {
            setHasProjectDescription(true);
        }
    }

    const sendUploadError = (err) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendGenericErrorEmail`, { errorSubject: 'Error Uploading Testimonial video', errorMessage: 'Check the cors issues' }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('error sending generic error email = ', error);
        })
    }

    const checkPortfolioName = (portfolioName) => {
        if (portfolioName.trim().length === 0) {
            setHasPortfolioName(false);
        } else {
            setHasPortfolioName(true);
        }
    }

    const getProsVideos = (videoType, userId, userType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, { videoType: videoType, userId: userId, userType: userType }).then((response) => {
            setVideosResponses(response.data);
        }).catch(function (error) {
            console.log('error getting pros videos = ', error);
        })
    }
    const checkUploadForm = () => {
        let isValid = true;
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }
        if (portfolioName.trim().length === 0) {
            setHasPortfolioName(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }
        return isValid;
    }

    const saveVideoUploaded = (userId, uploadType, uploadFileName, portfolioName, projectDescription, referenceNumber) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveProVideos`, { userId: userId, videoType: uploadType, uploadFileName: uploadFileName, portfolioName: portfolioName, projectDescription: projectDescription, referenceNumber: referenceNumber }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const changeHandlerUploads = (event) => {
        event.preventDefault();
        setShowErrorMessage(false);
        const chosenFile = event.target.files[0].name;
        setFileToBeUploaded(chosenFile);
        const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;

        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidVideo(false);
        } else {
            setIsValidVideo(true);
            setSelectedUploads(event.target.files);
        }
        videoRef.current.blur();
    }

    const handleUpload = async () => {
        const uuids = uuid();
        let selfVideoArray = [];
        try {
            if (selectedUploads.length > 0) {
                if (checkUploadForm()) {
                    setFileProgressSelfVideo(0);
                    setShowErrorMessage(false);
                    setShowProcessingVideo(true);
                    setShowProcessing(false);
                    setErrorUploadingVideo(false);
                    selfVideoArray = [...selectedUploads];
                    let videoArray = [];
                    videoArray = selfVideoArray.splice(0, 1);
                    try {
                        await Promise.all(
                            videoArray.map(async (file) => {
                                const fileName = cleanMediaUrl(file.name);
                                const key = `${uuids}/${fileName}`;

                                await uploadWithSignedUrl(file, proTestimonialProfileBucketName, key, (myProgress) => {
                                    setFileProgressSelfVideo(myProgress);
                                });

                                setShowProcessingVideo(false);
                                setShowInfo(false);
                                setShowProcessing(true);
                                setFileProgressSelfVideo(0);
                                saveVideoUploaded(userId, uploadType, fileName, portfolioName, projectDescription, uuids);
                                setTimeout(() => {
                                    setShowInfo(true);
                                    setShowProcessing(false);
                                    setShowPortfolios(true)
                                    setShowUploadVideo(false);
                                    getProsVideos('portfolio', userId, 500002);
                                }, process.env.REACT_APP_TIEMOUT);
                            })
                        );
                    } catch (e) {
                        setShowProcessingVideo(false);
                        setPortFolioName('');
                        setProjectDescription('');
                        setFileToBeUploaded('');
                        setSelectedUploads([]);
                        setErrorUploadingVideo(true);
                        sendUploadError(e);
                    }
                }
            } else {
                setShowErrorMessage(true);
            }
        } catch (e) {
            setShowInfo(true);
            setShowUploadVideo(false);
            setShowProcessing(false);
            console.error(`Uploading Videos file error: ${e}`);
        }
    };

    const clearEmojis = (string, type) => {
        switch (type) {
            case 'projectDescription':
                setProjectDescription(string.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''));
                break;
            case 'portfolioName':
                setPortFolioName(string.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''));
                break;
            default:
                break;
        }
    }

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <Tabs
                        userType={500002}
                        setShowProReq={null}
                        currentTab={'portfolio'}
                    />

                    <PageTitle
                        headerText={`Portfolio`}
                    />
                    <MediaLibrary
                        userId={parseInt(userId)}
                        userType={userType}
                        videoType={'Portfolio'}
                    />

                    {/* <div id="app-container">  
                        {showNotAllowed ?
                            <ModalInfo
                                messageHeader={"You are not allowed to see this page."}
                                messageBody={"This page is just for registered pros and vendors."}
                            />
                        :''
                        }              
                        <main className="fz-feed"> 
                            <div className="tabs">
                                {showPortfolios ?
                                    <>
                                        <Tabs
                                            userType={userType}
                                            setShowProReq={null}
                                            currentTab={'portfolios'}
                                        />
                                        <div id="tabpanel-1" role="tabpanel" tabIndex="0" aria-labelledby="tab-1">
                                            <div className="col six fz-video-item">
                                                <div className="fz-header-title fz-container fz-between">
                                                    <div className="inner block fz-start">
                                                        <h2 className="h1-like">Portfolio</h2>
                                                    </div>
                                                    <div className="inner button-group fz-end">
                                                        <button type="button" className="button-m button-primary" onClick={(e) => {setShowUploadVideo(true)} }>
                                                            <span className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            </span>
                                                            <span className="text">Upload portfolio video</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <br />
                                                <p className="block fz-start">Make videos of your best work and upload them here.</p>
                                                { videosResponses.length > 0 ?
                                                    <div id="po-feed-container" className="gallery-view">
                                                        {videosResponses.map((video, index) => {
                                                            return(
                                                                <div className="po-feed-item" key={`resp_${video.id}`}>
                                                                    <div className="video-frame" key={`frame${video.id}`}>
                                                                        <a href="/" onClick={(e) => {e.preventDefault(); navigate(`/videoplayer/${video.id}/${video.referenceNumber}?type=library&userId=${video.userId}`)}}>
                                                                            <img src={`${s3ThumbnailBucketPrefix}/${thumbNailPrefix}-${video.referenceNumber}/${video.videoFileName.replace('mp4', 'jpg')}`} alt="" key={`img_${video.id}`} />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                : 
                                                    <AlertMessage
                                                        messageHeader={"You don't have any portfolio video uploaded."}
                                                        messageBody={""}
                                                        startDate={null}
                                                        setShowWarningContainerMessage={''}
                                                        messageType={"informational"}
                                                        showButton={false}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </>   
                                : ''
                                }                    
                            </div>
                        </main>  
                        { showUploadVideo ?
                            <>
                            <div id="modal-5" className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <h2 className="modal-title h4">Portfolio file upload</h2>
                                    </div>
                                    <div className="modal__content">
                                        {
                                            showProcessingVideo ?
                                                <AlertMessage
                                                    messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                                    messageBody={""}
                                                    startDate={null}
                                                    setShowWarningContainerMessage={''}
                                                    messageType={"informational"}
                                                    showButton={false}
                                                />
                                            : ''
                                        }
                                        {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                            <>
                                            <div className={`form-field text-input ${!hasPortfolioName ? 'error' : ''}`}>
                                                <label htmlFor="portfolioName">Portfolio name</label>
                                                <input
                                                    name="portfolioName"
                                                    id="portfolioName"
                                                    type="text"
                                                    aria-required="true"
                                                    placeholder=""
                                                    value={portfolioName}
                                                    onFocus={(e) => { setHasPortfolioName(true); } }
                                                    onBlur={(e) => { clearEmojis(e.target.value, 'portfolioName'); checkPortfolioName(e.target.value); } }
                                                    onChange={(e) => { setPortFolioName(e.target.value); } }
                                                >
                                                </input>
                                                {!hasPortfolioName ?
                                                    <p className="validation-message">Enter portfolio name.</p>
                                                : ''}
                                            </div>
                                            <div className={`form-field text-area ${!hasProjectDescription ? 'error' : ''}`}>
                                                <label htmlFor="project-description">Description</label>
                                                <div className="input-container">
                                                    <textarea
                                                        name="Project description"
                                                        id="project-description"
                                                        type="text"
                                                        aria-required="true"
                                                        placeholder=""
                                                        value={projectDescription}
                                                        onFocus={(e) => { setHasProjectDescription(true); } }
                                                        onBlur={(e) => { clearEmojis(e.target.value, 'projectDescription'); checkProjectDescription(e.target.value); } }
                                                        onChange={(e) => { setProjectDescription(e.target.value); } }
                                                    >
                                                    </textarea>
                                                </div>
                                                {!hasProjectDescription ?
                                                    <p className="validation-message">Enter project description</p>
                                                :''
                                                }
                                            </div>
                                            </>  
                                        : ''
                                        } 
                                    
                                        {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                            <div className={`form-field file-input ${!isValidVideo || showErrorMessage ? 'error' : ''}`}>
                                                <input type="file" id="fileupload" className="file-input__input" onChange={(e) => {setShowErrorMessage(false); setIsValidVideo(true); changeHandlerUploads(e)}} ref={videoRef}/>
                                                <label htmlFor="fileupload" className="button button-m button-tertiary">
                                                    <span className="icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                                                    <span className="text">Choose video&hellip;</span>
                                                </label>
                                                {showErrorMessage ?
                                                    <p className="validation-message">Choose a video first.</p>
                                                    :''
                                                }
                                                {!isValidVideo ?
                                                    <p className="validation-message">{videoFormatError}</p>
                                                    :''
                                                }
                                                {fileToBeUploaded !== '' ?<p>{fileToBeUploaded}</p> : ''}
                                            </div>
                                        : errorUploadingVideo ?
                                            <div className="alert-container">
                                                <div className="alert alert--error">
                                                    <div className="alert__content">
                                                        <p className="alert__heading">There was an error uploading the video. Please try it again.</p>
                                                    </div>
                                                    <button type="button" className="button-tertiary button-s button-icon alert__close" onClick={() => setErrorUploadingVideo(false)}>
                                                        <span className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        : ''
                                        }
                                        <div className='file-input-container block'>
                                            <div className='file-input-container block'>
                                                {
                                                    showProcessingVideo ?
                                                        <span>
                                                            <small>
                                                                <span className='gray-600'>
                                                                Uploading video  <progress className='progress-bar' value={fileProgressSelfVideo} max='100'></progress> &nbsp;
                                                                    {fileProgressSelfVideo} of 100% completed
                                                                </span>&nbsp;&nbsp;&nbsp;<span className="loader loader--s"></span>
                                                            </small> 
                                                        
                                                        </span>
                                                    : ''
                                                }
                                                {showProcessing ?
                                                    <AlertMessage
                                                        messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                                        messageBody={""}
                                                        startDate={null}
                                                        setShowWarningContainerMessage={''}
                                                        messageType={"informational"}
                                                        showButton={false}
                                                        showLoader={true}
                                                    />
                                                : ''
                                                }
                                            </div> 
                                        </div> 
                                    </div>
                                    {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                        <div className="modal__action">
                                            <div className="button-group">
                                                <button 
                                                    type='button'
                                                    id='uploadbutton'
                                                    className='button button-m button-primary'
                                                    onClick={() => {handleUpload()}}
                                                >  
                                                    Upload video
                                                </button>
                                                                    
                                                <button 
                                                    type='reset'                        
                                                    aria-label='Exit'
                                                    value='Exit'
                                                    className='button button-m butto-secondary'
                                                    onClick={() => {
                                                        setShowUploadVideo(false);
                                                        setShowErrorMessage(false);
                                                        setPortFolioName('');
                                                        setProjectDescription('');
                                                        setIsValidVideo(true);
                                                    }}
                                                >
                                                    Exit
                                                </button>
                                            </div>
                                        </div>
                                    : ''
                                    }  
                                </div>
                            </div>
                            </>
                        : ''
                        }                    
                    </div> */}

                </div>
            </main>
        </div>
    )
}


