import React from 'react';
function AccountDetailsForm({
    companyName, hasCompanyName, setCompanyName, setHasCompanyName,
    password, hasPassword, setPassword, setHasPassword,
    confirmPassword, hasConfirmPassword, setConfirmPassword, setHasConfirmPassword, 
    phone, hasPhoneNumber, setPhone, setHasPhoneNumber, formatPhoneNumber,
    firstName, hasFirstName, setFirstName,setHasFirstName,
    lastName, hasLastName, setLastName, setHasLastName,
    emailProfile, hasEmailProfile, setEmailProfile, setHasEmailProfile,
    formValidator, accountType
}) {
    return(
        <section className="form-details">
            {accountType !== 'propertyowner' && (
                <>
                <h2 className="h4">{accountType}</h2>
                <div className="form-row col-2">
                    <div className={`form-field text-input ${!hasCompanyName ? 'error' : ''}`}>
                        <label htmlFor="companyname">Company Name</label>
                        <input
                            type="text"
                            id="companyname"
                            value={companyName}
                            onBlur={(e) => {setCompanyName(formValidator.clearEmojis(e.target.value))}}
                            onFocus={(e) => {setHasCompanyName(true);}}
                            onChange={(e) => {setCompanyName(e.target.value); }}
                        />
                        {!hasCompanyName ? 
                            <p className="validation-message">Enter companyname name.</p>
                        : ''
                        }
                    </div>
                </div>
                </>
            )}
            <div className="form-row col-2">
                <div className={`form-field text-input ${!hasFirstName ? 'error' : ''}`}>
                    <label htmlFor="firstName">First Name</label>
                    <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        value={firstName}
                        onBlur={(e) => {setFirstName(formValidator.clearEmojis(e.target.value))}} 
                        onFocus={(e) => {setHasFirstName(true);}}
                        onChange={(e) => {setFirstName(e.target.value); }}
                    />
                    {!hasFirstName ? 
                        <p className="validation-message">Enter first name.</p>
                    : ''
                    }
                </div>
                <div className={`form-field text-input ${!hasLastName ? 'error' : ''}`}>
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        value={lastName}
                        onBlur={(e) => {setLastName(formValidator.clearEmojis(e.target.value))}}
                        onFocus={(e) => {setHasLastName(true);}}
                        onChange={(e) => {setLastName(e.target.value); }}
                    />
                    {!hasLastName ? 
                        <p className="validation-message">Enter last name.</p>
                    : ''
                    }
                </div>
            </div>
            <div className="form-row col-2">
                <div className={`form-field text-input ${!hasEmailProfile ? 'error' : ''}`}>
                    <label htmlFor="email">Email</label>
                    <input
                        id='email'
                        type='email'
                        autoComplete='email'
                        value={emailProfile}
                        onBlur={(e) => {setEmailProfile(formValidator.clearEmojis(e.target.value))}}
                        onFocus={(e) => {setHasEmailProfile(true);}}
                        onChange={(e) => {setEmailProfile(e.target.value); }}
                    />  
                    {!hasEmailProfile ? 
                        <p className="validation-message">Enter valid email.</p>
                    : ''
                    }
                </div>
                <div className={`form-field text-input ${!hasPhoneNumber ? 'error' : ''}`}>
                    <label htmlFor="phone">Phone number (10 digits only)</label>
                    <input
                        id='phone'
                        name='phone'
                        type='tel'
                        maxLength={10}
                        value={phone}
                        onBlur={(e) => {formValidator.formatPhoneNumber(e.target.value, setPhone);}}
                        onChange={(e) => {setPhone(e.target.value); }}
                        onFocus={(e) => {setHasPhoneNumber(true);}}
                    /> 
                    {!hasPhoneNumber ? 
                        <p className="validation-message">Enter a 10 digits phone number.</p>
                    : ''
                    }
                </div>
            </div>
            {accountType !== 'propertyowner' && (
                <div className="form-row col-2">
                    <div className={`form-field text-input ${!hasPassword ? 'error' : ''}`}>
                        <label htmlFor="current-password">Password</label>
                        <input
                            id='current-password'
                            type='password'
                            autoComplete='password'
                            onBlur={(e) => {setPassword(formValidator.clearEmojis(e.target.value))}}
                            onFocus={(e) => {setHasPassword(true); setHasConfirmPassword(true);}}
                            value={password} onChange={(e) => {setPassword(e.target.value); }}
                        />
                        {!hasPassword ? 
                            <p className="validation-message">Enter password.</p>
                        : ''
                        }
                    </div>
                    <div className={`form-field text-input ${!hasConfirmPassword ? 'error' : ''}`}>
                        <label htmlFor="confirm-password">Confirm password</label>
                        <input
                            id='confirm-password'
                            type='password'
                            autoComplete='password'
                            value={confirmPassword}
                            onBlur={(e) => {setConfirmPassword(formValidator.clearEmojis(e.target.value))}}
                            onFocus={(e) => {setHasConfirmPassword(true); setHasConfirmPassword(true);}}
                            onChange={(e) => {setConfirmPassword(e.target.value); }}
                        />
                        {!hasConfirmPassword ? 
                            <p className="validation-message">Enter matching confirm password.</p>
                        : ''
                        }
                    
                    </div>
                </div>
            )}
        </section>
    )
}
export default AccountDetailsForm;