import React from 'react';
export default function CityFilter({setSelectedCity, selectedCity, citiesArray}) {
    return (
        <div className="form-field select">
            <label htmlFor="sort-by-city">Choose City</label>
            <select id="sort-by-city" value={selectedCity} onChange={e => setSelectedCity(e.target.value)}>
                <option value="">All</option>
                {citiesArray.map((city, index) => (
                    <option key={`city${index}`} value={city}>{city}</option>
                ))}
            </select>
        </div>
    )
};
