
import React, { useState } from "react";

import {
  PayPalScriptProvider,
  usePayPalCardFields,
  PayPalCardFieldsProvider,
  PayPalCardFieldsForm,
  PayPalButtons,
} from "@paypal/react-paypal-js";

export default function PayPalPage() {
  const [isPaying, setIsPaying] = useState(false);


  async function createOrder(data) {
    return fetch(`${process.env.REACT_APP_LOCALHOST}/createPayPalOrder`, {
      method: "POST",
      // Use the "body" parameter to optionally pass additional order information
      body: JSON.stringify({
        card: {
          attributes: {
            verification: {
              method: "SCA_ALWAYS",
            },
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((order) => order.id)
      .catch((err) => {
        console.error(err);
      });
  }

  function onApprove(data) {
    return fetch(`${process.env.REACT_APP_LOCALHOST}/orders/${data.orderID}/capture`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((orderData) => {
        // Successful capture!
      })
      .catch((err) => {});
  }

  function onError(error) {
    // Do something with the error from the SDK
  }


  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENTID,
        components: "card-fields,buttons",
         disableFunding: "paylater",
         enableFunding: "venmo",
        currency: "USD",
        intent: "capture", 
      }}
    >
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
      />
      <PayPalCardFieldsProvider
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
      >
        <PayPalCardFieldsForm />
        
        {/* Custom client component to handle card fields submission */}
        <SubmitPayment
          isPaying={isPaying}
          setIsPaying={setIsPaying}
        />
      </PayPalCardFieldsProvider>
    </PayPalScriptProvider>
  );
}

const SubmitPayment = ({ isPaying, setIsPaying, billingAddress }) => {
  const { cardFieldsForm, fields } = usePayPalCardFields();

  const handleClick = async () => {
    if (!cardFieldsForm) {
      const childErrorMessage =
        "Unable to find any child components in the <PayPalCardFieldsProvider />";

      throw new Error(childErrorMessage);
    }
    const formState = await cardFieldsForm.getState();

    if (!formState.isFormValid) {
      return alert("The payment form is invalid");
    }
    setIsPaying(true);

    cardFieldsForm.submit({ billingAddress }).catch((err) => {
      setIsPaying(false);
    });
  };

  return (
    <button
      className={isPaying ? "btn" : "btn btn-primary"}
      style={{ float: "right" }}
      onClick={handleClick}
    >
      {isPaying ? <div className="spinner tiny" /> : "Pay"}
    </button>
  );
};             
