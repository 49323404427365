const copyToClipBoard = async (textToCopy) => {
    try {
      const newText = textToCopy.replace(/\bnull\b/g, "");
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(newText);
      } else {
        fallbackCopyToClipboard(newText);
      }
    } catch (err) {
      console.error('Failed to copy text:', err);
      throw err;
    }
  };
  
  const fallbackCopyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'fixed';
    textarea.style.top = '-9999px';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
  
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Failed to copy text:', err);
      throw err;
    } finally {
      document.body.removeChild(textarea);
    }
  };
  export {copyToClipBoard};


  