import styles from './pdf-page-select.module.scss';
import React from 'react';

/*interface PdfPageSelectProps {
  pageNumber: number;
  numPages: number;
  handlePageChange: (page: number) => void;
}*/

/**
 *
 * @param pageNumber
 * @param handlePageChange
 * @param selectedPages
 * @returns {JSX.Element}
 * @constructor
 */
export function PdfPageSelect({ pageNumber, handlePageChange, selectedPages }) {

  return (
      <div className={styles.selectWrapper + " " + "form-field select"}>
        <select
            id="pageNumber"
            value={pageNumber}
            onChange={(e) => {
              handlePageChange(Number(e.currentTarget.value));
            }}
            className={styles.select}
        >
          {selectedPages.map((page, index) => {
            return <option key={index + 1} value={Number(page.pageNumber)}>{page.pageName}</option>;
          })}
        </select>
      </div>
  );
}

export default PdfPageSelect;
