import Axios from 'axios';

export const getLatestUncompletPackage = (userId) => {

    return new Promise((resolve, reject) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/package/getLatestUnCompletePackage`, { userId: userId }).then((response) => {
            resolve(response.data);
        }).catch(function (error) {
            console.log('Error getLatestUncompletPackage', error);
            return resolve(null);
        });
    });
}