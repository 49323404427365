import React, { useState } from 'react';
import AppContext from './AppContext';
import { getAccessToken, getUserInfo } from "./utils/authHelpers";
const AppProvider = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [myFolders, setMyFolders] = useState([]);
  const [numberOfSubFolders, setNumberOfSubFolders] = useState(0);
  const [currentFolder, setCurrentFolder] = useState('');
  const [userId, setUserId] = useState('');
  const [inviteeId, setInviteeId] = useState('');
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(0);
  const [isPro, setIsPro] = useState(0);
  const [currentPackageId, setCurrentPackageId] = useState('');
  const [packageAddress, setPackageAddress] = useState('');
  const [packageId, setPackageId] = useState('');
  const [packageName, setPackageName] = useState('');
  const [userName, setUserName] = useState('');
  const [isPropertyOwner, setIsPropertyOwner] = useState(false);
  const [userType, setUserType] = useState(500001);
  const [videoFileName, setVideoFileName] = useState('');
  const [transactionCode, setTransactionCode] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [userInfo, setUserInfo] = useState(getUserInfo());
  const [accessToken, setAccessToken] = useState(getAccessToken());

  return (
    <AppContext.Provider value={{
      selectedFiles, setSelectedFiles,
      myFolders, setMyFolders,
      numberOfSubFolders, setNumberOfSubFolders,
      currentFolder, setCurrentFolder,
      userId, setUserId,
      inviteeId, setInviteeId,
      email, setEmail,
      currentPackageId, setCurrentPackageId,
      isAdmin, setIsAdmin,
      isPro, setIsPro,
      packageAddress, setPackageAddress,
      packageId, setPackageId,
      packageName, setPackageName,
      userName, setUserName,
      userType, setUserType,
      videoFileName, setVideoFileName,
      isPropertyOwner, setIsPropertyOwner,
      transactionCode, setTransactionCode,
      referenceNumber, setReferenceNumber,
      userInfo, setUserInfo,
      accessToken, setAccessToken
    }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;