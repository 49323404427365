import React from 'react';
import styles from './image-type-select.module.scss';

const ImageTypeSelector = ({ activeType, setActiveType }) => {
  const buttonTypes = [
    { type: 'IMAGE', label: 'View all' },
    { type: 'STILL_IMAGE', label: 'Still picture' },
    { type: '360_IMAGE', label: '360 picture' },
  ];

  return (
      <div className={styles.container}>
        {buttonTypes.map(({ type, label }) => (
            <button
                key={type}
                className={`${styles.button} ${activeType === type ? styles.active : ''}`}
                onClick={() => setActiveType(type)}
            >
              {label}
            </button>
        ))}
      </div>
  );
};

export default ImageTypeSelector;