import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../libs/Header'
import Axios from 'axios';

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function ProFreePortfolios() {
    const [showMore, setShowMore] = useState({});
    const [videosResponses, setVideosResponses] = useState([]);
    const { userId, leadId } = useParams();
    const navigate = useNavigate();
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_BUCKET_PROS_VIDEOS_PREFIX;
    sessionStorage.setItem('isPropertyOwner', 'false');
    useEffect(() => {
        getProsVideos('portfolio', userId, 2);
    }, []);

    const getProsVideos = (videoType, userId, userType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, {videoType: videoType, userId: userId, userType: userType}).then((response) => {
            setVideosResponses(response.data);
        }).catch(function (error) {
            console.log('error getting pros videos = ',error);
        })
    }

    const getPackageDescription = (packageDesc, index) => {
        if (!showMore[index]) {
            return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH) + '...';
        } else {
            return packageDesc;
        }
    }
      
    const handleReadMore = (index) => {
        setShowMore((prevState) => {
            const updatedShowMore = { ...prevState };
            updatedShowMore[index] = !updatedShowMore[index];
            return updatedShowMore;
        });
    }

    return(
        <div className="row full">
        <Header />
        <div className="row">                 
            <div className="col ten">
                <ul className="fz-breadcrumb">
                    <li>
                        <a href="/" onClick={(e) => {e.preventDefault(); navigate(`/leads/${leadId}`)}}><small>Home</small></a>
                        <i className="icon-right fz-divider"></i>
                    </li>
                    <li><small>Pro portfolio</small></li>
                </ul>                    
                <main className="fz-feed">
                    <div className="fz-header-title fz-container fz-column">
                        <div className="fz-title">
                            <div className="fz-center">
                                <h1 className="h3-like">{videosResponses[0]?.firstName} {videosResponses[0]?.lastName}{videosResponses.length > 0 ?',': ''} <strong>{videosResponses[0]?.company}</strong></h1>
                            </div>
                        </div>
                    </div>
                    <div className="tabs">
                        <div className="fz-flex fz-center block-xx">
                            <div role="tablist" aria-labelledby="tablist-1" className="fz-flex fz-center automatic block">
                                <button id="tab-3"
                                    className="flat"
                                    type="button"
                                    role="tab"
                                    aria-selected="true"
                                    aria-controls="tabpanel-3">
                                    <span className="focus">
                                        <i title="Portfolio" className="icon-list"></i>
                                        Portfolio
                                    </span>
                                </button>
                                <button id="tab-4"
                                    className="flat"
                                    type="button"
                                    role="tab"
                                    aria-selected="false"
                                    aria-controls="tabpanel-4"
                                    tabIndex="-1"
                                    onClick={(e) => {
                                        navigate(`/profreetestimonials/${userId}/${leadId}`);
                                    } }
                                >
                                    <span className="focus">
                                        <i title="Testimonial" className="icon-selfie"></i>
                                        Testimonial
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div id="tabpanel-3" role="tabpanel" tabIndex="0" aria-labelledby="tab-3">
                            <div className="fz-response-feed block-xx mcol six">
                                { videosResponses.length > 0 ?
                                    videosResponses.map((video, index) => {
                                        return (
                                            <div className="fz-response-container" key={`container_${index}`}>
                                                <video className="fz-video" width="100%" height="auto" controls key={`video_${index}`}>
                                                <source src={`${s3BucketVideoPrefix}/${video.referenceNumber}/${video.videoFileName}`} type='video/mp4' />
                                                </video>
                                                <div className="fz-padding-def fz-start fz-column">
                                                    <h3 className="gray-700">{video.projectName}</h3>
                                                    <h4 className="gray-600">Scope of work</h4>

                                                    <p  className='preserve-html'>{getPackageDescription(video.projectDescription, video.id)}</p>
                                                    {video.projectDescription.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH && (
                                                        <>
                                                        <br />
                                                        <a href="/" onClick={(e) => { e.preventDefault(); handleReadMore(video.id); e.target.blur();}}>
                                                        {showMore[video.id] ? 'Read less' : 'Read more'}
                                                        </a>
                                                        </>
                                                    )}               
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="fz-alert alert-info">
                                        <i className="icon-info"></i>                                
                                        <strong>Info</strong>
                                        <p>There are no portfolio videos available for this pro yet.</p>
                                    </div>
                                }
                            </div>
                        </div>
                         
                    </div>
                </main>  
            </div>
        </div>
    </div>
  )
}


