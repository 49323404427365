import React, { useState } from 'react';
import getSvg from '../../utils/getSvg';

const initialFolderStructure = [
  {
    id: 1,
    name: "First floor",
    subfolders: [
      {
        id: 2,
        name: "Hallway bathroom",
        subfolders: [
          {
            id: 3,
            name: "Hallway closet",
            subfolders: []
          }
        ]
      },
      {
        id: 4,
        name: "Kitchen",
        subfolders: []
      }
    ]
  },
  {
    id: 5,
    name: "Second floor",
    subfolders: []
  }
];

// Function to find and update a folder/subfolder in the structure
const addSubfolderToStructure = (structure, parentId, newSubfolder) => {
  return structure.map(folder => {
    if (folder.id === parentId) {
      return { ...folder, subfolders: [...folder.subfolders, newSubfolder] };
    } else if (folder.subfolders.length > 0) {
      return { ...folder, subfolders: addSubfolderToStructure(folder.subfolders, parentId, newSubfolder) };
    }
    return folder;
  });
};

// Function to delete a folder/subfolder from the structure
const deleteFolderFromStructure = (structure, folderId) => {
  return structure.map(folder => {
    if (folder.id === folderId) {
      // If the current folder matches the id, remove it
      return null;
    } else if (folder.subfolders.length > 0) {
      // If the current folder has subfolders, recursively delete the folder from subfolders
      return {
        ...folder,
        subfolders: deleteFolderFromStructure(folder.subfolders, folderId)
      };
    }
    return folder;
  }).filter(Boolean); // Filter out null values (deleted folders)
};

// Function to rename a folder/subfolder in the structure
const renameFolderInStructure = (structure, folderId, newName) => {
  return structure.map(folder => {
    if (folder.id === folderId) {
      // If the current folder matches the id, update its name
      return { ...folder, name: newName };
    } else if (folder.subfolders.length > 0) {
      // If the current folder has subfolders, recursively rename the folder in subfolders
      return {
        ...folder,
        subfolders: renameFolderInStructure(folder.subfolders, folderId, newName)
      };
    }
    return folder;
  });
};

const Folder = ({ folder, level = 1, isLastInParent = false, onAddSubfolder, onDeleteFolder, onRenameFolder }) => {
  const [isVisible, setIsVisible] = useState(true); // To control the visibility of the folder's contents.
  const hasSubfolders = folder.subfolders && folder.subfolders.length > 0;

  // State and function for handling modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameFolderId, setRenameFolderId] = useState('');
  const [renameFolderName, setRenameFolderName] = useState('');

  const handleAddSubfolderClick = () => {
    const newSubfolder = { id: Date.now(), name: newFolderName, subfolders: [] };
    onAddSubfolder(folder.id, newSubfolder);
    setIsModalOpen(false); // Close the modal
    setNewFolderName(''); // Reset the input field
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
    setSelectedFiles(files); // Update the state with the selected files
  };

  const handleDeleteFolder = () => {
    onDeleteFolder(folder.id);
  };

  const handleRenameFolderClick = (id, name) => {
    setRenameFolderId(id);
    setRenameFolderName(name);
    setIsRenameModalOpen(true);
  };

  const handleRenameFolderConfirm = () => {
    onRenameFolder(renameFolderId, renameFolderName);
    setIsRenameModalOpen(false);
  };

  return (
    <div className={`l${level} ${hasSubfolders && !isLastInParent ? '' : 'no-backbone'} ${isLastInParent ? 'last' : ''}`}>
      {hasSubfolders && !isLastInParent && <div className="backbone"></div>}
      {level > 1 && <div className="curve"></div>}
      <section className="sub-folder">
        <div className="folder-title rounded">
          <div className="container-title">
            <button type="button" className="button-s button-icon" onClick={() => setIsVisible(!isVisible)}>
              <span className="icon">
                {getSvg('toggle')} {/* Ensure you have a toggle icon or similar */}
              </span>
            </button>
            <h3 className="h5">{folder.name}</h3>
          </div>
          <div className="button-group">
            <button title="New folder" type="button" className="button-s button-icon" onClick={() => setIsModalOpen(true)}>
              <span className="icon">
                {getSvg('folder')}
              </span>
            </button>
            <button title="Delete folder" type="button" className="button-s button-icon" onClick={handleDeleteFolder}>
              <span className="icon">
                {getSvg('delete')}
              </span>
            </button>
            <button title="Rename folder" type="button" className="button-s button-icon" onClick={() => handleRenameFolderClick(folder.id, folder.name)}>
              <span className="icon">
                {getSvg('edit')}
              </span>
            </button>
          </div>
        </div>
        <div className="file-upload__container">
          <div className="vertical-track">
            <div className="line"></div>
          </div>
          <div className="content">
            <div className="form-field file-input">
              <input type="file" id={`fileupload-${folder.id}`} onChange={handleFileSelect} className="file-input__input" multiple />
              <label htmlFor={`fileupload-${folder.id}`} className="button button-s button-tertiary">
                <span className="icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </span>
                <span className="text">Choose pictures…</span>
              </label>
              {selectedFiles.map((file, index) => ( // Display selected files
                <p key={index}>{file.name}</p>
              ))}
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div className="modal-container">
            <div className="modal">
              <div className="modal__header">
                Add Subfolder
              </div>
              <div className='modal-content'>
                <div className='row'>
                  <input type="text" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} placeholder="Folder name" />
                </div>
                <div className='row'>
                  <button onClick={handleAddSubfolderClick}>Add</button>
                  <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}
       
          <div className="modal-container">
            <div className="modal">
              <div className="modal__header">
                Rename
              </div>
              <div className='modal-content'>
                <div className='row'>
                  <input type="text" value={renameFolderName} onChange={(e) => setRenameFolderName(e.target.value)} placeholder="New folder name" />
                </div>
                <div className='row'>
                  <button onClick={handleRenameFolderConfirm}>Rename</button>
                  <button onClick={() => setIsRenameModalOpen(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
       
        {isVisible && folder.subfolders.map((subfolder, index) => (
          <Folder
            folder={subfolder}
            level={level + 1}
            key={subfolder.id}
            isLastInParent={index === folder.subfolders.length - 1}
            onAddSubfolder={onAddSubfolder}
            onDeleteFolder={onDeleteFolder}
            onRenameFolder={onRenameFolder}
          />
        ))}
      </section>
    </div>
  );
};

const FolderApp = () => {
  const [folderStructure, setFolderStructure] = useState(initialFolderStructure);

  const handleAddSubfolder = (parentId, newSubfolder) => {
    const updatedStructure = addSubfolderToStructure(folderStructure, parentId, newSubfolder);
    setFolderStructure(updatedStructure);
  };

  const handleDeleteFolder = (folderId) => {
    const updatedStructure = deleteFolderFromStructure(folderStructure, folderId);
    setFolderStructure(updatedStructure);
  };

  const handleRenameFolder = (folderId, newName) => {
    const updatedStructure = renameFolderInStructure(folderStructure, folderId, newName);
    setFolderStructure(updatedStructure);
  };

  return (
    <div>
      <main id="fazzad-main">
        <div id="app-container">
          <div id="create-folders">
            <h1 className="h3">Create As-built Package</h1>
            <div id="folder-wrapper" className="outlined">
              <div className="structure">
                {folderStructure.map((folder, index) => (
                  <Folder
                    folder={folder}
                    key={folder.id}
                    isLastInParent={index === folderStructure.length - 1}
                    onAddSubfolder={handleAddSubfolder}
                    onDeleteFolder={handleDeleteFolder}
                    onRenameFolder={handleRenameFolder}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FolderApp;
