// hooks/usePlanAndPicture.js
import {useState, useCallback, useRef, useEffect} from 'react';
import axios from 'axios';

function convertForSaving(frontendData) {
  const planAndPicture = {
    planName: frontendData.planName,
    pdfPages: [],
    pdfId: frontendData.pdfId
  };

  // Process selected pages and pins
  frontendData.selectedPages.forEach(selectedPage => {
    const pdfPage = {
      pageNumber: selectedPage.pageNumber,
      pageName: selectedPage.pageName,
      isSelected: true,
      pins: []
    };

    // Add pins for this page if they exist
    if (frontendData.pins[selectedPage.pageNumber]) {
      pdfPage.pins = frontendData.pins[selectedPage.pageNumber].map(pin => ({
        xCoordinate: pin.x,
        yCoordinate: pin.y,
        dataType: pin.dataType,
        additionalData: {
          imageUrl: pin.img,
          id: pin.id
        }
      }));
    }

    planAndPicture.pdfPages.push(pdfPage);
  });

  // Process pages without pins (if any)
  /*  Object.keys(frontendData.pins).forEach(pageId => {
        if (!planAndPicture.pdfPages.some(page => page.id === parseInt(pageId))) {
            planAndPicture.pdfPages.push({
                id: parseInt(pageId),
                isSelected: frontendData.pins[pageId].length > 0,
                pins: frontendData.pins[pageId].map(pin => ({
                    xCoordinate: pin.x,
                    yCoordinate: pin.y,
                    dataType: 'image',
                    additionalData: {
                        imageUrl: pin.img,
                        id: pin.id
                    }
                }))
            });
        }
    });*/

  return planAndPicture;
}

export const useFetchPlanAndPictureByPdfId = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPlanAndPicture = useCallback(async (pdfId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOCALHOST}/planandpictures/pdf/${pdfId}`);
      setData(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while fetching the plan and picture.');
      setData({});
    } finally {
      setLoading(false);
    }
  }, []);

  return {data, loading, error, fetchPlanAndPicture};
};

export const useUpdatePlanAndPicture = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updatePlanAndPicture = useCallback(async (id, updateData) => {
    setLoading(true);
    setError(null);
    const data = convertForSaving(updateData);
    try {
      const response = await axios.put(`${process.env.REACT_APP_LOCALHOST}/planandpictures/${id}`, data);
      return response.data;
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while updating the plan and picture.');
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  return {loading, error, updatePlanAndPicture};
};

export const useCreatePlanAndPicture = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createPlanAndPicture = useCallback(async (newData) => {
    setLoading(true);
    setError(null);
    const data = convertForSaving(newData);
    try {
      const response = await axios.post(`${process.env.REACT_APP_LOCALHOST}/planandpictures/`, data);
      return response.data;
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while creating the plan and picture.');
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  return {loading, error, createPlanAndPicture};
};

export const useDeletePlanAndPicture = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deletePlanAndPicture = useCallback(async (id) => {
    setLoading(true);
    setError(null);
    try {
      await axios.delete(`${process.env.REACT_APP_LOCALHOST}/planandpictures/${id}`);
      return true;
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while deleting the plan and picture.');
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  return {loading, error, deletePlanAndPicture};
};

export const useFetchPlanAndPicturesByPdfIds = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  const fetchPlanAndPictures = useCallback(async (pdfIds) => {
    if (!Array.isArray(pdfIds) || pdfIds.length === 0) {
      setError('Invalid input: pdfIds must be a non-empty array.');
      return;
    }

    // Cancel the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    abortControllerRef.current = new AbortController();

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_LOCALHOST}/planandpictures/pdf`,
          {pdfIds},
          {signal: abortControllerRef.current.signal}
      );
      setData(response?.data?.reduce((acc, curr) => {
        acc[curr.pdfId] = curr;
        return acc;
      }, {}));
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request canceled:', err.message);
        setData([]);
      } else {
        setError(err.response?.data?.error || 'An error occurred while fetching PlanAndPictures.');
        setData([]);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return {data, loading, error, fetchPlanAndPictures};
};

export const useGetPdfs = () => {
  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPdfs = useCallback(async (packageName) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${process.env.REACT_APP_LOCALHOST}/getpdfs`, {projectName: packageName});
      if (response.data.length > 0) {
        setPdfs(response.data);
      } else {
        console.log('no pdfs found');
        setPdfs([]);
      }
    } catch (error) {
      console.error('Error fetching PDFs:', error);
      setError(error.message || 'An error occurred while fetching PDFs');
    } finally {
      setLoading(false);
    }
  }, []);

  return {pdfs, loading, error, getPdfs};
};

export const useGetPdfsAndPlanAndPictures = (packageName) => {
  const [pdfs, setPdfs] = useState([]);
  const [planAndPictures, setPlanAndPictures] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [allUsedPhotos, setAllUsedPhotos] = useState([]);
  const abortControllerRef = useRef(null);

  const getPdfsAndPlanAndPictures = useCallback(async () => {
    setLoading(true);
    setError(null);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      const pdfResponse = await axios.post(
          `${process.env.REACT_APP_LOCALHOST}/getpdfs`,
          {projectName: packageName},
          {signal: abortControllerRef.current.signal}
      );

      if (pdfResponse.data.length > 0) {
        setPdfs(pdfResponse.data);

        const pdfIds = pdfResponse.data.map((item) => item.Key.replace(/\//g, '__').replace('.pdf', ''));

        const planAndPicturesResponse = await axios.post(
            `${process.env.REACT_APP_LOCALHOST}/planandpictures/pdf`,
            {pdfIds},
            {signal: abortControllerRef.current.signal}
        );

        setPlanAndPictures(planAndPicturesResponse.data);

        const photos = planAndPicturesResponse?.data?.flatMap(item =>
            item.pdfPages.flatMap(page =>
                page.pins.map(pin => pin.additionalData.imageUrl)
            )
        ) ?? []

        setAllUsedPhotos(photos);
      } else {
        console.log('No PDFs found');
        setPdfs([]);
        setPlanAndPictures([]);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request canceled:', err.message);
      } else {
        console.error('Error fetching data:', err);
        setError(err.message || 'An error occurred while fetching data');
      }
    } finally {
      setLoading(false);
    }
  }, [packageName]);

  useEffect(() => {
    setLoading(true);
    getPdfsAndPlanAndPictures().then(() => {
      setLoading(false);
    })

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [getPdfsAndPlanAndPictures]);

  return {pdfs, planAndPictures, loading, error, getPdfsAndPlanAndPictures, allUsedPhotos};
};