import React from 'react';
import getSvg from '../../utils/getSvg';
import { formatDate } from '../../utils/dateFormat';

function AlertMessage({ messageHeader, messageBody = "", startDate = null, messageType, messageBodyContinuation = null, showButton = true, showLoader = false, onButtonClick }) {
  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick(); // Call the callback function
    }
  };

  return (
    <div className="alert-container">
      <div className={`alert alert--${messageType}`}>
        {/* {getSvg(messageType)} */}
        <div className="alert__content">
          <div className="alert-inline__loader">
            {messageHeader !== '' ? <p className="alert__heading">{messageHeader}</p> : ''}
            {
              messageBody !== '' &&
              <>
                {showLoader ? <span className="loader loader--s"></span> :
                  <p
                    className="alert__message"
                    dangerouslySetInnerHTML={{ __html: messageBody + (startDate ? ` ${formatDate(startDate)} ${messageBodyContinuation}` : '') }}
                  />
                }
              </>
            }
          </div>
        </div>
        {showButton ?
          <button
            type="button"
            className="button-tertiary button-s button-icon alert__close"
            onClick={handleButtonClick}
          >
            {getSvg('close')}
          </button>
          : ''
        }
      </div>
    </div>
  );
}

export default AlertMessage;
