import axiosInstance from "./axiosInstance";
import Axios from "axios";

const getPackageInfoPublic = async (packageName) => {
    let packages = { "hasStillPictures": false, "has360Pictures": false, "hasDronePictures": false, "hasPdfs": false, "hasDwg": false, "hasFiles": false, response: [] };
    return Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackage`, { packageName: packageName }).then((response) => {
        if (response.data.length > 0) {
            if (response.data[0].hasStillPictures === 1) {
                packages.hasFiles = true;
                packages.hasStillPictures = true;
            }
            if (response.data[0].has360Pictures === 1) {
                packages.hasFiles = true;
                packages.has360Pictures = true;
            }
            if (response.data[0].hasDronePictures === 1) {
                packages.hasFiles = true;
                packages.hasDronePictures = true;
            }
            if (response.data[0].hasPdfs === 1) {
                packages.hasFiles = true;
                packages.hasPdfs = true;
            }
            if (response.data[0].hasDwg === 1) {
                packages.hasFiles = true;
                packages.hasDwg = true;
            }

            packages.response = response.data;
            sessionStorage.setItem('packageAddress', response.data[0].packageAddress);
        }
        return packages;
    }).catch(function (error) {
        console.log('error on package details gttting package = ', error);
    })
};

const getPackageInfo = async (packageName) => {
    let packages = { "hasStillPictures": false, "has360Pictures": false, "hasDronePictures": false, "hasPdfs": false, "hasDwg": false, "hasFiles": false, response: [] };
    return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/api/package/getPackage`, { packageName: packageName }).then((response) => {
        if (response.data) {
            if (response.data.hasStillPictures === 1) {
                packages.hasFiles = true;
                packages.hasStillPictures = true;
            }

            if (response.data.has360Pictures === 1) {
                packages.hasFiles = true;
                packages.has360Pictures = true;
            }
            if (response.data.hasDronePictures === 1) {
                packages.hasFiles = true;
                packages.hasDronePictures = true;
            }

            if (response.data.hasPdfs === 1) {
                packages.hasFiles = true;
                packages.hasPdfs = true;
            }

            if (response.data.hasDwg === 1) {
                packages.hasFiles = true;
                packages.hasDwg = true;
            }

            packages.response = response.data;
            sessionStorage.setItem('packageAddress', response.data.packageAddress);
        }
        return packages;
    }).catch(function (error) {
        console.log('error on package details getting package = ', error);
    })
};

const getPackageImageInfoPublic = async (pck) => {
    const scopeOfWorkName = 'scope-of-work-videos';
    const s3BucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const packageImageInfo = { "hasVideo": false, "scopeOfWorkVideoThumbnail": '', "scopeOfWorkVideo": '', response: [] };
    if (typeof pck.packageName !== 'undefined') {
        try {
            return Axios.post(`${process.env.REACT_APP_LOCALHOST}/uniqueImage`, { packageName: pck.packageName, pictureType: 'scope-of-work-videos' }).then((response) => {
                if (response.data.length > 0) {
                    packageImageInfo.response = response.data;
                    if (typeof response.data[0].Key !== 'undefined') {
                        const videoNameArray = response.data[0].Key.split('/');
                        const videoName = videoNameArray[videoNameArray.length - 1].replace('jpg', 'mp4');
                        packageImageInfo.scopeOfWorkVideoThumbnail = `${s3ThumbnailBucketPrefix}/${response.data[0].Key}`;
                        packageImageInfo.scopeOfWorkVideo = `${s3BucketPrefix}/${pck.packageName}/${scopeOfWorkName}/${videoName}`;
                        packageImageInfo.hasVideo = true;
                    } else {
                        packageImageInfo.hasVideo = false;
                    }
                } else {
                    packageImageInfo.hasVideo = false;
                }
                return packageImageInfo;
            }).catch(function (error) {
                packageImageInfo.hasVideo = false;
                console.log('error on getPackageImage = ', error)
            })
        } catch (err) {
            console.log('err = ', err);
            packageImageInfo.hasVideo = false;
        }
    }
}

const getPackageImageInfo = async (pck) => {
    const scopeOfWorkName = 'scope-of-work-videos';
    const s3BucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const packageImageInfo = { "hasVideo": false, "scopeOfWorkVideoThumbnail": '', "scopeOfWorkVideo": '', response: [] };
    if (typeof pck.packageName !== 'undefined') {
        try {
            return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/api/package/uniqueImage`, { packageName: pck.packageName, pictureType: 'scope-of-work-videos' }).then((response) => {
                if (response.data.length > 0) {
                    packageImageInfo.response = response.data;
                    if (typeof response.data[0].Key !== 'undefined') {
                        const videoNameArray = response.data[0].Key.split('/');
                        const videoName = videoNameArray[videoNameArray.length - 1].replace('jpg', 'mp4');
                        packageImageInfo.scopeOfWorkVideoThumbnail = `${s3ThumbnailBucketPrefix}/${response.data[0].Key}`;
                        packageImageInfo.scopeOfWorkVideo = `${s3BucketPrefix}/${pck.packageName}/${scopeOfWorkName}/${videoName}`;
                        packageImageInfo.hasVideo = true;
                    } else {
                        packageImageInfo.hasVideo = false;
                    }
                } else {
                    packageImageInfo.hasVideo = false;
                }
                return packageImageInfo;
            }).catch(function (error) {
                packageImageInfo.hasVideo = false;
                console.log('error on getPackageImage = ', error)
            })
        } catch (err) {
            console.log('err = ', err);
            packageImageInfo.hasVideo = false;
        }
    }
}

const getPackageDescriptionInfo = (packageDesc, index, showMore) => {
    if (!showMore[index]) {
        if (packageDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH) {
            return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH) + '...';
        } else {
            return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
        }
    } else {
        return packageDesc;
    }
}

export { getPackageInfo, getPackageImageInfo, getPackageDescriptionInfo, getPackageInfoPublic, getPackageImageInfoPublic };
