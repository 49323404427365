import React from 'react';
export default function PageTitle({headerText, subHeaderText, showSubHeader}) {

  const buildContent = () => {
    if (headerText !== '') {
      return (
        <div id="page-title">
            <h1 className="h3">{headerText}</h1>
            {showSubHeader && <p>{subHeaderText}</p>}
        </div>
      );
    } else {
      // Directly return JSX, no need for additional braces or variable
      return showSubHeader ? <p>{subHeaderText}</p> : null;
    }
  };
  
  return (
    buildContent()
  );
  
};
