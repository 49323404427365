import React from 'react';
import { changePassword } from "../../utils/userHelper";
import AlertMessage from "./AlertMessage";
const inputNames = {
    oldPassword: "oldPassword",
    newPassword: "newPassword",
    confirmPassword: "confirmPassword"
}

const statuses = {
    loading: "loading",
    suceess: "suceess",
    fail: "fail",
    idle: "idle"
}

export function ChangePassword({ onSuccess }) {
    const [changePasswordForm, setChangePasswordForm] = React.useState({});
    const [changePasswordFormValidation, setChangePasswordFormValidation] = React.useState({
        oldPassword: true,
        newPassword: true,
        confirmPassword: true,
    });
    const [showConfirmation, setShowConfirmation] = React.useState(statuses.idle);

    function isFieldValid(fieldName) {
        if (fieldName === inputNames.confirmPassword) {
            if (!changePasswordForm[inputNames.confirmPassword] ||
                changePasswordForm[inputNames.confirmPassword] !== changePasswordForm[inputNames.newPassword]) {
                return false;
            }
        } else {
            return !!changePasswordForm[fieldName]
        }

        return true
    }

    function setFieldValidation(fieldName) {
        setChangePasswordFormValidation(oldV => ({ ...oldV, [fieldName]: isFieldValid(fieldName) }));
    }

    function isFormValid() {
        const myVallidation = { ...changePasswordFormValidation };

        myVallidation.oldPassword = isFieldValid(inputNames.oldPassword);
        myVallidation.newPassword = isFieldValid(inputNames.newPassword);
        myVallidation.confirmPassword = isFieldValid(inputNames.confirmPassword);

        setChangePasswordFormValidation(myVallidation);

        return !Object.values(myVallidation).some((v) => v === false);
    }

    const handleValueChange = (newValue, name) => {
        setChangePasswordForm(oldV => ({ ...oldV, [name]: newValue }));
    }

    const updatePassword = async () => {
        let suceess = true;
        try {
            if (isFormValid()) {
                setShowConfirmation(statuses.loading);
                const result = await changePassword(changePasswordForm.oldPassword, changePasswordForm.newPassword);
                setShowConfirmation(statuses.suceess);
            }
        } catch (error) {
            console.error(error);
            setShowConfirmation(statuses.error);
            suceess = false;
        } finally {
            setTimeout(() => setShowConfirmation(statuses.idle), 3000);
            if (suceess) {
                onSuccess();
            }
        }
    }

    return (
        <div>
            <div className={`col-2  form-field text-input ${!changePasswordFormValidation[inputNames.oldPassword] ? 'error' : ''}`}>
                <label htmlFor="current-password">Old Password</label>
                <input
                    id='current-password'
                    type='password'
                    autoComplete='password'
                    value={changePasswordForm[inputNames.oldPassword]}
                    onBlur={() => setFieldValidation(inputNames.oldPassword)}
                    onChange={(e) => handleValueChange(e.target.value, inputNames.oldPassword)}
                />
                {!changePasswordFormValidation[inputNames.oldPassword] &&
                    <p className="validation-message">Enter password.</p>
                }
            </div>
            <div className={`col-2 form-field text-input ${!changePasswordFormValidation[inputNames.newPassword] ? 'error' : ''}`}>
                <label htmlFor="new-password">New password</label>
                <input
                    id='new-password'
                    type='password'
                    autoComplete='password'
                    value={changePasswordForm[inputNames.newPassword]}
                    onBlur={() => setFieldValidation(inputNames.newPassword)}
                    onChange={(e) => handleValueChange(e.target.value, inputNames.newPassword)}
                />
                {!changePasswordFormValidation[inputNames.newPassword] &&
                    <p className="validation-message">Enter new password.</p>
                }
            </div>
            <div className={`col-2 form-field text-input ${!changePasswordFormValidation[inputNames.confirmPassword] ? 'error' : ''}`}>
                <label htmlFor="confirm-password">Confirm password</label>
                <input
                    id='confirm-password'
                    type='password'
                    autoComplete='password'
                    value={changePasswordForm[inputNames.confirmPassword]}
                    onBlur={() => setFieldValidation(inputNames.confirmPassword)}
                    onChange={(e) => handleValueChange(e.target.value, inputNames.confirmPassword)}
                />
                {!changePasswordFormValidation[inputNames.confirmPassword] &&
                    <p className="validation-message">Enter matching password.</p>
                }

            </div>
            <div className="button-group">
                <button
                    aria-label='Change credentials'
                    className="button-l button-primary"
                    onClick={updatePassword}
                    type='button'
                    disabled={showConfirmation === statuses.loading}
                >
                    {showConfirmation === statuses.loading ?
                        <span className="loader loader--s"></span> : 'Submit'
                    }

                </button>
            </div>

            {showConfirmation !== statuses.idle && showConfirmation !== statuses.loading && (
                <div className="fz-block" style={{ marginTop: '10px' }}>
                    <AlertMessage
                        messageHeader={showConfirmation === statuses.suceess ? "Password updated successfully." : "Faile to Update Password"}
                        messageBody={""}
                        startDate={null}
                        setShowWarningContainerMessage={null}
                        messageType={showConfirmation === statuses.suceess ? "success" : "error"}
                        messageBodyContinuation={null}
                        showButton={false}
                    />
                </div>
            )}

        </div>);
}