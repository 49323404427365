import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../libs/Header';
export default function AdminToolsList() {
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const handleGoToLogin = () =>  navigate('/login');
    const isAdmin = sessionStorage.getItem('isAdmin');
    const navigate = useNavigate();
     useEffect(() => {
    if( isLoggedIn !==  'true'){
        handleGoToLogin();
    }
    }, []);

    return(
        <div className='row full'>
            <Header />
            <div className='row'>                 
                <div className="col ten">
                    <ul className='fz-breadcrumb'>
                        <li>
                            <a href='' onClick={(e) => {navigate(`/profeed`)}}>Home</a>
                            <span className='icon-right fz-divider'></span>
                        </li>
                        <li>
                           Tools
                        </li>
                    </ul>
                    <div className="fz-header-title fz-container block">
                        {
                            isAdmin === '1' ?
                            <span className='fz-card-group'>
                                <div>                    
                                    <button 
                                        type='button'
                                        id="createuser"
                                        aria-label='Create users'
                                        className='btn fz-btn'
                                        onClick={() => { navigate(`/create`)}}
                                    >  
                                        Create users
                                    </button>
                                </div>
                                <br />
                                <br />
                                <div> 
                                    <button 
                                        type='button'                        
                                        aria-label='Delete packages'
                                        value='Delete packages'
                                        className='btn fz-btn'
                                        onClick={() => {navigate(`/delete`) }}
                                    >
                                    Delete packages
                                    </button>
                                </div>
                                <br />
                                <br />
                                <div> 
                                    <button 
                                        type='button'                        
                                        aria-label='Delete video submission'
                                        value='Delete videos submission'
                                        className='btn fz-btn'
                                        onClick={() => { navigate('/deletesubmissions')}}
                                    >
                                    Delete Videos submission
                                    </button>
                                </div>
                                <br />
                                <br />
                                <div> 
                                    <button 
                                        type='button'                        
                                        aria-label='Rejected users'
                                        value='View rejected users'
                                        className='btn fz-btn'
                                        onClick={() => { navigate('/rejects')}}
                                    >
                                        Rejected Pro/Vendor accounts
                                    </button>
                                </div>
                            </span>
                            :
                            <div className="fz-alert alert-info">
                                <i className="icon-info"></i>
                                <strong>Info</strong>
                                <p>You are not authorize to view this page.</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}