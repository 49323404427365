import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import imageLogo from '../../images/fazzad-logo.svg';
import imageLogoWhite from '../../images/fazzad-logo-white.svg';
import imageUser from "../../images/icon-user-circle.svg";
import AppContext from '../../AppContext';
import { logOut } from "../../utils/authHelpers";

export default function Header({ mode = 'default' }) {
    const navigate = useNavigate();
    const [showFullHeader, setShowFullHeader] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [logoToDisplay, setLogoToDisplay] = useState(imageLogo);
    const [userType, setUserType] = useState();
    const userName = sessionStorage.getItem('userName');
    const userId = sessionStorage.getItem('userId');
    const accountTypes = [500001, 500002, 500004];

    useEffect(() => {
        if (mode !== 'default') {
            setLogoToDisplay(imageLogoWhite);
        }
        setUserType(parseInt(sessionStorage.getItem('userType')));
        checkUrl();
    }, [userType, showFullHeader]);

    const showHideMenu = () => {
        setShowOptions(!showOptions);
    }

    const checkUrl = () => {
        if (window.location.pathname === '/login') {
            setShowFullHeader(false);
        } else if (window.location.pathname === '/signup') {
            setShowFullHeader(false);
        } else if (window.location.pathname.substring(0, 15) === '/forgotpassword') {
            setShowFullHeader(false);
        } else {
            setShowFullHeader(true);
        }
    }
    const goToPage = (vl) => {
        switch (vl) {
            case 'logout':
                logOut();
                navigate(`/login`);
                break;
            case 'profile':
                navigate(`/profile/${userType}`);
                break;
            case 'contacts':
                navigate(`/contacts/${userId}/500001/0`);
                break;
            case 'mynetwork':
                navigate(`/mynetwork`);
                break;
            case 'uploads':
                navigate(`/uploads`);
                break;
            case 'tools':
                navigate(`/tools`);
                break;
            case 'courses':
                navigate(`/courses/${userId}`);
                break;
            case 'pros':
                navigate(`/profeed`);
                break;
            case 'surveyor':
                navigate(`/survey`);
                break;
            default:
                break;
        }
    }
    return (
        <>

            <header id="fazzad-header" className="app-header">
                <a href="/" className="logo" onClick={(e) => { e.preventDefault(); e.target.blur(); }}>
                    <img src={logoToDisplay} alt="Fazzad platform" />
                </a>
                {showFullHeader ?
                    accountTypes.includes(parseInt(userType)) ?
                        <nav id="app-navigation">
                            <ul>
                                <li>
                                    <div className="profile-menu" tabIndex="0" onClick={() => showHideMenu()}>
                                        <span className="first-name">{userName}</span>
                                        <span className="icon">
                                            <img src={imageUser} alt={`User ${userName}`} />
                                        </span>
                                        {showOptions && (
                                            <div className="profile-menu__menu">
                                                <ul>
                                                    <li><a href="/" onClick={(e) => { e.preventDefault(); goToPage('profile') }}>Profile</a></li>
                                                    {userType === 500001 && (
                                                        <>
                                                            <li><a href="/" onClick={(e) => { e.preventDefault(); goToPage('contacts') }}>Invite Surveyors</a></li>
                                                            <li><a href="/" onClick={(e) => { e.preventDefault(); goToPage('mynetwork') }}>My network</a></li>
                                                            <li><a href="/" onClick={(e) => { e.preventDefault(); goToPage('courses') }}>Courses</a></li>
                                                            <li><a href="/" onClick={(e) => { e.preventDefault(); goToPage('surveyor') }}>Feed</a></li>
                                                        </>
                                                    )}
                                                    {userType === 500002 && (
                                                        <li><a href="/" onClick={(e) => { e.preventDefault(); goToPage('pros') }}>Feed</a></li>
                                                    )}
                                                    <li><a href="/" onClick={(e) => { e.preventDefault(); goToPage('logout') }}>Log Out</a></li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        : ''
                    : ''
                }
            </header>
        </>
    )
};
