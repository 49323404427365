/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {formatDate} from '../../utils/dateFormat';
import {useNavigate} from "react-router-dom";
import {useParams} from 'react-router-dom';
import Axios from 'axios';
import Header from './Header'

export default function PdfGallery() {
    const navigate = useNavigate();
    const {packageName, leadtype } = useParams();
    const [packagePdfs, setPackagePdfs] = useState([]);
    const [packages, setPackages] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const s3BucketPrefix = process.env.REACT_APP_S3_BUCKET_PREFIX;
    const userId = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    
    const goToPackageDetails = (packageName) => {
        if(parseInt(userType) === 500001){
            navigate(`/survdetails/${packageName}`);
        } else if (parseInt(userType) === 500002){
            navigate(`/prodetails/${packageName}`);
        } else {
            navigate(`/showdetails/${packageName}/${leadtype}`);
        }
    }

    const goToFeed = () => {
        if(parseInt(userType) === 500001){
            navigate(`/survey`);
        } else if (parseInt(userType) === 500002){
            navigate(`/profeed`);
        } else {
            navigate(`/property/${packageName}`);
        }
    }

    const getPackage = async () => {
        try{
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackage`, {packageName: packageName}).then((response) => {
                if(response.data.length > 0){
                    setPackages(response.data);
                }
            setShowLoader(false);
            }).catch(function (error) {
                setShowLoader(false);
                console.log(error);
            })
        } catch (error) {
            setShowLoader(false);
        }
    }
   
    
    useEffect(() => {
        if(sessionStorage.getItem('isPropertyOwner') === 'true'){
            document.body.classList.add('dark-mode');
        }else{
            document.body.classList.remove('dark-mode');
        }
        getPackage(userId);
        getPdfs(packageName);
    }, []);

    const getFileInfo = (info, type) => {
        let fileInfo = '';
        const infoArray = info.split('/');
        if(type === 'name'){
            fileInfo = infoArray[2];
        }
        else {
            fileInfo = formatDate(info);
        }
        return fileInfo;
    }
    
    const getPdfs = (packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getpdfs`, {projectName:packageName}).then((response) => {
            if(response.data.length > 0){
                setPackagePdfs(response.data);
            } else {
                console.log('no pdfs found')
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    return(
        <div id="fazzad-site-container">
            <Header
                mode={sessionStorage.getItem('isPropertyOwner') === 'true' ? 'homeonwer' : 'default'} 
            />
            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            {parseInt(userType) !== 500003 && sessionStorage.getItem('isPropertyOwner') === 'false' ?
                                <>
                                <li>
                                    <a href='' onClick={(e) => {goToFeed()}}>Home</a>
                                    <span className="separator">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                </li>
                                <li>
                                    <a href='' onClick={(e) => { goToPackageDetails(packages[0].packageName); } }>Package Details</a>
                                    <span className="separator">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                </li>
                                <li aria-current="page">
                                    <span>PDF file</span>
                                </li>
                                </>
                            :
                                <>
                                    <li>
                                        <a href='' onClick={(e) => { navigate(`/showdetails/${packageName}/0`); } }>Project</a>
                                        <span className="separator">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li>
                                        <span>PDF file</span>
                                    </li>
                                </>
                            }
                        </ol>
                    </nav> 
                    <div id="package-title" className="nowrap">
                        <h1 className="h3">PDFs</h1>
                    </div>
                    {showLoader &&(<span className="loader loader--l"></span>)}
                    <div className="package-files-container">
                        <div className="package-files__list">
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>File Name</th>
                                        </tr>
                                    </thead>
                                        {packagePdfs.length > 0 ?
                                            <tbody>
                                                {packagePdfs.map((pdfs, index) => {
                                                    return(
                                                        <tr key={`tr_${index}`}>
                                                            <td key={`td_${index}`} className="fz-break-all">
                                                                <a href={`${s3BucketPrefix}/${packageName}/pdf-files/${getFileInfo(pdfs.Key, 'name')}`} target='_blank' rel="noopener noreferrer">
                                                                    {getFileInfo(pdfs.Key, 'name')}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        :''}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}