import React, { useEffect, useState, useRef } from 'react';
import * as formValidator from '../../utils/formValidation';
import AccountDetailsForm from '../libs/AccountDetailsForm';
import Header from '../libs/Header';
import Axios from 'axios';
import MailingAddressForm from '../libs/MailingAddressForm';
import getSvg from '../../utils/getSvg';
import PageTitle from '../libs/PageTitle';
import useGooglePlaceAutoComplete from "../../service/google_place_autocomplete";
import { loadGoogleMapsAPI } from '../../utils/loadGoogleMapsAPI';
import { set } from 'react-hook-form';

export default function FreeLead() {
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [hasAddress, setHasAddress] = useState(true);
    const [hasCity, setHasCity] = useState(true);
    const [hasConfirmPassword, setHasConfirmPassword] = useState(true);
    const [hasFirstName, setHasFirstName] = useState(true);
    const [hasLastName, setHasLastName] = useState(true);
    const [hasPassword, setHasPassword] = useState(true);
    const [hasState, setHasState] = useState(true);
    const [hasZip, setHasZip] = useState(true);
    const [hasEmail, setHasEmail] = useState(true);
    const [hasPhoneNumber, setHasPhoneNumber] = useState(true);
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [showDoneMessage, setShowDoneMessage] = useState(false);
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const addressRef = useRef();
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
    const userType = 500003;
    let autoComplete = "";

    useEffect(() => {    
        async function initGooglePlaces() {
            try {
             await loadGoogleMapsAPI('initGoogleMaps');
                autoComplete = await googleAutoCompleteSvc.initAutoComplete(addressRef.current, handleAddressSelect);
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        }
        
        initGooglePlaces();
        sessionStorage.setItem('isLoggedIn',  'false');
        sessionStorage.setItem('userType', 500003);
        document.body.classList.add('dark-mode');
        return () => {
            document.body.classList.remove('dark-mode');
        };
    }, []);

    const handleAddressSelect = async () => {
        let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
        addressRef.current.value = addressObj.address1;
        setAddress(addressObj.address1);
        setState(addressObj.adminArea1Short);
        setCity(addressObj.locality);
        setZip(addressObj.postalCode);
        setHasAddress(true);
        setHasCity(true);
        setHasState(true);
        setHasZip(true);
    };

    const saveVideoUploaded = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveFreeLeadVideos`, {
            email: email,
            address: addressRef.current.value,
            address2: address2,
            city: city,
            phone: phone,
            zip: zip,
            state: state,
            firstName: firstName,
            lastName: lastName,
            isJustLead: true,
        }).then(() => {
           setShowDoneMessage(true);
        }).catch(function (error) {
            console.log('error = ',error);
        })
    }


    const checkPhoneNumber = (phone) => {
        if(phone.trim().length > 0){
            if(!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)){
                setHasPhoneNumber(false);
            }else {
                setHasPhoneNumber(true);
            }
        }else {
            setHasPhoneNumber(false);
        }
    };

    const createLead = () => {
        checkEmail(email);
        checkFirstName(firstName);
        checkLastName(lastName);
        checkAddress(address);
        checkCity(city);
        checkPhoneNumber(phone);
        
        let isValid = true;
        if(checkForm()){
            if( 
                firstName.trim().length === 0 || lastName.trim().length === 0 || 
                city.trim().length === 0 || state.trim().length === 0 ||
                email.trim().length === 0 || city.trim().length === 0 || zip.trim().length < 5 || !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) || 
                !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            ){
                isValid = false;
            } else {
                saveVideoUploaded();
            }
        }
        if(typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean'){
            if(addressRef.current.value.trim().length === 0){
                isValid = false;
            }
            else{
                setHasAddress(true);
            }
        }
        return isValid;
    }

    const checkCity = (city) => {
        if(city.trim().length === 0){
            setHasCity(false);
        }else {
            setHasCity(true);
        }
    }

    const checkFirstName = (firstName) => {
        if(firstName.trim().length === 0){
            setHasFirstName(false);
        }else {
            setHasFirstName(true);
        }
    }

    const checkLastName = (lastName) => {
        if(lastName.trim().length === 0){
            setHasLastName(false);
        }else {
            setHasLastName(true);
        }
    }

     const checkEmail = (email) => {
        if(email.trim().length > 0){
            const matchEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if(Array.isArray(matchEmail) && matchEmail.length > 0){
                setHasEmail(true);
            } else {
                setHasEmail(false);
            }
        } else {
            setHasEmail(false);
        }
     }
     
    const checkAddress = (address) => {
        if(typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean' && address !== ''){
            if(address.trim().length === 0){
                setHasAddress(false);
            }
        }else {
            setHasAddress(true);
        }

        if(addressRef.current.value.trim().length > 0){
            setAddress(addressRef.current.value);
        }
    }
     
     const formatPhoneNumber = (phone) => {
        const cleaned = ('' + phone).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          setPhone(['(', match[2], ') ', match[3], '-', match[4]].join(''));
        }
     }
     
    const checkForm = () => {
        let isValid = true;
        if(email.trim().length === 0 || state.trim().length === 0 || phone.trim().length === 0 || firstName.trim().length === 0 || lastName.trim().length === 0){
            isValid = false;
        }
        if(typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean'){
            if(addressRef.current.value.trim().length === 0){
                isValid = false;
            }else {
                setHasAddress(true);
            }
        }
        return isValid;
    }
    
    return(
        <div id="fazzad-site-container">
        <Header
            mode={'dark'}
        />
        <main id="fazzad-main">
            <div id="app-container">
                <div className="form-container" id="proprofile">
                    {!showDoneMessage ?
                        <form action="">
                            <section className="form-details">
                                <PageTitle
                                    headerText={'Site Visit'}
                                    subHeaderText={'Fill out your information so a surveyor can reach out to you.'}
                                    showSubHeader={true}
                                />
                                
                                <AccountDetailsForm
                                    accountType={'propertyowner'}
                                    companyName={''}
                                    hasCompanyName={true}
                                    setCompanyName={null}
                                    setHasCompanyName={true}
                                    license={null}
                                    setLicense={null}
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    hasFirstName={hasFirstName}
                                    setHasFirstName={setHasFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    setHasLastName={setHasLastName}
                                    hasLastName={hasLastName}
                                    phone={phone}
                                    setPhone={setPhone}
                                    hasPhoneNumber={hasPhoneNumber}
                                    setHasPhoneNumber={setHasPhoneNumber}
                                    formatPhoneNumber={formatPhoneNumber}
                                    emailProfile={email}
                                    setEmailProfile={setEmail}
                                    hasEmailProfile={hasEmail}
                                    setHasEmailProfile={setHasEmail}
                                    password={password}
                                    setPassword={setPassword}
                                    hasPassword={hasPassword}
                                    setHasPassword={setHasPassword}
                                    confirmPassword={confirmPassword}
                                    setConfirmPassword={setConfirmPassword}
                                    hasConfirmPassword={hasConfirmPassword}
                                    setHasConfirmPassword={setHasConfirmPassword}
                                    isInvitation={false}
                                    formValidator={formValidator}
                                    userType={parseInt(userType)}
                                />
                            </section>
                            <section className="form-details">
                                <MailingAddressForm 
                                    setAddress={setAddress}
                                    addressRef={addressRef}
                                    hasAddress={hasAddress}
                                    setHasAddress={setAddress}
                                    setAddress2={setAddress2}
                                    address2={address2}
                                    city={city}
                                    setCity={setCity}
                                    hasCity={hasCity}
                                    setHasCity={setHasCity}
                                    state={state}
                                    setState={setState}
                                    hasState={hasState}
                                    setHasState={setHasState}
                                    zip={zip}
                                    setHasZip={setHasZip}
                                    hasZip={hasZip}
                                    setZip={setZip}
                                    formValidator={formValidator}
                                    showHeader={true}
                                    isNewPackage={true}
                                    addressHeader={"Project Address"}
                                />
                            </section>
                            <div className="button-group">
                                <button
                                    type="button"
                                    className="button-m button-primary"
                                    onClick={() => {createLead()}}
                                >
                                    <span className="text">Continue</span>
                                </button>
                            </div>
                        </form>
                    :
                    <div className="alert-container">
                        <div className={`alert alert--info`}>
                            <div className="alert__content">
                                <p>{getSvg('green-checkmark')}&nbsp; We received your request for a site visit. An interested Surveyor will reach out to you</p>
                            </div>        
                            
                        </div>
                        </div>
                    }
                </div>
            </div>                            
        </main>
        
    </div>
  )
}
