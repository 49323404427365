import axiosInstance from './axiosInstance';



/**
 * sends a POST request to a login endpoint with
 * the provided email and password, sets user information based on the response, and returns a promise
 * with the response data or an error.
 * @param email - The `email` parameter in the `logIn` function represents the email address of the
 * user trying to log in. It is used as part of the authentication process to verify the user's
 * identity.
 * @param password - The `password` parameter in the `logIn` function is the user's password that they
 * input when trying to log in. It is typically a string value that is used for authentication purposes
 * to verify the user's identity.
 * @returns The `logIn` function is returning a promise that resolves to the data from the successful
 * response if the login request is successful. If there is an error during the login request, the
 * function will return a promise that rejects with the error.
 */
export async function logIn(email, password) {
    const baseUrl = `${process.env.REACT_APP_LOCALHOST}/api/auth/login`;

    try {
        const response = await axiosInstance.post(baseUrl, { email, password });
        setUserInfo(response);
        return await Promise.resolve(response.data);
    } catch (error) {
        return await Promise.reject(error);
    }
}

/**
 * sends a POST request to a signup API endpoint with user information and
 * returns a Promise resolving to the response data or rejecting with an error.
 * @param userInfo - The `userInfo` parameter in the `signUp` function likely contains the information
 * needed for a user to sign up, such as their username, email, password, and any other relevant
 * details required for registration. This information is typically passed as an object with key-value
 * pairs, for example:
 * @returns The `signUp` function is returning a promise that resolves to the data from the response if
 * the request is successful. If there is an error during the request, it will return a rejected
 * promise with the error.
 */
export async function signUp(userInfo) {
    const baseUrl = `${process.env.REACT_APP_LOCALHOST}/api/auth/signup`;

    try {
        const response = await axiosInstance.post(baseUrl, userInfo);
        setUserInfo(response);
        return await Promise.resolve(response.data);
    } catch (error) {
        return await Promise.reject(error);
    }
}

function setUserInfo(response) {
    if (response?.data?.msg === 'email already exists') {
        return;
    }

    localStorage.setItem('userInfo', JSON.stringify(response.data));
    localStorage.setItem('accessToken', response.data.accessToken);
}

export function getUserInfo() {
    try {
        const userInfoString = localStorage.getItem('userInfo');
        if (userInfoString) {
            return JSON.parse(userInfoString);
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

export function getAccessToken() {
    return localStorage.getItem('accessToken') ?? null;
}

export function logOut() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem("userInfo");
}