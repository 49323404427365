import React, { useEffect, useState } from 'react';
import getSvg from '../../utils/getSvg';
import Axios from 'axios';
import Header from './Header';
import BreadCrumbs from '../libs/BreadCrumbs';
import checkUserProfile from '../../utils/checkUserProfile';
import { downloadCadTemplate } from '../../utils/downloadCadTemplate';
import PageTitle from './PageTitle';
import ModalInfo from '../libs/ModalInfo';
import checkForMobile from '../../utils/checkForDeviceType';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
export default function SurveyorCourses() {
    const { userId } = useParams();
    const navigate = useNavigate();
    const userType = 500001;
    const [isMobile, setIsMobile] = useState(false);
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showIntroModal, setShowIntroModal] = useState(false);
    const [courses, setCourses] = useState([]);
    const [singleCourse, setSingleCourse] = useState([]);
    const [showMore, setShowMore] = useState({});
    const [showMoreIntro, setShowMoreIntro] = useState(false);
    const userTypeToBeChecked = parseInt(process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED);
    const imagesUrlPrefix = process.env.REACT_APP_S3_BUCKET_COURSES_IMAGES_PREFIX;
    const videoUrlPrefix = process.env.REACT_APP_S3_BUCKET_COURSES_VIDEOS_PREFIX;
    const handleGoToLogin = () => navigate('/login');

    useEffect(() => {
        setIsMobile(checkForMobile());
        getCourses();
    }, []);

    const downloadCadTemplateFunc = async () => {
        setIsDownloading(true);
        try {
            await downloadCadTemplate('fazzad-cad-template');
            setIsDownloading(false);
        } catch (e) {
            setIsDownloading(false);
            console.error(`Download package error: ${e}`);
        }
    }

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const { isGoodToGo } = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (isGoodToGo) {
            getCourses();
        } else {
            setShowNotAllowed(true);
        }
    }

    const getCourses = async () => {
        try {
            axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/api/main/getCourses`, { videoType: 'become-a-surveyor' }).then((response) => {
                setCourses(response.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleReadMore = (index) => {
        setShowMore({ ...showMore, [index]: !showMore[index] });
    };

    const getCourseDescription = (courseDesc, index) => {
        if (!showMore[index]) {
            if (courseDesc.length > 150) {
                return courseDesc.substring(0, 150) + '...';
            } else {
                return courseDesc.substring(0, 150);
            }
        } else {
            return courseDesc;
        }
    }

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <BreadCrumbs
                        header1={"Home"}
                        isLink1Clickable={true}
                        link1={"survey"}
                        header2={"Courses"}
                        link2={""}
                        isLink2Clickable={false}
                        isLink2Navigation={false}
                        link3={""}
                        header3={""}
                        goToFeed={null}
                        userType={"surveyor"}
                    />

                    <div id='page-title--courses'>
                        <PageTitle
                            headerText={"Courses"}
                            subHeaderText={"Building an as-built survey business"}
                            showSubHeader={true}
                        />
                        <div className="button-group">
                            <button
                                type="button"
                                className="button-m button-primary"
                                onClick={() => downloadCadTemplateFunc()}
                            >
                                {getSvg('download')}
                                <span className="text">CAD template</span>
                            </button>
                        </div>
                    </div>
                    <div id="featured-course" className="fz-value fz-block">
                        <div className="wrapper-featured rounded">
                            <div className="col-featured-description">
                                <h5>Featured</h5>
                                <h2>A day in the life of a surveyor</h2>
                                <p>We reveal the step by step process of completing an as-built survey and getting paid for it.<br />
                                    {!showMoreIntro && (
                                        <a href="/"
                                            onClick={(e) => { e.preventDefault(); setShowMoreIntro(!showMoreIntro); e.target.blur(); }}
                                        >
                                            Read more
                                        </a>
                                    )}
                                </p>
                                {showMoreIntro && (
                                    <>
                                        <p>Starting with getting a lead. This may come from a cold call, an email, a webform on your website or a paid lead from a platform such as Home Advisor, Bark or Thumbtack. (Quick tip: Remodeling leads for Architects can be turned into as-built survey leads. Tell the client the first step is to do an as-built and after that is done you will connect them with several registered Architects to choose from.)
                                        </p>
                                        <p>Then we move to getting a deposit to commence work. This is done via Square, for the simple reason that getting paid with a credit card will make it very easy for the client to pay for the work.</p>
                                        <p>Now that you're hired, load up your tools and head to the jobsite. Once there, start taking photography of all spaces, areas, mechanical-electrical-plumbing devices and utilities. Photography may include Still, 360 and Drone. While these may seem redundant, it's this redundancy that will make sure every inch of the property is documented and works as a safeguard in case you miss something. Also, having a checklist of the things you need to document really helps. Going back to the property later will mean time wasted for you and your client, not to mention annoyance. </p>
                                        <p>Finally it's time to perform the actual survey. You must decide between Laser Scanning or Drafting on-site, depending on the situation you may go for one or the other. For quick notes of details and reminders, walk around and record a video dictating notes to yourself or your team.</p>
                                        <p>A perk of working on the field is you can find new places to eat every time you do a job. If you want to save money, head home and continue working.</p>
                                        <p>Download all information from your devices while the site is still fresh in your head and so that its easier for you to organize the photography and files.</p>
                                        <p>Finally, deliver the work and send out that final invoice. You might be tempted to hold on to the work until you get payment, but don't. You already did the work, so might as well deliver it. A second issue is if you don't deliver, your client might say that payment wasn't made because the work was never delivered. Once again, final invoice should be done via Square, in case there is an issue with money, they can always resolve it using a credit card...what matters is that you get paid.
                                            <br />
                                            <a href="/"
                                                onClick={(e) => { e.preventDefault(); setShowMoreIntro(!showMoreIntro); e.target.blur(); }}
                                            >
                                                Read less
                                            </a>
                                        </p>
                                    </>
                                )}

                            </div>
                            <div className="col-featured-pic">
                                <a
                                    className="featured-pic modal-trigger" data-modal="modal-7"
                                    onClick={(e) => { e.preventDefault(); setShowIntroModal(true) }}
                                >
                                    <svg className="icon-play-featured" width="24" height="24" viewBox="0 0 100 100" version="1.1" fill="currentColor"><path d="M8.333,32.5l0,35c0,8.021 0.256,12.003 1.817,15.066c-0,0.001 -0,0.001 -0,0.001c1.598,3.136 4.147,5.685 7.283,7.283c3.064,1.561 7.046,1.817 15.067,1.817l35,-0c8.021,-0 12.003,-0.256 15.066,-1.817c3.137,-1.598 5.686,-4.147 7.284,-7.283c1.561,-3.064 1.817,-7.046 1.817,-15.067l-0,-35c-0,-8.021 -0.256,-12.003 -1.817,-15.066c-1.598,-3.137 -4.147,-5.686 -7.283,-7.284c-0,-0 -0,-0 -0.001,-0c-3.063,-1.561 -7.045,-1.817 -15.066,-1.817l-35,0c-8.021,0 -12.003,0.256 -15.066,1.817c-3.137,1.598 -5.686,4.147 -7.284,7.283c-1.561,3.064 -1.817,7.046 -1.817,15.067Zm8.334,0c-0,-5.983 -0.256,-8.998 0.908,-11.283c-0,-0 -0,-0 -0,-0c0.799,-1.568 2.074,-2.843 3.642,-3.642c-0,-0 -0,-0 -0,-0c2.285,-1.164 5.3,-0.908 11.283,-0.908c-0,-0 35,-0 35,-0c5.983,-0 8.998,-0.256 11.283,0.908c1.568,0.799 2.843,2.074 3.642,3.642c-0,-0 -0,-0 0,-0c1.164,2.285 0.908,5.3 0.908,11.283c0,-0 0,35 0,35c0,5.983 0.256,8.998 -0.908,11.283c-0,0 -0,0 -0,0.001c-0.799,1.567 -2.074,2.842 -3.641,3.641c-0.001,-0 -0.001,-0 -0.001,-0c-2.285,1.164 -5.3,0.908 -11.283,0.908c0,0 -35,0 -35,0c-5.983,0 -8.998,0.256 -11.283,-0.908c-0,-0 -0,-0 -0,-0c-1.568,-0.799 -2.843,-2.074 -3.642,-3.641c-1.164,-2.286 -0.908,-5.301 -0.908,-11.284c-0,0 -0,-35 -0,-35Zm18.75,4.856l-0,25.289c-0,3.44 0.528,5.075 1.247,6.035c1.086,1.451 2.749,2.359 4.557,2.488c1.197,0.086 2.858,-0.355 5.751,-2.215l19.67,-12.644c2.577,-1.657 3.567,-2.823 4.013,-3.877c0.657,-1.555 0.657,-3.309 -0,-4.864c-0.446,-1.054 -1.436,-2.22 -4.014,-3.877l-19.669,-12.644c-2.894,-1.861 -4.554,-2.301 -5.75,-2.215c-0.001,-0 -0.001,-0 -0.001,-0c-1.808,0.129 -3.471,1.037 -4.558,2.488c-0.719,0.961 -1.246,2.596 -1.246,6.036Z" /></svg>
                                </a>
                                <img src="https://fazzad-images.s3.us-west-1.amazonaws.com/courses/photo-day-in-the-life-of-surveyor.png" alt="Video clip" />
                            </div>
                        </div>
                    </div>
                    {/* <DownloadingModal
                        /> */}
                    {courses.map((course, index) => {
                        return (
                            <div className="course-list" key={`course_list_${index}`}>
                                <div className="course-item rounded" key={`course_item_${index}`}>
                                    <div className="image" key={`course_image_${index}`}>
                                        <a href=""
                                            className="modal-trigger"
                                            data-modal="modal-7"
                                            key={`course_href_${index}`}
                                        >
                                            <img
                                                src={`${imagesUrlPrefix}/${course.video_poster_name}`}
                                                alt={course.course_name}
                                                key={`course_poster_${index}`}
                                                onClick={(e) => { e.preventDefault(); setSingleCourse(course); setShowVideoModal(true); e.target.blur(); }}
                                            />
                                        </a>
                                    </div>
                                    <div className="content" key={`content_${index}`}>
                                        <div className="inner" key={`inner_${index}`}>
                                            <h3 className="h4" key={`course_h4_${index}`}>
                                                <a href="/"
                                                    className="modal-trigger"
                                                    data-modal="modal-7"
                                                    key={`course_href_trigger_${index}`}
                                                    onClick={(e) => { e.preventDefault(); setSingleCourse(course); setShowVideoModal(true); e.target.blur(); }}
                                                >
                                                    {course.course_name}
                                                </a>
                                            </h3>
                                            <article
                                                dangerouslySetInnerHTML={{ __html: getCourseDescription(course.course_description, course.id) }}
                                                key={`description_${index}`}>
                                            </article>
                                            {course.course_description.length > 180 && (
                                                <>
                                                    <a href="/"
                                                        onClick={(e) => { e.preventDefault(); handleReadMore(course.id); e.target.blur(); }}
                                                        key={`course_names_${index}`}
                                                    >
                                                        {showMore[course.id] ? 'Read less' : 'Read more'}
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                        <div className="fz-block-small">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {showNotAllowed && (
                        <div className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h4 warning">You are not allowed to see this page.</h2>
                                    <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => navigate('/')}>
                                        {getSvg('close')}
                                    </button>
                                </div>
                                <div className="modal__content">
                                    <p>You are not allowed to view this page or this package.</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {showVideoModal && (
                        <div id="modal-7" className="modal-container">
                            <div className="modal--video">
                                <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={(e) => setShowVideoModal(false)}>
                                    {getSvg('close')}
                                </button>
                                <video
                                    controls
                                    width='100%'
                                    height='auto'
                                    poster={`${imagesUrlPrefix}/${singleCourse.video_poster_name}`}
                                    autoPlay
                                >
                                    <source
                                        src={`${videoUrlPrefix}/${singleCourse.video_url}`}
                                        type='video/mp4'

                                    />
                                    <p>Your browser does not support the video element.</p>
                                </video>
                            </div>
                        </div>
                    )}
                    {showIntroModal && (
                        <div id="modalFeature" className="modal-container">
                            <div className="modal--video">
                                <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={(e) => setShowIntroModal(false)}>
                                    {getSvg('close')}
                                </button>
                                <video
                                    controls
                                    width='100%'
                                    height='auto'
                                    poster={`${imagesUrlPrefix}/${singleCourse.video_poster_name}`}
                                    autoPlay
                                >
                                    <source
                                        src={`https://fazzad-courses.s3.us-west-1.amazonaws.com/become-surveyor/DayInTheLife.mp4`}
                                        type='video/mp4'
                                    />
                                    <p>Your browser does not support the video element.</p>
                                </video>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            {isDownloading && (
                <ModalInfo
                    messageHeader={"Downloading CAD template..."}
                    messageBody={`Files will be saved on your ${isMobile ? 'device' : 'computer'}.`}
                    messageType={"informational"}
                    showCloseButton={false}
                    showLoader={true}
                />
            )}
        </div>
    )
}