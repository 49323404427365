import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../libs/Header'
import Axios from 'axios';
import PageTitle from "../libs/PageTitle";
export default function VerifyAccount() {
    const [showVerify, setShowVerify] = useState(true);
    const [isGoodLead, setIsGoodLead] = useState(true);
    const {referenceNumber} = useParams();
    const navigate = useNavigate();
   
    useEffect(() => {
        verifyAccount(referenceNumber);
    }, []);

    const verifyAccount = (packageName)  => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/verifyAccount`, {packageName: packageName}).then((response) => {
            setTimeout(() => {
                setShowVerify(false);
                if(response.data.length > 0){
                    navigate(`/leads/${response.data[0].id}/1?lt=po`);
                } else {
                    setIsGoodLead(false);
                }
            }, 2000);
        }).catch(function (error) {
            console.log('tags error = ',error);
        })
    }

    return(
    <div>
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <PageTitle
                        headerText={'Account verification'} 
                    />
                    {showVerify && (
                     <div className='fz-block-sm'>
                        <p className="message fz-align-center">We are verifying your account...&nbsp;&nbsp;<span className="loader loader--s"></span></p>
                    </div>
                    )}
                    {!isGoodLead ?
                        <div>
                        <div className='row' style={{marginTop: "40px"}}>     
                            <p>Sorry your account couldn't be verified</p>
                        </div>
                        <div className='row' > 
                            <p>Your lead won't be posted/available</p>
                        </div>
                        </div>
                    : ''
                    }
                </div>
            </main>
        </div>
    </div>
  )
}
