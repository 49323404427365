// StarRating.js
import React from 'react';

const StarRating = ({ totalStars = 5, rating, setRating }) => {
    const handleStarClick = (ratingValue) => {
        setRating(rating === ratingValue ? 0 : ratingValue);
    };

    return (
        <div className="star-rating">
            {[...Array(totalStars)].map((_, index) => {
                const ratingValue = index + 1;
                return (
                    <label key={index}>
                        <input
                            type="radio"
                            name="rating"
                            value={ratingValue}
                            onClick={() => handleStarClick(ratingValue)}
                            className="star-rating-input"
                        />
                        <span className={ratingValue <= rating ? "star-rating-star filled" : "star-rating-star"}
                        >★</span>
                    </label>
                );
            })}
            <p>Rating: {rating} / {totalStars}</p>
        </div>
    );
};

export default StarRating;
