export const ViewType = {
    scopeOfWork: "scope-of-work",
    scopeOfWorkThumbnail: "scope-of-work-thumbnail",
    pdfFiles: "pdf-files",
    dwgFiles: "dwg-files",
    coverImage: "cover-image",
    stillPictures: "still-pictures",
    threeSixtyPictures: "three-sixty-pictures",
    dronePictures: "drone-pictures",
    cadfile: 'cad-file',
    planPicture: 'plan-picture'
}

export function getBucketNameByViewType(thePictureType) {
    switch (thePictureType) {
        case ViewType.scopeOfWork:
            return process.env.REACT_APP_PROJECT_VIDEOS_BUCKET_NAME;
        case ViewType.scopeOfWorkThumbnail:
            return process.env.REACT_APP_PROJECT_VIDEO_THUMBNAIL;
        case ViewType.coverImage:
            return process.env.REACT_APP_S3_BUCKET;
        case ViewType.pdfFiles:
        case ViewType.stillPictures:
        case ViewType.dronePictures:
        case ViewType.threeSixtyPictures:
            return "fazzad-projects-compressed";
        default:
            return "";
    }
}

export function getFileExt(fileName) {
    if (!fileName) {
        throw new Error("Can not have empty filename");
    }

    const fileParts = fileName.split(".");

    return Array.isArray(fileParts) ? fileParts.pop() : "";
}

/*
REACT_APP_SCOPE_OF_WORK = scope - of - work - videos
REACT_APP_ADDITIONAL_VIDEOS = additional - videos
REACT_APP_STILL_PICTURES = still - pictures
REACT_APP_THREE_SIXTY = three - sixty - pictures
REACT_APP_DRONE = drone - pictures
REACT_APP_PDF = pdf - documents
REACT_APP_DWG = dwg - files
REACT_APP_POINT_CLOUD = point - cloud - pictures*/
