import React, {useRef, useState} from 'react';
import styles from './editable-heading.module.scss';

const EditableHeading = ({planName, setPlanName, viewType}) => {
  const headingRef = useRef();
  const [previousName, setPreviousName] = useState(planName);

  const handleTitleChange = (event) => {
    const newTitle = event.target?.textContent?.trim();
    if (newTitle) {
      setPlanName(newTitle);
      setPreviousName(newTitle);
    } else {
      // Reset to previous value if empty
      if (headingRef.current) {
        headingRef.current.textContent = previousName;
      }
    }
  };

  const selectElementContents = (element) => {
    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleIconClick = () => {
    if (headingRef.current) {
      headingRef.current.focus();
      selectElementContents(headingRef.current);
    }
  };

  return (
      <div className={styles['editable-heading']}>
        <h1
            ref={headingRef}
            contentEditable={viewType === 'edit'}
            onFocus={() => {
              selectElementContents(headingRef.current);
            }}
            onBlur={handleTitleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                handleTitleChange(e);
                headingRef.current?.blur();
              }
            }}
            className={"h3"}
            suppressContentEditableWarning={true}
        >
          {planName}
          {viewType === 'edit' && (
              <svg
                  onClick={handleIconClick}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className={styles['editable-heading-icon']}
              >
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </svg>
          )}
        </h1>
        {viewType === 'edit' && <p>Click on image to begin tagging and marking with either still or 360 pictures.</p>}
      </div>
  );
};

export default EditableHeading;