const cleanMediaUrl = (urlString) => {
    const searchTerm = '.';
    const lastIndexOfSearchTerm = urlString.lastIndexOf(searchTerm);
    const stringLenght = urlString.length;
    const newUrl = urlString.substr(0, stringLenght - (stringLenght - lastIndexOfSearchTerm))
    const cleanUrlWithSpace = newUrl.replace(/[^a-zA-Z0-9 ]/g, "");
    const cleanUrl = cleanUrlWithSpace.replace(/\s/g, '');
    const fileExtension = urlString.substr(urlString.length - (urlString.length - urlString.lastIndexOf(searchTerm)))
    return `${cleanUrl}${fileExtension}`;
}

export { cleanMediaUrl };