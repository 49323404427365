import React, { useState, useContext } from 'react';
import getSvg from '../../utils/getSvg';
import AWS from 'aws-sdk';
import Header from './Header';
import AppContext from '../../AppContext';

const initialFolderStructure = [];
const numberOfSubFolders = [];
const addSubfolderToStructure = (structure, parentId, newSubfolder) => {
  return structure.map(folder => {
    if (folder.id === parentId) {
      return { ...folder, subfolders: [...folder.subfolders, newSubfolder] };
    } else if (folder.subfolders.length > 0) {
      return { ...folder, subfolders: addSubfolderToStructure(folder.subfolders, parentId, newSubfolder) };
    }
    return folder;
  });
};

const deleteFolderFromStructure = (structure, folderId) => {
  return structure.map(folder => {
    if (folder.id === folderId) {
      
      numberOfSubFolders.splice(0,1);
      return null;
    } else if (folder.subfolders && folder.subfolders.length > 0) {
      return { ...folder, subfolders: deleteFolderFromStructure(folder.subfolders, folderId) };
    }
    return folder;
  }).filter(Boolean);
};

const renameFolderInStructure = (structure, folderId, newName) => {
  return structure.map(folder => {
    if (folder.id === folderId) {
      return { ...folder, name: newName };
    } else if (folder.subfolders && folder.subfolders.length > 0) {

      return { ...folder, subfolders: renameFolderInStructure(folder.subfolders, folderId, newName) };
    }
    return folder;
  });
};

const Folder = ({ folder, level = 1, isLastInParent = false, onAddSubfolder, onDeleteFolder, onRenameFolder, onSelectFiles }) => {
  const [isVisible, setIsVisible] = useState(true);
  const hasSubfolders = folder.subfolders && folder.subfolders.length > 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(folder.selectedFiles || []);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameFolderId, setRenameFolderId] = useState('');
  const [renameFolderName, setRenameFolderName] = useState('');

  const handleAddSubfolderClick = () => {
    const newSubfolder = { id: Date.now(), name: newFolderName, subfolders: [], selectedFiles: [] }; // Initialize selectedFiles as an empty array
    onAddSubfolder(folder.id, newSubfolder);
    numberOfSubFolders.push(newSubfolder);
    setIsModalOpen(false);
    setNewFolderName('');
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
    onSelectFiles(folder.id, files); // Pass selected files to the parent component
    setSelectedFiles(files); // Update selected files state for the current folder
  };

  const handleDeleteFolder = () => {
    onDeleteFolder(folder.id);
  };

  const handleRenameFolderClick = (id, name) => {
    setRenameFolderId(id);
    setRenameFolderName(name);
    setIsRenameModalOpen(true);
  };

  const handleRenameFolderConfirm = () => {
    onRenameFolder(renameFolderId, renameFolderName);
    setIsRenameModalOpen(false);
  };

  return (
    <div className={`l${level > 3 ? 3 : level} ${hasSubfolders && !isLastInParent ? '' : 'no-backbone'} ${isLastInParent ? 'last' : ''}`}>
      {hasSubfolders && !isLastInParent && <div className="backbones"></div>}
      {level > 1 && <div className="curve"></div>}
      <section className="sub-folder">
        <div className="folder-title rounded">
          <div className="container-title">
            {hasSubfolders ?
              <button type="button" className="button-s button-icon" onClick={() => setIsVisible(!isVisible)}>
                  <span className="icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                  </span>
              </button>
            : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
            <h3 className="h5">{folder.name}</h3>
          </div>
            <div className="button-group">
             
              <button title="New folder" type="button" className="button-s button-icon" onClick={() => {
               
                setIsModalOpen(true)}}>
                <span className="icon">
                  {getSvg('folder')}
                </span>
              </button>
             
            <button title="Delete folder" type="button" className="button-s button-icon" onClick={handleDeleteFolder}>
              <span className="icon">
                {getSvg('delete')}
              </span>
            </button>
            <button title="Rename folder" type="button" className="button-s button-icon" onClick={() => handleRenameFolderClick(folder.id, folder.name)}>
              <span className="icon">
                {getSvg('edit')}
              </span>
            </button>
          </div>
        </div>
        <div className="file-upload__container">
          {hasSubfolders && (
            <div className="vertical-track">
              <div className="line"></div>
            </div>
          )}
          <div className="content">
            <div className="form-field file-input">
              <input type="file" id={`fileupload-${folder.id}`} onChange={handleFileSelect} className="file-input__input" multiple />
              <label htmlFor={`fileupload-${folder.id}`} className="button button-s button-tertiary">
                <span className="icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </span>
                <span className="text">Choose pictures…</span>
              </label>
              {selectedFiles.map((file, index) => (
                <p key={index}>{file.name}</p>
              ))}
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div id="modal-5" className="modal-container">
            <div className="modal">
              <div className="modal__content">
                <div className="form-field text-input">
                  <label htmlFor="new-folder-name">New Folder Name</label>
                  <input id="new-folder-name" type="text" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} placeholder="Folder name" />
                </div>
              </div>
              <div className="modal__action">
                <div className="button-group">
                  <button className="button button-m button-secondary" type="button" onClick={handleAddSubfolderClick}>Create</button>
                  <button className="button button-m button-primary" type="button" onClick={() => setIsModalOpen(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isRenameModalOpen && (
          <div id="modal-5" className="modal-container">
            <div className="modal">
              <div className="modal__content">
                <div className="form-field text-input">
                  <label htmlFor="rename">Rename folder</label>
                  <input id="rename" type="text" value={renameFolderName} onChange={(e) => setRenameFolderName(e.target.value)} placeholder="New folder name" />
                </div>
              </div>
              <div className="modal__action">
                <div className="button-group">
                  <button className="button button-m button-secondary" type="button" onClick={handleRenameFolderConfirm}>Rename</button>
                  <button className="button button-m button-primary" type="button" onClick={() => setIsRenameModalOpen(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isVisible && folder.subfolders.map((subfolder, index) => (
          <Folder
            folder={subfolder}
            level={level + 1}
            key={subfolder.id}
            isLastInParent={isLastInParent && index === folder.subfolders.length - 1}
            onAddSubfolder={onAddSubfolder}
            onDeleteFolder={onDeleteFolder}
            onRenameFolder={onRenameFolder}
            onSelectFiles={onSelectFiles}
          />
        ))}
      </section>
    </div>
  );
};


const FolderApp = () => {
  const [folderStructure, setFolderStructure] = useState(initialFolderStructure);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const { myFolders, setMyFolders } = useContext(AppContext);

  const handleAddSubfolder = (parentId, newSubfolder) => {
    const updatedStructure = addSubfolderToStructure(folderStructure, parentId, newSubfolder);
    setFolderStructure(updatedStructure);
  };

  const handleDeleteFolder = (folderId) => {
    const updatedStructure = deleteFolderFromStructure(folderStructure, folderId);
    setFolderStructure(updatedStructure);
  };

  const handleRenameFolder = (folderId, newName) => {
    const updatedStructure = renameFolderInStructure(folderStructure, folderId, newName);
    setFolderStructure(updatedStructure);
  };

  const handleCreateFolder = () => {
    setIsModalOpen(true);
  };

  const handleAddFolderConfirm = () => {
    const newFolder = { id: Date.now(), name: newFolderName, subfolders: [], selectedFiles: [] };
    const updatedStructure = [...folderStructure, newFolder];
    setMyFolders(updatedStructure);
    setFolderStructure(updatedStructure);
    setIsModalOpen(false);
    setNewFolderName('');
  };

  const handleSelectFiles = (folderId, files) => {
    const updateSelectedFilesInStructure = (structure, folderId, files) => {
      return structure.map(folder => {
        if (folder.id === folderId) {
          return { ...folder, selectedFiles: files };
        } else if (folder.subfolders) {
          return {
            ...folder,
            subfolders: updateSelectedFilesInStructure(folder.subfolders, folderId, files)
          };
        }
        return folder;
      });
    };

    const updatedStructure = updateSelectedFilesInStructure(folderStructure, folderId, files);
    setFolderStructure(updatedStructure);
  };

  const uploadAllFilesToS3 = () => {
    const s3 = new AWS.S3();
  console.log(folderStructure)
    s3.config.update({
      accessKeyId: 'AKIA5MPPECD5TXNVGR4A', // Use environment variables
      secretAccessKey: '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', // Use environment variables
      region: 'us-west-1',
    });
   
    // Function to upload files recursively
    const uploadFilesRecursively = (files, folderPath) => {
      files.forEach(file => {
        const params = {
          Bucket: 'fazzad-still-pictures',
          Key: `${folderPath}/${file.name}`,
          Body: file
        };

        s3.upload(params, (err, data) => {
          if (err) {
            console.error(err);
          } else {
            console.log(`File uploaded successfully. File location: ${data.Location}`);
          }
        });
      });
    };

    // Function to recursively upload files in folders
    // Function to recursively upload files in folders
    const uploadFolderRecursively = (folder, parentFolderPath = '') => {
      const folderPath = parentFolderPath ? `${parentFolderPath}/${folder.name}` : folder.name;

      // Upload files in the current folder
      if (folder.selectedFiles && folder.selectedFiles.length > 0) {
        uploadFilesRecursively(folder.selectedFiles, folderPath);
      }

      // Recursively upload files from subfolders
      folder.subfolders.forEach(subfolder => {
        uploadFolderRecursively(subfolder, folderPath);
      });
    };


    // Iterate through the folder structure and upload files
    folderStructure.forEach(folder => {
      uploadFolderRecursively(folder);
    });
  };

  return (
    <div>
      <Header />
      <main id="fazzad-main">
        <div id="app-container">
          <div id="create-folders">
            <h1 className="h3">Create As-built Package</h1>
            <div>
              <button onClick={handleCreateFolder}>Create New Folder</button>
              <button onClick={uploadAllFilesToS3}>Upload All to AWS S3</button>
            </div>
            <div id="folder-wrapper" className="outlined">
              <div className="structure">
                {folderStructure.map((folder, index) => (
                  <Folder
                    folder={folder}
                    key={folder.id}
                    isLastInParent={index === folderStructure.length - 1}
                    onAddSubfolder={handleAddSubfolder}
                    onDeleteFolder={handleDeleteFolder}
                    onRenameFolder={handleRenameFolder}
                    onSelectFiles={handleSelectFiles}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      {isModalOpen && (
        <div id="modal-5" className="modal-container">
          <div className="modal">
            <div className="modal__content">
              <div className="form-field text-input">
                <label htmlFor="new-folder-name">New Folder Name</label>
                <input id="new-folder-name" type="text" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} placeholder="Folder name" />
              </div>
            </div>
            <div className="modal__action">
              <div className="button-group">
                <button className="button button-m button-secondary" type="button" onClick={handleAddFolderConfirm}>Create</button>
                <button className="button button-m button-primary" type="button" onClick={() => setIsModalOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FolderApp;