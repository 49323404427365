import { Navigate } from 'react-router-dom';
import { isExpired, decodeToken } from "react-jwt";
import { getUserInfo, getAccessToken } from "../../utils/authHelpers";



export const PrivateRoute = ({ children, to = "/login", userType = null }) => {
    const accessToken = getAccessToken();
    const userInfo = getUserInfo();

    //parseInt(process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED)
    if (accessToken && userInfo) {
        const isMyTokenExpired = isExpired(accessToken);
        if (!isMyTokenExpired) {
            if (!userType) {
                return children;
            } else if (parseInt(userType) === userInfo.userType) {
                return children;
            }
        }
    }

    return <Navigate to={to} replace />;
};