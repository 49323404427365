export const loadGoogleMapsAPI = (callbackName) => {
    return new Promise((resolve, reject) => {
      // Check if the Google Maps API script is already loaded
      if (window.google && window.google.maps) {
        resolve(window.google.maps);
        return;
      }
  
      // Create a global callback for when the script is loaded
      window[callbackName] = () => {
        resolve(window.google.maps);
      };
  
      // Create a script element to include the Google Maps API
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&libraries=places&callback=${callbackName}`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
  
      script.onerror = (error) => {
        reject(error);
      };
    });
  };