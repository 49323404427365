import React, {useEffect, useState, useRef} from 'react';
import {cleanMediaUrl} from '../../utils/clearMediaUrl';
import {S3} from '@aws-sdk/client-s3';
import {useNavigate} from 'react-router-dom';
import {Upload} from '@aws-sdk/lib-storage';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header'
import Tabs from '../libs/Tabs';
import PageTitle from "../libs/PageTitle";
import uuid from 'react-uuid';
import {uploadWithSignedUrl} from '../../utils/awsHelpers';

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function ProTestimonials() {
    const videoRef = useRef(null);
    const proTestimonialProfileBucketName = 'fazzad-pros-portfolio-testimonial-videos-source';
    const [fileToBeUploaded, setFileToBeUploaded] = useState('');
    const [fileProgressSelfVideo, setFileProgressSelfVideo] = useState(5);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
    const [testTimonialName, setTestimonialName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [showTestimonials, setShowTestimonials] = useState(true);
    const [singleVideo, setSingleVideo] = useState([]);
    const [showProcessingVideo, setShowProcessingVideo] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showNoTestimonials, setShowNoTestimonials] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [showFormErrorMessage, setShowFormErrorMessage] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [videosResponses, setVideosResponses] = useState([]);
    const [hasTestimonialName, setHasTestimonialName] = useState(true);
    const [hasProjectDescription, setHasProjectDescription] = useState(true);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    const handleGoToLogin = () => navigate('/login');
    const navigate = useNavigate();
    const uploadType = 'testimonial';
    let tempVideoCounter = 0;
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_BUCKET_PROS_VIDEOS_PREFIX;
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const userId = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');

    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            getProsVideos(userId, 'testimonial');
        }
    }, []);

    const checkProjectDescription = (projectDescription) => {
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
        } else {
            setHasProjectDescription(true);
        }
    }

    const checkTestmimonialName = (testTimonialName) => {
        if (testTimonialName.trim().length === 0) {
            setHasTestimonialName(false);
        } else {
            setHasTestimonialName(true);
        }
    }

    const getProsVideos = (userId, videoType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, {
            userId: userId,
            videoType: videoType
        }).then((response) => {
            setVideosResponses(filterData('testimonial', response.data));
            if (response.data.length === 0) {
                setShowNoTestimonials(true);
            } else {
                setShowNoTestimonials(false);
            }
        }).catch(function (error) {
            console.log('error getting pros videos = ', error);
        })
    }
    const filterData = (videoType, originalData) => {
        let filteredData = [];
        if (videoType === 'portfolio') {
            filteredData = originalData.filter((item) => item.videoType === 'portfolio');
        } else {
            filteredData = originalData.filter((item) => item.videoType === 'testimonial');
        }
        return filteredData;
    }
    const sendUploadError = (err) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendGenericErrorEmail`, {
            errorSubject: 'Error Uploading Testimonial video',
            errorMessage: 'Check the cors issues'
        }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('error sending generic error email = ', error);
        })
    }

    const checkUploadForm = () => {
        let isValid = true;
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }
        if (testTimonialName.trim().length === 0) {
            setHasTestimonialName(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }
        return isValid;
    }

    const saveVideoUploaded = (userId, uploadType, uploadFileName, testTimonialName, projectDescription, referenceNumber) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveProVideos`, {
            userId: userId,
            videoType: uploadType,
            uploadFileName: uploadFileName,
            projectName: testTimonialName,
            projectDescription: projectDescription,
            referenceNumber: referenceNumber
        }).then((response) => {
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const changeHandlerUploads = (event) => {
        event.preventDefault();
        setShowErrorMessage(false);
        const chosenFile = event.target.files[0].name;
        setFileToBeUploaded(chosenFile);
        const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;

        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidVideo(false);
        } else {
            setIsValidVideo(true);
            setSelectedUploads(event.target.files);
        }
        videoRef.current.blur();
    }

    const handleUpload = async () => {
        setShowFormErrorMessage(false);
        const uuids = uuid();
        tempVideoCounter = 0;
        let selfVideoArray = [];
        try {
            if (testTimonialName.trim().length > 0 && projectDescription.trim().length > 0) {
                if (selectedUploads.length > 0) {
                    if (checkUploadForm()) {
                        setFileProgressSelfVideo(0);
                        setShowErrorMessage(false);
                        setShowProcessingVideo(true);
                        setShowProcessing(false);
                        setErrorUploadingVideo(false);
                        selfVideoArray = [...selectedUploads];
                        let videoArray = [];
                        videoArray = selfVideoArray.splice(0, 1);
                        try {
                            await Promise.all(
                                videoArray.map(async (file) => {
                                    const fileName = cleanMediaUrl(file.name);
                                    const key = `${uuids}/${fileName}`;

                                    await uploadWithSignedUrl(file, proTestimonialProfileBucketName, key, (myProgress) => {
                                        setFileProgressSelfVideo(myProgress);
                                    });

                                    setShowProcessingVideo(false);
                                    setShowInfo(false);
                                    setShowProcessing(true);
                                    setFileProgressSelfVideo(0);
                                    saveVideoUploaded(userId, uploadType, fileName, testTimonialName, projectDescription, uuids);
                                    setTimeout(() => {
                                        setShowInfo(true);
                                        setShowProcessing(false);
                                        setShowTestimonials(true)
                                        setShowUploadVideo(false);
                                        getProsVideos(userId, 'testimonial');
                                    }, process.env.REACT_APP_TIEMOUT);
                                })
                            );
                        } catch (e) {
                            setShowProcessingVideo(false);
                            setTestimonialName('');
                            setProjectDescription('');
                            setFileToBeUploaded('');
                            setSelectedUploads([]);
                            setErrorUploadingVideo(true);
                            sendUploadError(e);
                        }
                    }
                } else {
                    setShowErrorMessage(true);
                }
            } else {
                setShowFormErrorMessage(true);
            }
        } catch (e) {
            setShowInfo(true);
            setShowUploadVideo(false);
            setShowProcessing(false);
            console.error(`Uploading Videos file error: ${e}`);
        }
    };

    const clearEmojis = (string, type) => {
        switch (type) {
            case 'projectDescription':
                setProjectDescription(string.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''));
                break;
            case 'testTimonialName':
                setTestimonialName(string.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''));
                break;
        }
    }

    const deleteTestimonial = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteProUploadsVideos`, {singleVideo: singleVideo}).then((response) => {
            setShowDeleteModal(false);
            getProsVideos(userId);
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    return (
        <div id="fazzad-site-container">
            <Header/>
            <main id="fazzad-main">
                <div id="app-container">
                    {showTestimonials ?
                        <>
                            <Tabs
                                userType={userType}
                                setShowProReq={null}
                                currentTab={'testimonial'}
                            />
                            <PageTitle
                                headerText={"Testimonial"}
                            />
                            <button type="button" className="fz-block-sm button-m button-primary" onClick={(e) => {
                                setShowUploadVideo(true)
                            }}>
                                {getSvg('upload')}
                                <span className="text">Upload testimonial video</span>
                            </button>
                            <p>Upload your testimonial videos here.</p>
                            <div id="po-container">
                                <div id="po-feed-container" className="reel-view">
                                    {videosResponses.map((pck, index) => {
                                        return (
                                            <div className="po-feed-item" key={`resp_${pck.id}`}>
                                                <div className="video rounded" key={`video_${pck.id}`}>
                                                    <video
                                                        className='rounded'
                                                        src={`${s3BucketVideoPrefix}/${pck.referenceNumber}/${pck.videoFileName}`}
                                                        poster={`${s3ThumbnailBucketPrefix}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.referenceNumber}/${pck.videoFileName.replace('mp4', 'jpg')}`}
                                                        controls
                                                    />
                                                </div>
                                                <div className="content">
                                                    <div className="info">

                                                        <p>{pck.projectName}</p>
                                                        <p>{pck.projectDescription}</p>
                                                    </div>
                                                    <div className="actions">
                                                        <a href=""
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setSingleVideo(pck);
                                                               setShowDeleteModal(true);
                                                           }}
                                                           className="icon-black button button-s button-destructive button-icon delete"
                                                           title="Delete">
                                                            {getSvg('delete')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                    {showDeleteModal ?
                                        <div id="modal-5" className="modal-container">
                                            <div className="modal">
                                                <div className="modal__header">
                                                    <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                                </div>
                                                <div className="modal__action">
                                                    <div className="button-group">
                                                        <button className="button button-m button-secondary"
                                                                type="button" onClick={() => {
                                                            setShowDeleteModal(false)
                                                        }}>No, Cancel
                                                        </button>
                                                        <button className="button button-m button-destructive"
                                                                type="button" onClick={() => deleteTestimonial()}>Yes,
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                    {showNoTestimonials ?
                                        <AlertMessage
                                            messageHeader={`You don't have any ${uploadType} video uploaded.`}
                                            messageBody={""}
                                            startDate={null}
                                            setShowWarningContainerMessage={''}
                                            messageType={"informational"}
                                            showButton={false}
                                        />
                                        : ''
                                    }
                                </div>
                            </div>
                        </>
                        : ''
                    }
                    {showUploadVideo ?
                        <>
                            <div id="modal-5" className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <h2 className="modal-title h4">Testimonial file upload</h2>
                                    </div>
                                    <div className="modal__content">
                                        {
                                            showProcessingVideo ?
                                                <AlertMessage
                                                    messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                                    messageBody={""}
                                                    startDate={null}
                                                    setShowWarningContainerMessage={''}
                                                    messageType={"informational"}
                                                    showButton={false}
                                                />
                                                : ''
                                        }
                                        {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                            <>
                                                <div
                                                    className={`form-field text-input ${!hasTestimonialName ? 'error' : ''}`}>
                                                    <label htmlFor="project-description">Testimonial name</label>
                                                    <input
                                                        name="testTimonialName"
                                                        id="testTimonialName"
                                                        type="text"
                                                        aria-required="true"
                                                        placeholder=""
                                                        value={testTimonialName}
                                                        onFocus={(e) => {
                                                            setHasTestimonialName(true);
                                                            setShowFormErrorMessage(false);
                                                        }}
                                                        onBlur={(e) => {
                                                            clearEmojis(e.target.value, 'testTimonialName');
                                                            checkTestmimonialName(e.target.value);
                                                        }}
                                                        onChange={(e) => {
                                                            setTestimonialName(e.target.value);
                                                        }}
                                                    >
                                                    </input>
                                                    {!hasTestimonialName ?
                                                        <p className="validation-message">Enter testimonial name.</p>
                                                        : ''}
                                                </div>
                                                <div
                                                    className={`form-field text-area ${!hasProjectDescription ? 'error' : ''}`}>
                                                    <label htmlFor="project-description">Description</label>
                                                    <div className="input-container">
                                                        <textarea
                                                            name="Project description"
                                                            id="project-description"
                                                            type="text"
                                                            aria-required="true"
                                                            placeholder=""
                                                            value={projectDescription}
                                                            onFocus={(e) => {
                                                                setHasProjectDescription(true);
                                                                setShowFormErrorMessage(false);
                                                            }}
                                                            onBlur={(e) => {
                                                                clearEmojis(e.target.value, 'projectDescription');
                                                                checkProjectDescription(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setProjectDescription(e.target.value);
                                                            }}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    {!hasProjectDescription ?
                                                        <p className="validation-message">Enter project description</p>
                                                        : ''
                                                    }
                                                </div>
                                            </>
                                            : ''
                                        }

                                        {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                            <div
                                                className={`form-field file-input ${!isValidVideo || showErrorMessage ? 'error' : ''}`}>
                                                <input type="file" id="fileupload" className="file-input__input"
                                                       onChange={(e) => {
                                                           setShowErrorMessage(false);
                                                           setIsValidVideo(true);
                                                           changeHandlerUploads(e)
                                                       }} ref={videoRef}/>
                                                <label htmlFor="fileupload" className="button button-m button-tertiary">
                                                    {getSvg('upload')}
                                                    <span className="text">Choose video&hellip;</span>
                                                </label>
                                                {showErrorMessage ?
                                                    <p className="validation-message">Choose a video first.</p>
                                                    : ''
                                                }
                                                {!isValidVideo ?
                                                    <p className="validation-message">{videoFormatError}</p>
                                                    : ''
                                                }
                                                {fileToBeUploaded !== '' ? <p>{fileToBeUploaded}</p> : ''}
                                            </div>
                                            : errorUploadingVideo ?
                                                <div className="alert-container">
                                                    <div className="alert alert--error">
                                                        <div className="alert__content">
                                                            <p className="alert__heading">There was an error uploading
                                                                the video. Please try it again.</p>
                                                        </div>
                                                        <button type="button"
                                                                className="button-tertiary button-s button-icon alert__close"
                                                                onClick={() => setErrorUploadingVideo(false)}>
                                                            <span className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17 7L7 17M7 7L17 17" stroke="currentColor"
                                                                          strokeWidth="2" strokeLinecap="round"
                                                                          strokeLinejoin="round"/>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                        <div className='file-input-container block'>
                                            <div className='file-input-container block'>
                                                {
                                                    showProcessingVideo ?
                                                        <span>
                                                            <small>
                                                                <span className='gray-600'>
                                                                    Uploading video  <progress className='progress-bar'
                                                                                               value={fileProgressSelfVideo}
                                                                                               max='100'></progress> &nbsp;
                                                                    {fileProgressSelfVideo} of 100% completed
                                                                </span>&nbsp;&nbsp;&nbsp;<span
                                                                className="loader loader--s"></span>
                                                            </small>

                                                        </span>
                                                        : ''
                                                }
                                                {showProcessing ?
                                                    <AlertMessage
                                                        messageHeader={process.env.REACT_APP_PROCESSING_VIDEO_MESSAGE}
                                                        messageBody={process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}
                                                        startDate={null}
                                                        setShowWarningContainerMessage={''}
                                                        messageType={"success"}
                                                        showButton={false}
                                                        showLoader={true}
                                                    />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        {showFormErrorMessage ?
                                            <AlertMessage
                                                messageHeader={"Testimonial name and description are required."}
                                                messageType={"error"}
                                            />
                                            : ''
                                        }
                                    </div>
                                    {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                        <div className="modal__action">
                                            <div className="button-group">
                                                <button
                                                    type='button'
                                                    id='uploadbutton'
                                                    className='button button-m button-primary'
                                                    onClick={() => {
                                                        handleUpload()
                                                    }}
                                                >
                                                    {getSvg('upload')}
                                                    Upload video
                                                </button>

                                                <button
                                                    type='reset'
                                                    aria-label='Exit'
                                                    value='Exit'
                                                    className='button button-m butto-secondary'
                                                    onClick={() => {
                                                        setShowUploadVideo(false);
                                                        setShowErrorMessage(false);
                                                        setTestimonialName('');
                                                        setProjectDescription('');
                                                        setIsValidVideo(true);
                                                    }}
                                                >
                                                    Exit
                                                </button>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </>
                        : ''
                    }
                </div>
            </main>
        </div>
    )
}


