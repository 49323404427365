import React, { useState } from 'react';
import getSvg from '../../utils/getSvg';
import {
  PayPalScriptProvider,
  usePayPalCardFields,
  PayPalCardFieldsProvider,
  PayPalCardFieldsForm,
  PayPalButtons,
} from "@paypal/react-paypal-js";

import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance';

const SubmitPayment = ({ isPaying, setIsPaying, billingAddress }) => {
  const { cardFieldsForm, fields } = usePayPalCardFields();

  const handleClick = async () => {
    if (!cardFieldsForm) {
      const childErrorMessage =
        "Unable to find any child components in the <PayPalCardFieldsProvider />";

      throw new Error(childErrorMessage);
    }
    const formState = await cardFieldsForm.getState();

    if (!formState.isFormValid) {
      return alert("The payment form is invalid");
    }
    setIsPaying(true);

    cardFieldsForm.submit({ billingAddress }).catch((err) => {
      setIsPaying(false);
    });
  };

  return (
    <button
      className={isPaying ? "button-l pay-now loading" : "button-l button-primary"}
      onClick={handleClick}
      style={{ width: '-webkit-fill-available' }}
      disabled={isPaying ? true : false}
    >
      {isPaying ? <div className="paypal-spinner" /> : "Pay Now"}
    </button>
  );
};

export default function PayPalForm(
  {
    modalHeader,
    modalSubHeader = "Enter your credit card information, so you can create a new package.",
    amountPaid,
    onClose,
    packageName,
    serviceType,
    onTransactionApproval,
    builtPackage,
    userId,
    userInfo,
    additionalData = {}
  }) {

  const [amount, setAmount] = useState(amountPaid);
  const [transactionDone, setTransactionDone] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState('');
  const [isPaying, setIsPaying] = useState(false);
  const [billingAddress, setBillingAddress] = useState({
    addressLine1: userInfo.address,
    addressLine2: "",
    adminArea1: userInfo.state,
    adminArea2: userInfo.city,
    countryCode: "US",
    postalCode: userInfo.zip,
  });


  async function createOrder(data) {
    console.log("Data ", data);
    setIsProcessing(true);
    return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/api/paypal/createOrder`, {
      ...data,
      card: {
        attributes: {
          verification: {
            method: "SCA_ALWAYS",
          },
        },
      },
      userId,
      serviceType
    })
      .then((orderResponse) => {
        const order = orderResponse.data;

        setBillingAddress({
          addressLine1: order.billingAddress.address_line_1,
          addressLine2: order.billingAddress.address_line_2,
          adminArea1: order.billingAddress.admin_area_1,
          adminArea2: order.billingAddress.admin_area_2,
          countryCode: order.billingAddress.country_code,
          postalCode: order.billingAddress.postal_code,
        });

        return order.id;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function onApprove(data) {
    setPaymentMessage('');
    return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/api/paypal/orders/${data.orderID}/capture`, {
      userId: userId,
      amount: amount,
      serviceType: serviceType,
      packageName: packageName ?? null,
      additionalData
    })
      .then((paymentResponse) => {
        if (paymentResponse.data) {
          setIsProcessing(false);
          setIsApproved(true);
          setTransactionDone(true);
          setTimeout(() => {
            handleTransactionApproval({ isApproved: true, additionalData: { ...paymentResponse.data } });
          }, 500);

        } else {
          throw new Error(paymentResponse);
        }
      })
      .then(_ => {
        return data.orderID;
      })
      .catch((err) => {
        setIsProcessing(false);
        setIsApproved(false);
        setTransactionDone(true);

        if (err.response && err.response.data) {
          setPaymentMessage(err.response.data.errorMessage);
        } else {
          setPaymentMessage(JSON.stringify(err));
          throw err;
        }
      }).finally(() => setIsPaying(false));
  }

  function onError(error) {
    setIsProcessing(false);
    setIsApproved(false);
    console.log('error processing payment:', JSON.stringify(error));
  }


  const handleTransactionApproval = (approval) => {
    // Perform your logic to check if the transaction is approved
    // If approved, set isApproved to true and call the callback function
    setIsApproved(approval);
    onTransactionApproval(approval); // Notify the parent component that the transaction is approved
  };

  function handleBillingAddressChange(field, value) {
    setBillingAddress((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  return (
    <>
      <div className="modal-container">
        <div className="modal">
          <div className="modal__header">
            <h2 className="modal-title h4">{modalHeader}</h2>
            {!isProcessing && !transactionDone ?
              <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => { setAmount(0); onClose(false) }}>
                {getSvg('close')}
              </button>
              : ''
            }
          </div>
          <div className='modal-content'>
            {!transactionDone && (
              <p>{serviceType !== 1 ? modalSubHeader : 'Enter your credit card information, so you can contact the property owner.'}
              </p>
            )}
            {amount > 0 && !transactionDone ?
              <div className='transaction-statement fz-block-sm'>
                <strong>You will be charged ${amount / 100}.00.</strong>
              </div>
              : ''
            }
            {!transactionDone || !isApproved ? (
              <PayPalScriptProvider
                options={{
                  clientId: process.env.REACT_APP_PAYPAL_CLIENTID,
                  components: "card-fields,buttons",
                  disableFunding: "paylater",
                  currency: "USD",
                  intent: "capture",
                  environment: "production",
                }}
              >
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                />
                <PayPalCardFieldsProvider
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                >
                  <PayPalCardFieldsForm />

                  <SubmitPayment
                    isPaying={isPaying}
                    setIsPaying={setIsPaying}
                    billingAddress={billingAddress}
                  />
                </PayPalCardFieldsProvider>
              </PayPalScriptProvider>
            ) : (
              ''
            )}

            <div className='transaction-statement approval'>
              {paymentMessage !== '' ?
                isApproved ?
                  <>
                    <span className='approved'>
                      {getSvg('green-checkmark')}
                    </span>&nbsp;
                    <strong>Transaction approved</strong>
                  </>
                  :
                  <div className='paypal-payment-error'>
                    <div className='fz-block-sm not-approved'>
                      <span className='not-approved'>

                      </span>&nbsp;
                      <strong>Transaction not approved</strong>
                    </div>
                    <span className='not-approved'>
                      Please fix this error:&nbsp;<strong>{paymentMessage}</strong>
                    </span>
                  </div>
                : ''
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


