import React from 'react';

export default function LeadsOptions({ option1, option2, filterLeads, leadTypeText = "Lead type", showAll, showLocal, setShowLocal, setShowAll }) {

    const handleRadioChange = (selection) => {
        if (selection === 'all') {
            if (!showAll) {
                setShowAll(true);
                setShowLocal(false);
                filterLeads('all');
            }
        } else if (selection === 'local') {
            if (!showLocal) {
                setShowAll(false);
                setShowLocal(true);
                filterLeads('local');
            }
        }
    };

    return (
        <div className="homefeed--details">
            <div className="homefeed--details__toggle">
                <legend>{leadTypeText}</legend>
                <div className="button-group">
                    <div className="form-field radio">
                        <input
                            type="radio"
                            id="radio-one"
                            name="local-all"
                            value="all"
                            checked={showAll}
                            onChange={() => handleRadioChange('all')}
                        />
                        <label htmlFor="radio-one">{option1}</label>
                    </div>
                    <div className="form-field radio">
                        <input
                            type="radio"
                            id="radio-two"
                            name="local-all"
                            value="local"
                            checked={showLocal}
                            onChange={() => handleRadioChange('local')}
                        />
                        <label htmlFor="radio-two">{option2}</label>
                    </div>
                </div>
            </div>
        </div>
    );
}
