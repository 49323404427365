import React, { useState, useEffect } from "react";
import {useParams, useNavigate} from 'react-router-dom';
import {copyToClipBoard} from "../../utils/copyToClipBoard";
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import CustomHeader from '../libs/CustomHeader'
import likeVideo from "../../utils/likeVideo";
import VideoComponent from "../libs/VideoComponent";

function HomeOwnersResponses() {
    // const [playedDurations, setPlayedDurations] = useState({});
    const {packageName, leadtype} = useParams();
    const [packagesResponses, setPackagesResponses] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNoData, setShowNoData] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [singlePackage, setSinglePackage] = useState([]);
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const thumbNailPrefix = 'fazzad-thumbnail-00001';
    const userType = 500003;
    useEffect(() => {
        sessionStorage.setItem('userType', 500003);
        if(parseInt(leadtype) === 0){
            getPaidPackageResponses(packageName);
        } else {
            sessionStorage.setItem('leadId', packageName);
            getFreePackageResponses(packageName);
        }
        document.body.classList.add('dark-mode');
        return () => {
            document.body.classList.remove('dark-mode');
        };
    }, []);

    const handleCopyToClipboard = (textToCopy) => {
        copyToClipBoard(textToCopy)
    };

    const handlePlayed = (pck, index) => {
        if(!pck.hasVideoBeenWatched) {
            setTimeout(() => {
                Axios.post(`${process.env.REACT_APP_LOCALHOST}/markVideoAsWatched`, {userId: packagesResponses[0].userId, id: packagesResponses[0].id}).then((response) => {
                }).catch(function (error) {
                    console.log(error);
                })
            }, 10000);
            
        } else {
            return;
        }
    };

    const saveVideoStats = (pck, videoFileName) => {
        const data = {
            userId: 999999,
            userType: userType,
            packageName: pck.packageName,
            videoFileName: videoFileName,
            currentPage: 'property owner response reels'
        }
        setTimeout(() => {
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/addVideosStats`, data).then((response) => {
                return;
            }).catch(function (error) {
                console.log(error);
            })
        }, 5000);
    }

    const likeVideoFunc = async (pck) => {
        await likeVideo(pck);
        if(leadtype === '0'){
            getPaidPackageResponses(packageName);
        } else {
            getFreePackageResponses(packageName);
        }
    }
    
    const deleteVideo = () => {
        setShowDeleteModal(false);
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/hidePackageFromHomeOwner`,
            {
                id: singlePackage.id,
                userId: singlePackage.userId,
                leadType: typeof singlePackage.leadType !== 'undefined' ? 0 : 1
            }).then((response) => {
            const tempData = packagesResponses.filter((item) => item.id !== singlePackage.id);
            setPackagesResponses(tempData);
            if(tempData.length === 0){
                setShowNoData(true);
            } else {
                setShowNoData(false);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const getPaidPackageResponses = (packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackagesResponsesHomeOwner`, {packageName: packageName}).then((response) => {
            if(response.data.length > 0){
                setShowNoData(false);
                const tempData = response.data;
                    tempData.map((pck) => {
                        pck.thumbNailSource = `${s3ThumbnailBucketPrefix}/${thumbNailPrefix}-${pck.packageName}/pro-videos-library/${pck.videoFileName.replace('mp4', 'jpg')}`;
                        pck.videoSource = `${s3BucketVideoPrefix}/${pck.packageName}/pro-videos-library/${pck.videoFileName}`;
                    });
                    setPackagesResponses(tempData);
            }else {
                setShowNoData(true);
            }
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            setShowNoData(false);
        })
    }
    const getFreePackageResponses = (packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getFreeLeadsReplies`, {leadId: packageName, isPropertyOwner: true}).then((response) => {
            if(response.data.length > 0){
                setShowNoData(false);
                const tempData = response.data;
                    tempData.map((pck) => {
                        pck.thumbNailSource = `${s3ThumbnailBucketPrefix}/${thumbNailPrefix}-${pck.packageName}/pro-videos-library/${pck.videoFileName.replace('mp4', 'jpg')}`;
                        pck.videoSource = `${s3BucketVideoPrefix}/${pck.packageName}/pro-videos-library/${pck.videoFileName}`;
                    });
                    setPackagesResponses(tempData);
            }else {
                setShowNoData(true);
            }
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            setShowNoData(false);
        })
    }

    return (
        <div id="fazzad-site-container">
            <CustomHeader
                packageName={packageName}
                leadtype={'none'}
            />
            <main id="fazzad-main">
                <div id="po-container">
                    <div id="po-feed-container" className="reel-view">
                        {showLoader && (<span className="loader loader--l"></span>)}
                        {!showNoData ?
                            packagesResponses.map((pck, index) => {
                                return(
                                    <div className="po-feed-item" key={`resp_${pck.id}_${index}`}>
                                        <VideoComponent
                                            pck={pck}
                                            videoKey={index}
                                            videoType={'project'}
                                            handleCopyToClipboard={handleCopyToClipboard}
                                            setShowDeleteModal={setShowDeleteModal}
                                            hasActions={true}
                                            likeVideoFunc={likeVideoFunc}
                                            setSinglePackage={setSinglePackage}
                                            saveVideoStats={saveVideoStats}
                                            index={index}
                                            setSingleVideo={null}
                                            handlePlayed={handlePlayed}
                                            packageInfo={[]}
                                            isHomeOwner={true}
                                            leadtype={leadtype}
                                        />
                                    </div>
                                );
                            })
                        :
                            <AlertMessage
                                messageHeader={"You dont have any responses from Pros and Vendors yet."}
                                messageBody={""}
                                startDate={null}
                                setShowWarningContainerMessage={''}
                                messageType={"informational"}
                                showButton={false}
                            />
                        }
                    </div>
                    { showDeleteModal ?
                        <>
                        <div id="modal-4" className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                </div>
                                <div className="modal__action">
                                    <div className="button-group">
                                        <button className="button button-m button-primary" type="button" onClick={() => {deleteVideo()}}>Ok</button>   
                                        <button className="button button-m button-secondary" type="button" onClick={() => {setShowDeleteModal(false)}}>Cancel</button>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : ''
                    }
                </div>
            </main>
        </div>
    );
}

export default HomeOwnersResponses;