/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { useNavigate, useParams } from 'react-router-dom';
import { Upload } from "@aws-sdk/lib-storage";
import { S3 } from "@aws-sdk/client-s3";
import PageTitle from './PageTitle';
import Axios from 'axios';
import Header from './Header';
import ProgressBar from '../libs/ProgressBar';
import getSvg from '../../utils/getSvg';
import { getPutObjectSignedUrl } from "../../utils/awsHelpers";

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function EditPackagePictures() {
    const navigate = useNavigate();
    const { packageName, pictureType } = useParams();
    const [currentIndex, setCurrentIndex] = useState(0)
    const [errorMessage, setErrorMessage] = useState('Just images with format .jpg or .jpeg or .png can be uploaded');
    const [files, setFiles] = useState([]);
    const [fileProgressScopeOfWork, setFileProgressScopeOfWork] = useState(0);
    const [hasFilesToBeUploaded, setHasFilesToBeUploaded] = useState(true);
    const [numberOfFiles, setNumberOfFiles] = useState(0);
    const [pictureTypeDisplay, setPictureTypeDisplay] = useState('');
    const processingMessage = process.env.REACT_APP_PROCESSING_MESSAGE;
    const [selectedPictures, setSelectedPictures] = useState([]);
    const [showNoFilesToDelete, setShowNoFilesToDelete] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showWrongImageFormat, setShowWrongImageFormat] = useState(false);
    const [uploadTypeText, setUploadTypeText] = useState('picture');
    const [uploadStarted, setUploadStarted] = useState(false);
    const dwgExtension = /\.dwg$/i;
    const fileInputRef = useRef();
    const imageExtensionsRegex = /\.(jpg|jpeg|png)$/i;
    const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
    const pdfExtension = /\.pdf$/i;
    const s3BucketPrefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com';
    const s3BucketPrefixThumbnail = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const s3BucketPrefixVideo = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const videoSourceBucket = process.env.REACT_APP_PROJECT_VIDEO_SOURCE;
    const scopeOfWorkName = process.env.REACT_APP_SCOPE_OF_WORK;
    let wrongFiles = [];
    const [countScopeOfWorkVideo, setCountScopeOfWorkVideo] = useState(0);
    const videoBucket = process.env.REACT_APP_SCOPE_OF_WORK;
    const bucketName = 'fazzad-projects';
    const filesToBeDeleted = [];
    let scopeOfWorkVideoUrl = '';
    const config = {
        bucketName: bucketName,
        region: process.env.REACT_APP_REGION,
    };

    const configVideo = {
        bucketName: process.env.REACT_APP_S3_BUCKET_PROS_SOURCE,
        region: process.env.REACT_APP_REGION,
    };

    useEffect(() => {
        switch (pictureType) {
            case 'dwg-files':
                setUploadTypeText('file');
                setPictureTypeDisplay('CAD');
                break;
            case 'pdf-files':
                setPictureTypeDisplay('PDFs');
                setUploadTypeText('document');
                break;
            case 'still-pictures':
                setPictureTypeDisplay('Still Pictures');
                setUploadTypeText('picture');
                break;
            case 'cover-image':
                setPictureTypeDisplay('Cover Image');
                setUploadTypeText('picture');
                break;
            case 'scope-of-work':
                setPictureTypeDisplay('Scope of Work');
                setUploadTypeText('video');
                break;
            case 'scope-of-work-videos':
                setPictureTypeDisplay('Scope of Work');
                setUploadTypeText('video');
                break;
            case 'three-sixty-pictures':
                setPictureTypeDisplay('360º Pictures');
                setUploadTypeText('picture');
                break;
            case 'drone-pictures':
                setPictureTypeDisplay('Drone Pictures');
                setUploadTypeText('picture');
                break;
        }
        getPackageImage(packageName);
    }, []);

    const addSelectedPicture = (e) => {
        // Get the current file path from the checkbox value
        const filePath = e.target.value;

        setSelectedPictures((currentSelectedPictures) => {
            // Check if the file is already selected
            if (currentSelectedPictures.includes(filePath)) {
                // If selected, remove it from the array
                return currentSelectedPictures.filter(item => item !== filePath);
            } else {
                // If not selected, add it to the array
                return [...currentSelectedPictures, filePath];
            }
        });
    };

    const goToFeed = (userId) => {
        navigate(`/survey`);
    }

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Set the canvas dimensions to the original width and height
                    canvas.width = img.width;
                    canvas.height = img.height;

                    // Draw the image on the canvas
                    ctx.drawImage(img, 0, 0);

                    // Compress the image using the specified quality (0.5)
                    canvas.toBlob(
                        (blob) => {
                            const compressedFile = new File([blob], file.name, {
                                type: file.type,
                                lastModified: Date.now(),
                            });
                            resolve(compressedFile);
                        },
                        file.type,
                        0.3
                    );
                };
                img.onerror = (error) => {
                    reject(error);
                };
                img.src = event.target.result;
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    const getPackageImage = async (pkName) => {
        if (typeof pkName !== 'undefined') {
            try {
                const allImages = [];
                Axios.post(`${process.env.REACT_APP_LOCALHOST}/uniqueImage`, { packageName: pkName, pictureType: pictureType }).then((response) => {
                    setNumberOfFiles(response.data.length);
                    if (response.data.length > 0) {
                        response.data.forEach((element, index) => {
                            const fileNameArray = element.Key.split('/');
                            if (fileNameArray[2].trim() !== '') {
                                allImages.push({
                                    compacted: `${s3BucketPrefix}/${element.Key}`,
                                    original: `${s3BucketPrefix}/${element.Key}`,
                                    imagePath: `${fileNameArray.join('/')}`,
                                    thumbnail: `${s3BucketPrefixThumbnail}/${element.Key}`,
                                    fileName: fileNameArray[fileNameArray.length - 1],
                                    fileIndex: index,
                                });
                            }
                        });
                        setFiles(allImages);
                    }
                }).catch(function (error) { })
            } catch (err) { }
        }
    }

    const updateFilesSelected = (e) => {
        e.preventDefault();
        wrongFiles = [];
        const files = [...e.target.files];
        setSelectedPictures(e.target.files);
        if (e.target.files.length > 0) {
            files.map((file) => {
                switch (pictureType) {
                    case 'pdf-files':
                        if (!pdfExtension.test(file.name)) {
                            wrongFiles.push(file.name);
                            setErrorMessage('Just files with format .pdf can be uploaded');
                        }
                        break;
                    case 'dwg-files':
                        if (!dwgExtension.test(file.name)) {
                            setErrorMessage('Just files with format .dwg can be uploaded');
                            wrongFiles.push(file.name);
                        }
                        break;
                    case 'scope-of-work':
                        if (!videoExtensionsRegex.test(file.name)) {
                            setErrorMessage('Just files with format mp4|mov|avi|wmv|flv|webm can be uploaded');
                            wrongFiles.push(file.name);
                        }
                        break;
                    case 'scope-of-work-videos':
                        if (!videoExtensionsRegex.test(file.name)) {
                            setErrorMessage('Just files with format mp4|mov|avi|wmv|flv|webm can be uploaded');
                            wrongFiles.push(file.name);
                        }
                        break;
                    default:
                        if (!imageExtensionsRegex.test(file.name)) {
                            setErrorMessage('Just images with format jpg or jpeg or png can be uploaded');
                            wrongFiles.push(file.name);
                        }
                        break;
                }
            });
            if (wrongFiles.length === 0) {
                setShowWrongImageFormat(false);
                showConfirmModal();
            } else {
                setShowWrongImageFormat(true);
            }
        } else {
            setSelectedPictures([]);
        }
    }

    const showConfirmModal = () => {
        setShowModal(true);
    }

    const checkForDeletion = (e) => {
        if (selectedPictures.length > 0) {
            setShowDeleteModal(true);
            setShowNoFilesToDelete(false);
        } else {
            setShowNoFilesToDelete(true);
            setShowDeleteModal(false);
        }
    }

    const deleteFilesFromS3 = async (type = 'files') => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteFilesFromPackage`, {
            isVideo: pictureType !== 'scope-of-work' ? false : true,
            packageName: packageName,
            bucketName: pictureType !== 'scope-of-work' ? bucketName : process.env.REACT_APP_PROJECT_VIDEOS_BUCKET_NAME,
            filesNames: type === 'files' ? selectedPictures : filesToBeDeleted,
        }).then((response) => {
            let remainingFiles = [];
            if (response.data.Deleted.length > 0) {
                if (type === 'files') {
                    remainingFiles = files.filter((file) =>
                        !selectedPictures.includes(file.imagePath)
                    );
                } else {
                    remainingFiles = files.filter((file) =>
                        !filesToBeDeleted.includes(file.imagePath)
                    );
                }
                setFiles(remainingFiles);
                setNumberOfFiles(remainingFiles.length);
                setShowDeleteModal(false);
                setSelectedPictures([]);
                updateHasPictures(remainingFiles.length);
                getPackageImage(packageName);
            }
        }).catch(function (error) {
            setShowDeleteModal(false);
            console.log('error = ', error);
        });
    };

    const updatePackageCoverImage = (fileName, packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateCoverImage`, {
            coverImageUrl: fileName,
            packageName: packageName,
        }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('error = ', error);
        });
    }

    const uploadVideo = async (file, bucketName, packageName, config) => {
        try {
            setFileProgressScopeOfWork(0);
            setUploadStarted(true); // Assuming this is to indicate the start of the upload
            const fileName = cleanMediaUrl(file.name);
            const key = `${packageName}/${scopeOfWorkName}/${fileName}`;
            const targetVideo = {
                Bucket: bucketName,
                Key: `${packageName}/${scopeOfWorkName}/${fileName}`,
                Body: file
            };

            await uploadWithSignedUrl(file, bucketName, key, setFileProgressScopeOfWork);
        } catch (error) {
            setUploadStarted(false);
            setShowProcessing(false);
            console.error('Upload error:', error);
        } finally {
            setShowProcessing(true);
            setHasFilesToBeUploaded(false);
            setTimeout(() => {

                setUploadStarted(false);
            }, process.env.REACT_APP_TIEMOUT);
        }
    }

    async function uploadWithSignedUrl(fileData, bucketName, key, onPrgress) {
        const signedUrl = await getPutObjectSignedUrl(bucketName, key, fileData.type);

        if (!signedUrl) {
            throw new Error("cannot generate signed url");
        }

        const xhr = new XMLHttpRequest();

        xhr.open('PUT', signedUrl, true);
        // Set the appropriate headers
        xhr.setRequestHeader('Content-Type', fileData.type);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                const myTotal = progress.toString().substring(0, 4);
                onPrgress && onPrgress(myTotal);
                console.log(myTotal);
            }
        };

        xhr.send(fileData);

        // Handle errors
        xhr.onerror = function (ev) {
            console.error('Error uploading file.', ev);
        };

        xhr.onload = function () {
            if (xhr.status === 200) {
                console.log('Request completed successfully:', xhr.responseText);
            } else {
                console.error('Request failed with status:', xhr.status);
                throw new Error(xhr.error);
            }
        };
    }

    const uploadPromise = async (file, packageName) => {
        try {
            let s3BucketName = pictureType;
            let compressedFile = '';
            let uploadPath = '';
            compressedFile = file;
            const uploadFileName = cleanMediaUrl(file.name);
            let bucketName = config.bucketName;
            if (pictureType !== 'scope-of-work') {
                uploadPath = `${packageName}/${s3BucketName}/${uploadFileName}`;
                bucketName = configVideo.bucketName;
            } else {
                uploadPath = `${packageName}/${videoBucket}/${uploadFileName}`;
            }

            try {
                await uploadWithSignedUrl(compressedFile, bucketName, uploadPath)
            } catch (uploadError) {
                console.error(`Upload failed for ${file.name}: ${uploadError}`);
            }

        } catch (error) {
            console.error(`Batch upload failed: ${error}`);
        }
    };

    const updateHasPictures = (remainingFile) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateHasPictures`, {
            packageName: packageName,
            pictureType: pictureType,
            hasPictures: remainingFile > 0 ? true : false
        }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('error = ', error);
        });
    }

    const handleUpload = async () => {
        try {
            setShowModal(false);
            setCountScopeOfWorkVideo(0);
            setShowProcessing(true);
            if (!showWrongImageFormat) {
                if (selectedPictures.length > 0) {
                    setShowUploadModal(true);
                    try {
                        let fileName = '';
                        let fileIndex = 0;
                        if (pictureType === 'cover-image') {
                            fileName = cleanMediaUrl(selectedPictures[0].name);
                        }
                        for (const pict of selectedPictures) {
                            fileIndex++;
                            setCurrentIndex(fileIndex);
                            if (pictureType !== 'scope-of-work') {
                                await uploadPromise(pict, packageName); // Passing a single file instead of an array
                            } else {
                                await uploadVideo(pict, videoSourceBucket, packageName, configVideo);
                            }
                        }
                        if (pictureType === 'cover-image' || pictureType === 'scope-of-work') {
                            files.forEach((file) => {
                                filesToBeDeleted.push(file.imagePath);
                            });
                            deleteFilesFromS3('cover');
                            if (pictureType === 'cover-image') {
                                updatePackageCoverImage(fileName, packageName);
                            }
                        }
                        setSelectedPictures([]);
                        updateHasPictures(selectedPictures.length);
                        if (pictureType !== 'scope-of-work') {
                            getPackageImage(packageName);
                        }
                        setTimeout(() => {
                            if (pictureType === 'scope-of-work') {
                                setShowUploadModal(false);
                                getPackageImage(packageName);
                                window.location.reload();
                            } else {
                                setShowUploadModal(false);
                            }
                        }, pictureType !== 'scope-of-work' ? 3000 : 10000);
                    } catch (e) {
                        setShowUploadModal(false);
                        setSelectedPictures([]);
                        console.error(`Uploading file error: ${e}`);
                    }
                } else {
                    setSelectedPictures([]);
                    setShowModal(false);
                    setShowUploadModal(false);
                }
            }
        } catch (e) {
            setSelectedPictures([]);
            setShowModal(false);
        }
    }

    return (
        <div>
            <Header />
            {showModal && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Are you sure you want to upload {selectedPictures.length} {selectedPictures.length > 1 ? 'files ' : 'file '} ?</h2>
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-secondary" type="button" onClick={() => { setShowModal(false); setSelectedPictures([]) }}>No, Cancel</button>
                                <button className="button button-m button-primary" type="button" onClick={() => { handleUpload() }}>Yes, Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showDeleteModal && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Are you sure you want to delete {selectedPictures.length} file{selectedPictures.length > 1 ? 's ?' : ' ?'}</h2>
                        </div>
                        <div className="modal__content">
                            <p>Once a file is deleted it cannot be recovered. You will need to upload it again.</p>
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false) }}>No, Cancel</button>
                                <button className="button button-m button-destructive" type="button" onClick={() => deleteFilesFromS3()}
                                >Yes, Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showUploadModal ?
                <div id="modal-6" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">File Upload Progress</h2>
                        </div>
                        <div className="modal__content">
                            {showProcessing && (
                                <div className="alert-container">
                                    <div className="alert alert--informational">
                                        <div className="alert__content">
                                            <div className="alert-inline__icontitle">
                                                <p className="alert__heading">{process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="file-uploads">
                                <div className='fz-block-sm'></div>
                                {showProcessing && parseInt(fileProgressScopeOfWork) === 100 && (
                                    <div className='fz-block-sm'>
                                        <p className="message fz-align-center">{processingMessage}&nbsp;&nbsp;<span className="loader loader--s"></span></p>
                                    </div>
                                )}
                                <section>
                                    {pictureType !== 'scope-of-work' && currentIndex < selectedPictures.length && selectedPictures.length > 0 ?
                                        currentIndex > 0 ?
                                            <>
                                                <h5>{pictureTypeDisplay}</h5>
                                                <div className="message"> Uploading {uploadTypeText} {currentIndex} of {selectedPictures.length}<span className="loader loader--s"></span></div>
                                            </>
                                            :
                                            ''
                                        :
                                        currentIndex === selectedPictures.length && selectedPictures.length > 0 && pictureType !== 'scope-of-work' ?
                                            <div className='message fz-align-center'>
                                                <p>All {uploadTypeText}s have been uploaded!</p>
                                                {<span className="icon">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#007000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>}
                                            </div>
                                            : ''
                                    }
                                    {pictureType === 'scope-of-work' ? (
                                        <section>
                                            {fileProgressScopeOfWork > 0 && fileProgressScopeOfWork < 100 && countScopeOfWorkVideo < 1 ? (
                                                <>
                                                    <ProgressBar
                                                        fileProgress={fileProgressScopeOfWork}
                                                        fileType={'video'}
                                                    />

                                                </>
                                            ) : (
                                                fileProgressScopeOfWork < 100 && (<div className="message">Preparing {uploadTypeText} to be uploaded <span className="loader loader--s"></span></div>)
                                            )}
                                        </section>
                                    ) : (
                                        ''
                                    )}

                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
            }

            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <li>
                                <a href="/" onClick={(e) => { e.preventDefault(); goToFeed(); }}>Home</a>
                                <span className="separator">
                                    {getSvg('chevron-right')}
                                </span>
                            </li>
                            <li>
                                <a href="/" onClick={(e) => { e.preventDefault(); navigate(`/editpackage/${packageName}`); }}>Package Details</a>
                                <span className="separator">
                                    {getSvg('chevron-right')}
                                </span>
                            </li>
                            <li>
                                <a href="/" onClick={(e) => { e.preventDefault(); navigate(`/editpackage/${packageName}?view=true`); }}>Upload Files</a>
                                <span className="separator">
                                    {getSvg('chevron-right')}
                                </span>
                            </li>
                            <li aria-current="page">
                                <span>{pictureTypeDisplay}</span>
                            </li>
                        </ol>
                    </nav>
                    <PageTitle
                        headerText={`Edit ${pictureTypeDisplay}`}
                        subHeaderText={`Add or remove ${pictureType}.`}
                        showSubHeader={true}
                    />
                    <div className="form-container" id="edit-files">
                        <form action="">
                            <section className="form-pictures outlined collapsed">
                                <header>
                                    <h2 className="h4">{pictureTypeDisplay} <span className="count">({numberOfFiles})</span></h2>
                                    <div className="button-group">
                                        <div className="form-field file-input">
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                id="cover-picture"
                                                className="file-input__input"
                                                data-multiple-caption='{count} files selected'
                                                multiple={pictureTypeDisplay.toLowerCase() === 'cover image' || pictureTypeDisplay.toLowerCase() === 'scope of work' ? false : true}
                                                onChange={updateFilesSelected}
                                            />
                                            <label htmlFor="cover-picture" className="button button-s button-tertiary">
                                                {getSvg('upload')}
                                                <span className="text">{pictureTypeDisplay.toLowerCase() !== 'cover image' && pictureTypeDisplay.toLowerCase() !== 'scope of work' ? 'Upload ' : 'Replace '} {uploadTypeText}{pictureTypeDisplay.toLowerCase() !== 'cover image' && pictureTypeDisplay.toLowerCase() !== 'scope of work' ? 's' : ''}</span>
                                            </label>
                                        </div>
                                        {files.length > 0 && pictureType !== 'cover-image' && pictureType !== 'scope-of-work' ?
                                            <div className="form-field file-input">
                                                <button type="button" className="button-destructive-outlined button-s" onClick={() => checkForDeletion()}>
                                                    {getSvg('delete')}
                                                    <span className="text">Delete Selected {uploadTypeText}s</span>
                                                </button>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </header>
                                {showWrongImageFormat ?
                                    <div id="modal-2" className="modal-container">
                                        <div className="modal">
                                            <div className="modal__header">
                                                <div className="alert-container">
                                                    <div className="alert alert--error">
                                                        <div className="alert__content">
                                                            <div className="alert-inline__icontitle">
                                                                <p className="alert__heading">Something wrong with the files you selected.</p>
                                                            </div>
                                                            <p className="alert__message">{errorMessage}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => { setSelectedPictures([]); setShowWrongImageFormat(false) }}>
                                                    {getSvg('close')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                                {showNoFilesToDelete ?
                                    <div id="modal-2" className="modal-container">
                                        <div className="modal">
                                            <div className="modal__header">
                                                {getSvg('warning')}
                                                <h2 className="modal-title h4 warning">You have not selected any files to be deleted</h2>
                                                <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => { setShowNoFilesToDelete(false) }}>
                                                    {getSvg('close')}
                                                </button>
                                            </div>
                                            <div className="modal__content">
                                                <p>In order to delete files, you need to select at least one file first.</p>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className="uploaded-files">
                                    <fieldset>
                                        <div className="uploaded-file-grid">
                                            {files.map((file, index) => {
                                                if (pictureType === 'scope-of-work') {
                                                    scopeOfWorkVideoUrl = `${s3BucketPrefixVideo}/${packageName}/${videoBucket}/${file.fileName}`;
                                                }
                                                return (
                                                    <div className="uploaded-file rounded" key={`div_${index}`}>
                                                        {pictureType !== 'scope-of-work' && pictureType !== 'cover-image' && (
                                                            <>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`label_checkbox_${index}`}
                                                                    value={file.imagePath}
                                                                    key={`checkbox_${index}`}
                                                                    onChange={(e) => addSelectedPicture(e)}
                                                                    checked={Array.isArray(selectedPictures) && selectedPictures.includes(file.imagePath)}
                                                                />
                                                                <label htmlFor={`label_checkbox_${index}`}>
                                                                    <span className="uploaded-file__preview">
                                                                        {pictureType === 'pdf-files' && (getSvg('pdf'))}
                                                                        {pictureType === 'dwg-files' && (getSvg('cad'))}
                                                                        {pictureType !== 'pdf-files' && pictureType !== 'dwg-files' && (<img src={pictureType !== 'scope-of-work' ? file.compacted : file.thumbnail} alt="File" />)}
                                                                    </span>
                                                                    <span className="uploaded-file__name">{file.fileName}</span>
                                                                </label>
                                                            </>
                                                        )}
                                                        {pictureType === 'cover-image' && (
                                                            <label id="label-cover-image">
                                                                <span className="uploaded-file__preview">
                                                                    <img src={file.compacted} />
                                                                </span>
                                                                <span className="uploaded-file__name">{file.fileName}</span>
                                                            </label>

                                                        )}
                                                        {pictureType === 'scope-of-work' && (
                                                            <video
                                                                className='fz-video rounded'
                                                                width='100%'
                                                                height='auto'
                                                                controls
                                                                poster={file.thumbnail}
                                                            >
                                                                <source
                                                                    src={scopeOfWorkVideoUrl.replace('jpg', 'mp4')}
                                                                    type='video/mp4'
                                                                />
                                                            </video>
                                                        )}
                                                    </div>

                                                );
                                            })}

                                        </div>
                                    </fieldset>
                                </div>
                            </section>
                            <nav id="step-controls">
                                <div className="button-group">
                                    <button
                                        type="button"
                                        className="button-m button-tertiary"
                                        onClick={(e) => { e.preventDefault(); navigate(`/editpackage/${packageName}?view=true`); }}
                                    >
                                        <span className="text">
                                            Back
                                        </span>
                                    </button>
                                </div>
                            </nav>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    )
}
