import React, {useState, useEffect, useRef} from 'react';

function VideoWithLoading({
                              videoSource,
                              videoThumbNailSource,
                              packageInfo,
                              pck,
                              videoPrefix,
                              isFullScreen,
                              handlePlayed,
                              saveVideoStats
                          }) {
    const [isLoading, setIsLoading] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        const checkVideoAvailability = async () => {
            try {
                const response = await fetch(videoSource);
                if (response.ok) {
                    setIsLoading(false);
                } else {
                    setTimeout(checkVideoAvailability, 2000);
                }
            } catch (error) {
                console.error('Error checking video availability:', error);
            }
        };

        checkVideoAvailability();
    }, [videoSource]);

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {isLoading ? (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px'}}>
                    <span className="loader loader--s"></span>
                </div>
            ) : (
                <video
                    className={`rounded ${isFullScreen ? 'video-mode-contain' : ''}`}
                    src={videoSource}
                    poster={videoThumbNailSource}
                    controls
                    ref={videoRef}
                    onPlay={(e) => {
                        handlePlayed(packageInfo);
                        saveVideoStats(
                            pck,
                            `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX}/${pck.packageName}/${videoPrefix}/${pck.videoFileName}`
                        );
                    }}
                />
            )}
        </div>
    );
}

export default VideoWithLoading;
