/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Axios from 'axios';
import PageTitle from './PageTitle';
import getSvg from '../../utils/getSvg';
import ThreeSixtyComponent from './ThreeSixtyComponent';
import Header from './Header';
export default function PhotoGallery() {
    const navigate = useNavigate();
    const {packageName, pageName, leadtype} = useParams();
    const [allImages, setAllImages] = useState([]);
    const [packages, setPackages] = useState([]);
    const [galleryName, setGalleryName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [showNoPictures, setShowNoPictures] = useState(true);
    const [picture, setPicture] = useState({});
    // const s3BucketPrefix = process.env.REACT_APP_S3_BUCKET_PREFIX;
    const s3BucketPrefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com';
    const userId = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    let pageTitle = ''; 
    const goToPackageDetails = (packageName) => {
        if(parseInt(userType) === 500001){
            navigate(`/survdetails/${packageName}`);
        } else if (parseInt(userType) === 500002){
            navigate(`/prodetails/${packageName}`);
        } else {
            navigate(`/showdetails/${packageName}/${leadtype}`);
        }
    }

    const goToFeed = () => {
        if(parseInt(userType) === 500001){
            navigate(`/survey`);
        } else if (parseInt(userType) === 500002){
            navigate(`/profeed`);
        } else {
            navigate(`/property/${packageName}`);
        }
    }

    const setValues = (image, imageTitle) => {
        setShowModal(true);
        setPicture({"image": image, "title": imageTitle});
    }

    const getPackageImage = async (pkName, mediaType) => {
        if(typeof pkName !== 'undefined'){
            try{
                const allImages = [];
                Axios.post(`${process.env.REACT_APP_LOCALHOST}/uniqueImage`, {packageName: pkName, pictureType: mediaType}).then((response) => {
                    if(response.data.length > 0){
                        setShowNoPictures(false);
                        response.data.forEach(element => {
                            const fileNameArray = element.Key.split('/');
                            if(fileNameArray[2].trim() !== '' ){
                                allImages.push({
                                    compacted: `${s3BucketPrefix}/${element.Key}`,
                                    original: `${s3BucketPrefix}/${element.Key}`,
                                    imagePath: `${fileNameArray.join('/')}`,
                                    imageName: `${fileNameArray[fileNameArray.length-1]}`,
                                });
                            }
                        });
                        setAllImages(allImages);
                    } else {
                        setAllImages(0);
                        setShowNoPictures(true);
                    }
                    setShowLoader(false);
                }).catch(function (error) {setShowLoader(false);})
            }catch (err){setShowLoader(false);}
        }
    }

    const getPackage = () => {
        try{
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackage`, {packageName: packageName}).then((response) => {
                // setShowLoader(false);
                if(response.data.length > 0){
                    setPackages(response.data);
                    getPackageImage(response.data[0].packageName, pageName);
                }
            }).catch(function (error) {
                console.log(error);
                setShowLoader(false);
            })
        }catch (err){
            setShowLoader(false);
        }
    }

    useEffect(() => {
        if(sessionStorage.getItem('isPropertyOwner') === 'true'){
            document.body.classList.add('dark-mode');
        }else{
            document.body.classList.remove('dark-mode');
        }
        switch (pageName) {
            case 'still-pictures':
                pageTitle = 'Still Pictures';
                break;
            case 'three-sixty-pictures':
                pageTitle = '360 Pictures';
                break;
            case 'drone-pictures':
                pageTitle = 'Drone Pictures';
                break;
            default:
                break;
        }
        setGalleryName(pageTitle);
        getPackage(packageName);
    }, []);

    return(
        <div id="fazzad-site-container">
            {packages.length > 0 ?
                <main id="fazzad-main">          
                    <Header
                        mode={sessionStorage.getItem('isPropertyOwner') === 'true' ? 'homeonwer' : 'default'} 
                    />
                    <div id="app-container"> 
                        <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                            <ol>
                                {parseInt(userType) !== 500003 && sessionStorage.getItem('isPropertyOwner') === 'false' ?
                                <>
                                    <li key={'home'}>
                                        <a href='' onClick={(e) => { goToFeed(userId); } }>Home</a>
                                        <span className="separator">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li key={'details'}>
                                        <a href='' onClick={(e) => { goToPackageDetails(packages[0].packageName); } }>Package Details</a>
                                        <span className="separator">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li aria-current="page" key={'page'}>
                                        <span>{galleryName}</span>
                                    </li>
                                </>
                            :
                                <>
                                    <li key={'project'}>
                                    <a href='' onClick={(e) => { navigate(`/showdetails/${packageName}/0`); } }>Project</a>
                                        <span className="separator">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li key={galleryName}>
                                        <small>{galleryName}</small>
                                    </li>
                                </>
                            }
                            </ol>
                        </nav> 
                        <div className='col ten'>
                            <PageTitle
                                headerText={galleryName}
                                subHeaderText={packages.length > 0 ? `${packages[0].packageAddress}${packages[0].packageAddress2.length > 0 ? ` ${packages[0].packageAddress2},` : ','} ${packages[0].packageCity}, ${packages[0].packageState}, ${packages[0].packageZip}` : ''}
                                showSubHeader={true}
                            />
                        </div>
                        {showLoader &&(<span className="loader loader--l" key={'loader'}></span>)}
                        <div className="pic-grid">
                            {allImages.length > 0 ?
                                allImages.map((image, index) => (
                                    <>
                                    <figure key={`figure_${index}`}>
                                        <img
                                            src={image.compacted}
                                            onClick={() => setValues(image.original, image.imageName)}
                                            style={{width: '100%'}}
                                            key={`index_${index}`}
                                        />
                                        <figcaption key={`figcaption$_{index}`}>{image.imageName}</figcaption>
                                    </figure>
                                    </>
                                ))
                            : 
                                showNoPictures ?
                                    allImages.length === 0 ?
                                        <div>
                                            <p className='fz-center'>Loading files</p> <span className="fz-spinner"><i className="icon-spinner fz-spinner__default icon-spinner__large"></i></span>
                                        </div>
                                :
                                    ''
                                : ''
                            }
                        </div>
                    </div>            
                </main>
            :
                ''
            }
            {showModal ?
                <div className="modal-container">
                    <div className="modal modal-lg">
                        <div className="modal__header">
                            <p>{picture.title}</p>
                            <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => {setShowModal(false)}}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className='modal-content'>
                            {pageName === 'three-sixty-pictures' ?
                                <div className='row'>
                                    <ThreeSixtyComponent image={picture.image}/>
                                </div>
                            :
                                <div className='row'>
                                    <img src={picture.image} style={{width: '100%'}}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            : ''
            }
        </div>
    )
}
