import React from 'react';
import getSvg from '../../utils/getSvg';
function PageTitleWithSteps(
    {currentStep1,
        currentStep2,
        header,
        subHeader,
        step1Header,
        step2Header,
        step1SubHeader,
        step2SubHeader,
        completedStep1,
        completedStep2,
        isInactive,
        isNewPackage,
    }
    ) {
    return (
        <>
        <div id="page-title">
            <h1 className="h3">{header}</h1>
            <p>{subHeader}</p>
        </div>
        <div className="steps-container" aria-label="Steps">
            <ol>
                <li className={`${completedStep1 ? 'complete' : ''} ${currentStep1 ? 'current' : ''}`}>
                    <a href="" onClick={(e) => e.preventDefault()} style={{cursor: 'default'}}>
                        {!isNewPackage ?
                            <span className="status-icon">
                                 {getSvg('green-checkmark')}
                            </span>
                        :
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 22C16 18 20 14.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 14.4183 8 18 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        }
                        <span className="text">
                            <span className="step-title">{step1Header}</span>
                            <span className="step-description">{step1SubHeader}</span>
                        </span>
                    </a>
                </li>
                <li className="separator">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </li>
                <li className={`${isInactive ? 'inactive' : completedStep2 ? 'complete' : ''} ${currentStep2 ? 'current' : ''}`}>
                    <a href="" onClick={(e) => e.preventDefault()} style={{cursor: 'default'}}>
                        {!isInactive && isNewPackage && !completedStep1 ?
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        :''
                        }
                        {!isNewPackage ?
                            <span className="status-icon">
                               {getSvg('green-checkmark')}
                            </span>
                        :
                            completedStep1 ?
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 22C16 18 20 14.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 14.4183 8 18 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            : ''
                        }
                        <span className="text">
                            <span className="step-title">{step2Header}</span>
                            <span className="step-description">{step2SubHeader}</span>
                        </span>
                    </a>
                </li>
            </ol>
        </div>
        </>
    );
}

export default PageTitleWithSteps;