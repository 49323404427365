import React from 'react';
import getSvg from '../../utils/getSvg';
function JobTags({isTagSelected, setTags}) {
    return (
        <>
            <h2 className="h4">Job Tags</h2>
            <p className="helper-text">Check all that apply.</p>
            <div className="form-row col-2">
                <fieldset className="border">
                    <legend>
                        {getSvg('palette')}
                        Design
                    </legend>
                    <div className="form-field checkbox">
                        <input type="checkbox" id="design-interior-design"  name="design-interior-design" value="design-interior-design" checked={isTagSelected('design-interior-design', 'design')} onChange={(e) => {setTags(e, 'design');}} />
                        <label htmlFor="design-interior-design">
                            Interior Design
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="design-civil" name="design-civil" value="design-civil" onChange={(e) => {setTags(e, 'design');}} checked={isTagSelected('design-civil', 'design')}  />
                        <label htmlFor="design-civil">
                            Civil
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="design-landscape-architecture" name="design-landscape-architecture" value="design-landscape-architecture" onChange={(e) => {setTags(e, 'design');}} checked={isTagSelected('design-landscape-architecture', 'design')}/>
                        <label htmlFor="design-landscape-architecture">
                            Landscape Architecture
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="design-architecture" name="design-architecture" value="design-architecture" onChange={(e) => {setTags(e, 'design');}}  checked={isTagSelected('design-architecture', 'design')}   />
                        <label htmlFor="design-architecture">
                            Architecture
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="design-structural" name="design-structural" value="design-structural" onChange={(e) => {setTags(e, 'design');}}  checked={isTagSelected('design-structural', 'design')}  />
                        <label htmlFor="design-structural">
                            Structural
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="design-mechanical" name="design-mechanical" value="design-mechanical" onChange={(e) => {setTags(e, 'design');}}  checked={isTagSelected('design-mechanical', 'design')}  />
                        <label htmlFor="design-mechanical">
                            Mechanical
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="design-electrical" name="design-electrical" value="design-electrical" onChange={(e) => {setTags(e, 'design');}}  checked={isTagSelected('design-electrical', 'design')}  />
                        <label htmlFor="design-electrical">
                            Electrical
                        </label>
                    </div>                                      
                    <div className="form-field checkbox">
                        <input type="checkbox" id="design-plumbing" name="design-plumbing" value="design-plumbing" onChange={(e) => {setTags(e, 'design');}}  checked={isTagSelected('design-plumbing', 'design')}  />
                        <label htmlFor="design-plumbing">
                            Plumbing
                        </label>
                    </div>   
                </fieldset>
                <fieldset className="border">
                    <legend>
                        {getSvg('construction')}
                        Construction
                    </legend>
                    <div className="form-field checkbox">
                        <input type="checkbox" id="construction-hardscape" name="construction-hardscape" value="construction-hardscape" onChange={(e) => {setTags(e, 'construction');}} checked={isTagSelected('construction-hardscape', 'construction')} />
                        <label htmlFor="construction-hardscape">
                        Hardscape
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="construction-landscape" name="construction-landscape" value="construction-landscape" onChange={(e) => {setTags(e, 'construction');}}  checked={isTagSelected('construction-landscape', 'construction')} />
                        <label htmlFor="construction-landscape">
                            Landscape
                        </label>
                    </div>
                    <div className="form-field checkbox">
                        <input type="checkbox" id="construction-crawlspace" name="construction-crawlspace" value="construction-crawlspace" onChange={(e) => {setTags(e, 'construction');}} checked={isTagSelected('construction-crawlspace', 'construction')}  />
                        <label htmlFor="construction-crawlspace">
                            Crawlspace/Basement
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="construction-floor" name="construction-floor" value="construction-floor" onChange={(e) => {setTags(e, 'construction');}}  checked={isTagSelected('construction-floor', 'construction')} />
                        <label htmlFor="construction-floor">
                            Floor
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="construction-wall" name="construction-wall" value="construction-wall" onChange={(e) => {setTags(e, 'construction');}}  checked={isTagSelected('construction-wall', 'construction')} />
                        <label htmlFor="construction-wall">
                            Wall
                        </label>
                    </div> 
                    <div className="form-field checkbox">
                        <input type="checkbox" id="construction-ceiling"  name="construction-ceiling" value="construction-ceiling" onChange={(e) => {setTags(e, 'construction');}}  checked={isTagSelected('construction-ceiling', 'construction')} />
                        <label htmlFor="construction-ceiling">
                            Ceiling
                        </label>
                    </div>
                    <div className="form-field checkbox">
                        <input type="checkbox" id="construction-attic" name="construction-attic" value="construction-attic" onChange={(e) => {setTags(e, 'construction');}} checked={isTagSelected('construction-attic', 'construction')} />
                        <label htmlFor="construction-attic">
                            Attic
                        </label>
                    </div>                                                                         
                    <div className="form-field checkbox">
                        <input type="checkbox" id="construction-roof"  name="construction-roof" value="construction-roof" onChange={(e) => {setTags(e, 'construction');}} checked={isTagSelected('construction-roof', 'construction')}  />
                        <label htmlFor="construction-roof">
                            Roof
                        </label>
                    </div>  
                </fieldset>
            </div>
        </>
    );
}

export default JobTags;