/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import Header from './Header';
import PageTitle from './PageTitle';
export default function DwgGallery() {
    const s3BucketPrefix = 'https://fazzad-projects.s3.us-west-1.amazonaws.com';
    const navigate = useNavigate();
    const [dwgPictures, setDwgPictures] = useState([]);
    const [packages, setPackages] = useState([]);
    const [showLoading, setShowLoading] = useState(true);
    const userId = sessionStorage.getItem('userId');
    const { packageName, leadtype } = useParams();
    const userType = sessionStorage.getItem('userType');

    useEffect(() => {
        if(sessionStorage.getItem('isPropertyOwner') === 'true'){
            document.body.classList.add('dark-mode');
        }else{
            document.body.classList.remove('dark-mode');
        }
        getPackageImage(packageName);
        getPackage(packageName);
    }, []);

    const getPackage = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackage`, {packageName: packageName}).then((response) => {
            if(response.data.length > 0){
                setPackages(response.data);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const goToPackageDetails = (packageName) => {
        if(parseInt(userType) === 500001){
            navigate(`/survdetails/${packageName}`);
        } else if (parseInt(userType) === 500002){
            navigate(`/prodetails/${packageName}`);
        } else {
            navigate(`/showdetails/${packageName}/${leadtype}`);
        }
    }

    const goToFeed = () => {
        if(parseInt(userType) === 500001){
            navigate(`/survey`);
        } else if (parseInt(userType) === 500002){
            navigate(`/profeed`);
        } else {
            navigate(`/property/${packageName}`);
        }
    }

    const getPackageImage = async (pkName) => {
        if(typeof packageName !== 'undefined'){
            try{
                const allImages = [];
                Axios.post(`${process.env.REACT_APP_LOCALHOST}/uniqueImage`, {packageName: pkName, pictureType: 'dwg-files'}).then((response) => {
                    if(response.data.length > 0){
                        
                        response.data.forEach(element => {
                            const fileNameArray = element.Key.split('/');
                            if(fileNameArray[2].trim() !== '' ){
                                allImages.push({
                                    compacted: `${s3BucketPrefix}/${element.Key}`,
                                    original: `${s3BucketPrefix}/${element.Key}`,
                                    imagePath: `${fileNameArray.join('/')}`,
                                });
                            }
                        });
                        setDwgPictures(allImages);
                       
                    } else {
                        setShowLoading(false);
                        setDwgPictures([]);
                    }
                }).catch(function (error) { setShowLoading(false);})
            }catch (err){}
        }
    }

    return(
        <div id="fazzad-site-container">
            <main id="fazzad-main">  
                <Header
                    mode={sessionStorage.getItem('isPropertyOwner') === 'true' ? 'homeonwer' : 'default'} 
                />
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                        {parseInt(userType) !== 500003 && sessionStorage.getItem('isPropertyOwner') === 'false' ?
                            <>
                            <li>
                                <a href='' onClick={(e) => { goToFeed(userId); } }>Home</a>
                                <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </li>
                            <li>
                            <a href='' onClick={(e) => { goToPackageDetails(packageName); } }>Package Details</a>
                                <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </li>
                            <li aria-current="page">
                                <span>CAD</span>
                            </li>
                            </>
                        :
                            <>
                            <li>
                                <a href='' onClick={(e) => { navigate(`/showdetails/${packageName}/0`); } }><small>Project</small></a>
                                <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </li>
                            <li>
                                <span>CAD</span>
                            </li>
                            </>
                        }   
                        </ol>
                    </nav>
                   
                    <PageTitle
                        headerText={""}
                        subHeaderText={packages.length > 0 ? `${packages[0].packageAddress}${packages[0].packageAddress2.length > 0 ? ` ${packages[0].packageAddress2},` : ','} ${packages[0].packageCity}, ${packages[0].packageState}, ${packages[0].packageZip}` : ''}
                        showSubHeader={true}
                    />
                    <div className="package-files-container">
                        {dwgPictures.length > 0 && (
                            <div className="cad-viewer">
                                {showLoading ?
                                    <div className='loading-screen'>
                                        <div className="demo-container">
                                            <p>Loading CAD viewer</p>
                                            <span className="loader loader--m"></span> 
                                        </div>
                                    </div>
                                : ''
                                }                                 
                                <div className="alert-container">
                                    <div className="alert alert--info">
                                        <div className="alert__content">
                                            <p className="alert__heading">This viewing experience is optimized for larger displays.</p>
                                        </div>
                                        <button type="button" className="button-tertiary button-s button-icon alert__close">
                                            <span className="icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <iframe onLoad={() => setShowLoading(false)} src={`//sharecad.org/cadframe/load?url=${dwgPictures[0]?.original}`}></iframe>  
                            </div>
                        )}
                    </div>
                </div>            
            </main>
        </div>
    )
}
