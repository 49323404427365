import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../libs/Header'
import Axios from 'axios';

export default function DeletePackages() {
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const handleGoToLogin = () =>  navigate('/login');
    const userId = sessionStorage.getItem('userId');
    const isAdmin = sessionStorage.getItem('isAdmin');
    const userType = sessionStorage.getItem('userType');
    const[packages, setPackages] = useState([]);
    const[singlePackage, setSinglePackage] = useState([]);
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
   
    useEffect(() => {
        if( isLoggedIn !==  'true'){
            handleGoToLogin();
        } else {
            getPackages();
        }
    }, []);

    const deletePackage = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deletePackage`, {packageName: singlePackage.packageName, userId: userId, packageAddress: `${singlePackage.packageAddress} ${singlePackage.packageAddress2.length > 0 ? singlePackage.packageAddress2 + ',' : ','} ${singlePackage.packageCity}, ${singlePackage.packageState} ${singlePackage.packageZip}` , packageId: singlePackage.packageId}).then((response) => {
            setShowDeleteModal(false);
            getPackages(userId);
        }).catch(function (error) {
            console.log('tags error = ',error);
        })
     }


    const deletePackages = (pck) => {
        setShowDeleteModal(true);
        setSinglePackage(pck);
        
     }
    const getPackages = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/allpackagesAdmin`, {isAdmin: isAdmin, userType: userType, userId: userId}).then((response) => {
            if(response.data.length > 0){
                console.log(response.data)
                setPackages(response.data);
            }
        }).catch(function (error) {
            console.log('retrieve packages error = ',error);
        })
    }

    return(
    <div>
        <div className='row full'>
            <Header />
            <div className='row'>                 
                <div className='col ten'>
                    <ul className='fz-breadcrumb'>
                        <li>
                            <a href='' onClick={(e) => {navigate(`/profeed`)}}>Home</a>
                            <span className='icon-right fz-divider'></span>
                        </li>
                        <li>
                            <a href='' onClick={(e) => {navigate(`/tools`)}}>Tools</a>
                            <span className='icon-right fz-divider'></span>
                        </li>
                        <li>Delete Packages</li>
                    </ul>
                    <div className='fz-header-title fz-container'>
                        <h1>Surveyor Packages Deletion</h1>
                    </div>
                    <div className='fixed-table line-rules block-xx'>
                        {packages.length > 0 ?
                            <div className='fixed-table-inner no-scroll'>
                                <table className='fz-small'>
                                    <tbody>
                                    { 
                                        packages.map((pck, index) => {
                                        return(
                                            <tr key={`tr_${index}`}>
                                                <td key={`td_${index}`} className="fz-break-all">
                                                    <a href=""  onClick={(e) => {navigate(`/prodetails/${pck.packageId}`)}}>{pck.packageAddress}{pck.packageAddress2.length > 0 ? ` ${pck.packageAddress2},` : ','} {pck.packageCity}, {pck.packageState} {pck.packageZip}</a>
                                                    <br />
                                                    <button 
                                                        type='button'                        
                                                        className='btn btn-secondary fz-btn-sm'
                                                        aria-label='Delete'
                                                        value='Delete'
                                                        onClick={() => { 
                                                            deletePackages(pck);
                                                        }}
                                                    >
                                                    Delete
                                                </button>
                                                </td>
                                            </tr>
                                        )}
                                    )}
                                    </tbody>
                                </table>
                                { showDeleteModal ?
                                    <div id='modal-success' className='fz-overlay'>
                                        <div className='fz-overlay-container'>
                                            <div className='modal-content'>
                                                <div className='block-xx'>
                                                    <h4 className='h2-like'>{process.env.REACT_APP_DELETE_PACKAGE_MESSAGE}</h4>
                                                </div>
                                                <div className='row'>
                                                    <div className='button-group fz-container fz-center block'>                    
                                                        <button 
                                                            type='button'
                                                            id="deletebtn"
                                                            className='btn fz-btn-med'
                                                            onClick={() => { deletePackage()}}
                                                        >  
                                                            Yes
                                                        </button>
                                                        <span className='file-input__files'></span>
                                                        <button 
                                                            type='reset'                        
                                                            aria-label='Exit'
                                                            value='Exit'
                                                            className='btn fz-btn-med btn-secondary'
                                                            onClick={() => { setShowDeleteModal(false)}}
                                                        >
                                                        Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : ''
                            }
                            </div>
                        : 
                            <div className="fz-alert alert-info">
                                <i className="icon-info"></i>                                
                                <strong>Info</strong>
                                <p>
                                    There are no packages available for deletion.
                                </p>
                            </div>
                        }
                    </div>
                    {/* <div id='tabpanel-2'>
                        {packages.length > 0 ?
                            <div className='fz-response-feed block-xx mcol six' style={{marginLeft: '0px'}}>
                                {packages.map((pck, index) => {  
                                    return(
                                        <div key={`div_main_${index}`} className='fz-response-feed block-xx mcol six' style={{marginLeft: '0px'}}>                                                    
                                            <div className='fz-response-container'>
                                                <span className='fz-response-wrap'>
                                                    <h2 className='h3-like'>
                                                        <span className='f600'>{pck.firstName} {pck.lastName}</span> · <span className='gray-500'>{pck.company}</span>
                                                    </h2>
                                                </span>                                        
                                                
                                                
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        : <div className="fz-alert alert-info">
                            <i className="icon-info"></i>                                
                            <strong>Info</strong>
                            <p>
                                You dont have any responses from Pros and Vendors yet.
                            </p>
                        </div>
                        }
                    </div>  */}
                </div>
            </div>
        </div>
    </div>
  )
}
