import React from 'react';
import getSvg from '../../utils/getSvg';
export default function JobTagsFilter({setShowFilters, filterText}) {
    return (
        <div className="button-group">
            <button 
                id="btn-filter"
                className="btn btn-secondary"
                type="button"
                value="filters"
                tabIndex="-1"
                onClick={(e) => {setShowFilters(true)}}
            >
                {getSvg('filters')}
                {filterText}
            </button> 
        </div> 
    )
};
