import React from "react";
import getSvg from "../../utils/getSvg";

export function Modal({ onClose, children, modalHeader = "" }) {

    return (
        <div className="modal-container">
            <div className="modal">
                <div className="modal__header">
                    <h2 className="modal-title h4">{modalHeader}</h2>
                    <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => { onClose(false) }}>
                        {getSvg('close')}
                    </button>
                </div>
                <div className='modal-content'>
                    {children}
                </div>
            </div>
        </div>
    )
}