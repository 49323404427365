import getSvg from "../../utils/getSvg";
import { useNavigate } from 'react-router-dom';

export const NotAllowed = () => {
    const navigate = useNavigate();

    return (
        <div className="modal-container">
            <div className="modal">
                <div className="modal__header">
                    <h2 className="modal-title h4 warning">You are not allowed to see this page.</h2>
                    <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => navigate('/')}>
                        {getSvg('close')}
                    </button>
                </div>
                <div className="modal__content">
                    <p>You are not allowed to view this page or this package.</p>
                </div>
            </div>
        </div>
    )
}