import React, {useRef, useEffect, useState} from 'react';
import getSvg from '../../utils/getSvg';
import DisplayUserInfo from './DisplayUserInfo';
import VideoWithLoading from "../../utils/VideoWithLoading";

function VideoComponent({
                            pck,
                            videoKey,
                            setShowDeleteModal,
                            videoType = 'media',
                            handleCopyToClipboard,
                            hasActions = false,
                            likeVideoFunc,
                            setSingleVideo,
                            index,
                            setSinglePackage,
                            packageInfo,
                            saveVideoStats,
                            handlePlayed,
                            showInfo = true,
                            isHomeOwner = false,
                            leadtype = 0,
                        }) {
    let videoPrefix = '';
    let videoSource = '';
    let videoThumbNailSource = '';
    let bucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const [showMore, setShowMore] = useState(false); // Changed to false initially
    const videoRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        const videoElement = videoRef.current;
        // Function to handle the full-screen change
        const handleFullScreenChange = () => {
            setIsFullScreen(document.fullscreenElement === videoElement);
        };

        // Adding event listener for full-screen change
        document.addEventListener('fullscreenchange', handleFullScreenChange);

        // Cleanup
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);
    if (parseInt(leadtype) === 0) {
        if (videoType === 'project' || videoType === 'package') {
            videoPrefix = 'pro-videos-library';
            videoSource = `${bucketPrefix}/${pck.packageName}/pro-videos-library/${pck.videoFileName}`;
            videoThumbNailSource = `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.packageName}/${videoPrefix}/${pck.videoFileName.replace('mp4', 'jpg')}`;
        } else if (videoType === 'standalone') {
            videoSource = pck.videoSource;
            videoThumbNailSource = pck.videThumbnail;
        } else {
            bucketPrefix = process.env.REACT_APP_S3_BUCKET_PROS_VIDEOS_PREFIX;
            videoPrefix = 'scope-of-work-videos';
            videoSource = `${bucketPrefix}/${pck.referenceNumber}/${pck.videoFileName}`;
            videoThumbNailSource = `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.referenceNumber}/${pck.videoFileName.replace('mp4', 'jpg')}`;
        }
    } else {
        videoSource = `${bucketPrefix}/${pck.videoFileName}`;
        videoThumbNailSource = `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.videoFileName.replace('mp4', 'jpg')}`;
    }

    const getPackageDescription = (packageDesc) => {
        if (!showMore && packageDesc.length > 200) {
            return packageDesc.substring(0, 200) + '...';
        } else {
            return packageDesc;
        }
    };

    const handleReadMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className="fz-drop rounded" key={`main_div_${videoKey}`}>
            <div className="video" key={`video_${videoKey}`}>
                {/*<video*/}
                {/*    className={`rounded ${isFullScreen ? 'video-mode-contain' : ''}`}*/}
                {/*    src={videoSource}*/}
                {/*    poster={videoThumbNailSource}*/}
                {/*    controls*/}
                {/*    ref={videoRef}*/}
                {/*    onPlay={(e) => {*/}
                {/*        handlePlayed(packageInfo);*/}
                {/*        saveVideoStats(*/}
                {/*            pck,*/}
                {/*            `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX}/${pck.packageName}/${videoPrefix}/${pck.videoFileName}`*/}
                {/*        );*/}
                {/*    }}*/}
                {/*/>*/}

                <VideoWithLoading
                    videoSource={videoSource}
                    packageInfo={packageInfo}
                    videoPrefix={videoPrefix}
                    videoThumbNailSource={videoThumbNailSource}
                    pck={pck}
                    isFullScreen={isFullScreen}
                    handlePlayed={handlePlayed}
                    saveVideoStats={saveVideoStats}
                />

            </div>
            <div className="card">
                <div className="content">
                    {showInfo && (
                        <div className="info">
                            {videoType === 'project' || videoType === 'package' ? (
                                <DisplayUserInfo
                                    pck={pck}
                                    handleCopyToClipboard={handleCopyToClipboard}
                                    linkToProfile={true}
                                    packageName={pck.packageName}
                                    isHomeOwner={isHomeOwner}
                                    leadtype={leadtype}
                                />
                            ) : (
                                <>
                                    <p>
                                        <strong>{pck.projectName}</strong>
                                    </p>
                                    <br/>
                                    {/*<h3 className="h5">{pck.projectName}</h3>*/}
                                    <div className="package-scope">
                                        <p className="preformatted-text">
                                            {getPackageDescription(pck.projectDescription)}

                                            {pck.projectDescription.length > 200 && (
                                                <>
                                                    <br/>
                                                    <a
                                                        href="/"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleReadMore();
                                                            e.target.blur();
                                                        }}
                                                    >
                                                        {showMore ? 'Read less' : 'Read more'}
                                                    </a>
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {hasActions && (
                        <>
                            {videoType === 'media' && (
                                <div className="actions">
                                    <a
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSingleVideo(pck);
                                            setShowDeleteModal(true);
                                        }}
                                        className="icon-black button button-s button-destructive button-icon delete"
                                        title="Delete"
                                    >
                                        {getSvg('delete')}
                                    </a>
                                </div>
                            )}
                            {videoType === 'project' && (
                                <div className="actions">
                                    {leadtype !== 1 && (
                                        <a
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                likeVideoFunc(pck, index);
                                                e.target.blur();
                                            }}
                                            title={`${pck.homeOwnerLikedVideo === 1 ? 'Dislike' : 'Like'} this response`}
                                            className={`button button-s button-icon like ${
                                                pck.homeOwnerLikedVideo === 1 ? 'thumb-like' : ''
                                            }`}
                                        >
                                            {getSvg('like')}
                                        </a>
                                    )}
                                    <a
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSinglePackage(pck, index);
                                            setShowDeleteModal(true);
                                        }}
                                        className="button button-s button-destructive button-icon delete"
                                        title="Delete this response"
                                    >
                                        {getSvg('delete')}
                                    </a>
                                </div>
                            )}
                            {videoType === 'package' && (
                                <div className="actions">
                                    <a
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            likeVideoFunc(pck, index);
                                            e.target.blur();
                                        }}
                                        title={`${pck.homeOwnerLikedVideo === 1 ? 'Dislike' : 'Like'} this response`}
                                        className={`button button-s button-icon like ${
                                            pck.homeOwnerLikedVideo === 1 ? 'thumb-like' : ''
                                        }`}
                                    >
                                        {getSvg('like')}
                                    </a>
                                    <a
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowDeleteModal(true);
                                        }}
                                        className="button button-s button-destructive button-icon delete"
                                        title="Delete this response"
                                    >
                                        {getSvg('delete')}
                                    </a>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default VideoComponent;
