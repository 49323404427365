import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../libs/Header'
import Axios from 'axios';

export default function DeleteVideoSubmissions() {
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const handleGoToLogin = () =>  navigate('/login');
    const userId = sessionStorage.getItem('userId');
    const isAdmin = sessionStorage.getItem('isAdmin');
    const[packages, setPackages] = useState([]);
    const[singlePackage, setSinglePackage] = useState([]);
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_BUCKET_VIDEOS_PREFIX;
   
    useEffect(() => {
        if( isLoggedIn !==  'true'){
            handleGoToLogin();
        } else {
            getPackages();
        }
    }, []);

    const deleteVideoSubmission = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteVideosSubmissions`, {
            packageId: singlePackage.packageId,
            userId: userId,
            packageName: singlePackage.packageName,
            packageAddress: `${singlePackage.packageAddress}${singlePackage.packageAddress2.length > 0 ? ` ${singlePackage.packageAddress2},` : ','} ${singlePackage.packageCity}, ${singlePackage.packageState} ${singlePackage.packageZip}`
        }).then((response) => {
            setShowDeleteModal(false);
            getPackages();
        }).catch(function (error) {
            console.log('tags error = ',error);
        })
     }

    const deletePackage = (pck) => {
        setShowDeleteModal(true);
        setSinglePackage(pck);
    }

    const getPackages = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackagesSubmission`, {isAdmin: isAdmin}).then((response) => {
            if(response.data.length > 0){
                console.log(response.data)
                setPackages(response.data);
            }
        }).catch(function (error) {
            console.log('retrieve packages error = ',error);
        })
    }

    return(
    <div>
        <div className='row full'>
            <Header />
            <div className='row'>                 
                <div className='col ten'>
                    <ul className='fz-breadcrumb'>
                        <li>
                            <a href='' onClick={(e) => {navigate(`/profeed`)}}>Home</a>
                            <span className='icon-right fz-divider'></span>
                        </li>
                        <li>
                            <a href='' onClick={(e) => {navigate(`/tools`)}}>Tools</a>
                            <span className='icon-right fz-divider'></span>
                        </li>
                        <li>Delete Videos Submissions</li>
                    </ul>
                    {isAdmin.toString() === '1' ?
                        <>
                            <div className='fz-header-title fz-container'>
                                <h1>Pro Videos Submissions Deletion</h1>
                            </div>
                            <div className='fixed-table line-rules block-xx'>
                                {packages.length > 0 ?
                                    <div className='fixed-table-inner no-scroll'>
                                        {packages.map((pck, index) => {
                                            return (
                                                <div key={`div_main_${index}`} className='fz-response-feed block-xx mcol six' style={{ marginLeft: '0px' }}>
                                                    <div className='fz-response-container'>
                                                        <p>
                                                            {pck.packageAddress} {pck.packageAddress2.length > 0 ? pck.packageAddress2 + ',' : ''}
                                                            {pck.packageCity}, {pck.packageState} {pck.packageZip}
                                                        </p>

                                                        <video className='fz-container-small fz-video-small block-xx' controls>
                                                            <source src={`${s3BucketVideoPrefix}/${pck.packageName}/pro-videos/${pck.videoFileName}`} type='video/mp4' />
                                                        </video>
                                                        <div className='row'>
                                                            <div className='fz-container fz-center block-xx'>
                                                                <button
                                                                    type='button'
                                                                    id="deletebtn"
                                                                    className='btn btn-secondary fz-btn-med'
                                                                    onClick={() => { deletePackage(pck); } }
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        {showDeleteModal ?
                                            <div id='modal-success' className='fz-overlay'>
                                                <div className='fz-overlay-container'>
                                                    <div className='modal-content'>
                                                        <div className='block-xx'>
                                                            <h4 className='h2-like'>{process.env.REACT_APP_DELETE_PACKAGE_MESSAGE}</h4>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='button-group fz-container fz-center block'>
                                                                <button
                                                                    type='button'
                                                                    id="deletebtn"
                                                                    className='btn fz-btn-med'
                                                                    onClick={() => { deleteVideoSubmission(); } }
                                                                >
                                                                    Yes
                                                                </button>
                                                                <span className='file-input__files'></span>
                                                                <button
                                                                    type='reset'
                                                                    aria-label='Exit'
                                                                    value='Exit'
                                                                    className='btn fz-btn-med btn-secondary'
                                                                    onClick={() => { setShowDeleteModal(false); } }
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                    : 
                                        <div className="fz-alert alert-info">
                                            <i className="icon-info"></i>                                
                                            <strong>Info</strong>
                                            <p>
                                                There are no submitted packages available for deletion.
                                            </p>
                                        </div>
                                    }
                            </div>
                        </>
                    :   
                        <span>
                            <br />
                            <div className="fz-alert alert-info">
                                <i className="icon-info"></i>
                                <strong>Info</strong>
                                <p>You are not authorize to view this page.</p>
                            </div>
                        </span>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
