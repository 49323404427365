import React, { useState, useEffect } from "react";
import Header from '../libs/Header'
import Axios from 'axios';

function RejectedUsers() {
    const [rejectedAccounts, setRejectedAccounts] = useState([]);
    useEffect(() => {
        getRejectedAccounts();
    }, []);

    const formatPhoneNumber = (phone) => {
        const cleaned = ('' + phone).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return(['(', match[2], ') ', match[3], '-', match[4]].join(''));
        }
    }

    const getRejectedAccounts = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/rejectedusers`).then((response) => {
            setRejectedAccounts(response.data);
        }).catch(function (error) {
            console.log('error getting free accts = ',error);
        })
    }

    return (
        <div className='row full'>
            <Header />
            <div className='row'>                 
                <div className='col ten'>
                    <br />
                    {rejectedAccounts.length > 0 ?
                        <div className='fz-response-feed block-xx mcol six'>
                            <div className='fz-header-title fz-container'>
                                <h1>Rejected Accounts</h1>
                            </div>
                            {rejectedAccounts.map((acct, index) => {
                                return(
                                    <div key={`div_main_${index}`} className='fz-response-feed block-xx' style={{marginLeft: '0px'}}>                                                    
                                        <div className='fz-response-container'>
                                            <span className='fz-response-wrap'>
                                                <h2>{acct.company}</h2>
                                                <div>{acct.firstName}  {acct.lastName}</div>
                                                <div>License: {acct.license} - {formatPhoneNumber(acct.phone)}</div>
                                                <div>{acct.address}{acct.address2.length > 0 ? ` ${acct.address2},` : ','} {acct.city}, {acct.state} {acct.zip}</div>
                                                <br />
                                                <div><strong>Reason: {acct.rejectionReason}</strong></div>
                                            </span>  
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    : 
                    <div className='block'>
                        <div className="fz-alert alert-success">
                            <i className="icon-success"></i>                                
                            <strong>Info</strong>
                            <p>No accounts have been rejected</p>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default RejectedUsers;
