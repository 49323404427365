/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { downloadThePackage } from '../../utils/downloadPackage';
import { getPackageInfo, getPackageImageInfo, getPackageInfoPublic, getPackageImageInfoPublic } from '../../utils/getPackageInfo';
import { useNavigate, useParams } from 'react-router-dom';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import checkForMobile from '../../utils/checkForDeviceType';
import CustomHeader from '../libs/CustomHeader'
import iconCad from '../../images/icon-cad.svg';
import icon360 from '../../images/icon-360.svg';
import iconPictures from '../../images/icon-gallery.svg';
import iconDrone from '../../images/icon-drone.svg';
import iconPdf from '../../images/icon-pdf.svg';
import PackageDetails from '../libs/PackageDetails';
import ModalInfo from '../libs/ModalInfo';
import NoCoverImage from '../../images/NoCoverScopeImage.png';
import {useGetPdfsAndPlanAndPictures} from "../libs/plan-and-picture/hooks/usePlanAndPicture";

export default function SurveyorPackageDetails() {
    const navigate = useNavigate();
    const { packageName, leadtype } = useParams();
    const [scopeOfWorkVideo, setScopeOfWorkVideo] = useState('');
    const [has360Pictures, setHas360Pictures] = useState(false);
    const [hasDronePictures, setHasDronePictures] = useState(false);
    const [hasDwgPictures, setHasDwgPictures] = useState(false);
    const [hasFiles, setHasFiles] = useState(false);
    const [hasPdfs, setHasPdfs] = useState(false);
    const [hasNoPackage, setHasNoPackage] = useState(false);
    const [hasStillPictures, setHasStillPictures] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [numberOfViews, setNumberOfViews] = useState(0);
    const [packages, setPackages] = useState([]);
    const [scopeOfWorkThumbnail, setScopeOfWorkVideoThumbnail] = useState('');
    const [showMore, setShowMore] = useState({});
    const { planAndPictures, loading } = useGetPdfsAndPlanAndPictures(packageName);

    sessionStorage.setItem('isPropertyOwner', true);
    useEffect(() => {
        setIsMobile(checkForMobile());

        getPackage(packageName);
        document.body.classList.add('dark-mode');
        return () => {
            document.body.classList.remove('dark-mode');
        };
    }, []);

    const handleReadMore = (index) => {
        setShowMore({ ...showMore, [index]: !showMore[index] });
    }

    const goTopage = (type, pageNameTitle, packageName) => {
        if (type === 'survey') {
            navigate(`/profeed`);
        } else if (pageNameTitle === 'pdf') {
            navigate(`/pdf/${packageName}/${leadtype}`)
        } else if (pageNameTitle === 'dwg') {
            navigate(`/dwg/${packageName}/${leadtype}`)
        } else if (pageNameTitle === 'still') {
            navigate(`/galleries/${packageName}/still-pictures/${leadtype}`)
        } else if (pageNameTitle === '360') {
            navigate(`/galleries/${packageName}/three-sixty-pictures/${leadtype}`)
        } else {
            navigate(`/galleries/${packageName}/${type}/${leadtype}`)
            // navigate(`/gallery/${packageName}/${type}/${leadtype}`);
        }
    }
        ;

    const getPackageViews = (packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getSurveyorPackagesStats`, { packageName: packageName }).then((response) => {
            setNumberOfViews(response.data.length)
        }).catch(function (error) {
            console.log('download package error = ', error);
        })
    }

    const getPackage = async (packageName) => {
        const packageInfo = await getPackageInfoPublic(packageName);
        if (packageInfo.response) {
            setHasFiles(packageInfo.hasFiles);
            setHas360Pictures(packageInfo.has360Pictures);
            setHasDronePictures(packageInfo.hasDronePictures);
            setHasDwgPictures(packageInfo.hasDwg);
            setHasPdfs(packageInfo.hasPdfs);
            setHasStillPictures(packageInfo.hasStillPictures);
            setPackages(packageInfo.response);
            getPackageImage(packageInfo.response[0]);
            getPackageViews(packageName);
        } else {
            setHasNoPackage(true);
        }
    }

    const getPackageImage = async (pck) => {
        const packageImageInfo = await getPackageImageInfoPublic(pck);
        if (typeof pck.packageName !== 'undefined') {
            if (typeof packageImageInfo.response[0].Key !== 'undefined') {
                setScopeOfWorkVideoThumbnail(packageImageInfo.scopeOfWorkVideoThumbnail);
                setScopeOfWorkVideo(packageImageInfo.scopeOfWorkVideo);
                setHasVideo(packageImageInfo.hasVideo);
            } else {
                setHasVideo(false);
            }
        }
    }

    const downloadPackage = async (pckId) => {
        setIsDownloading(true);
        try {
            await downloadThePackage(pckId);
            setIsDownloading(false);
        } catch (e) {
            setIsDownloading(false);
            console.error(`Download package error: ${e}`);
        }
    }

    const getPackageDescription = (packageDesc, index) => {
        if (!showMore[index]) {
            if (packageDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH) {
                return `${packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH).trimRight()}... `;
            } else {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
            }
        } else {
            return packageDesc;
        }
    }

    return (
        <div id="fazzad-site-container">
            <CustomHeader
                packageName={packageName}
                leadtype={'none'}
            />
            <main id="fazzad-main">
                <div id="app-container">
                    {packages.length > 0 && (
                        <PackageDetails
                            currentPage={'property owner details'}
                            downloadPackage={downloadPackage}
                            filesText={'Files'}
                            getPackageDescription={getPackageDescription}
                            goTopage={goTopage}
                            handleReadMore={handleReadMore}
                            has360Pictures={has360Pictures}
                            hasDronePictures={hasDronePictures}
                            hasDwgPictures={hasDwgPictures}
                            hasFiles={hasFiles}
                            hasPdfs={hasPdfs}
                            hasStillPictures={hasStillPictures}
                            hasVideo={hasVideo}
                            headerTitle={'Package Details'}
                            iconPdf={iconPdf}
                            iconPictures={iconPictures}
                            icon360={icon360}
                            iconDrone={iconDrone}
                            iconCad={iconCad}
                            leadtype={leadtype}
                            NoCoverImage={NoCoverImage}
                            packages={packages}
                            packageDescriptionHeader={'Scope of work'}
                            packageName={packageName}
                            scopeOfWorkVideo={scopeOfWorkVideo}
                            scopeOfWorkThumbnail={scopeOfWorkThumbnail}
                            showContactHomeOnwerButton={false}
                            showDeletePackageButton={false}
                            showDownloadPackageButton={true}
                            showHeaderButtons={true}
                            showMore={showMore}
                            toggleCreditCardModal={null}
                            userType={500003}
                            userId={999999}
                            videoName={scopeOfWorkVideo}
                            videoText={'Scope of work video'}
                            planAndPictures={planAndPictures}
                        />
                    )}


                    {hasNoPackage ?
                        <AlertMessage
                            messageHeader={"No package found."}
                            messageBody={""}
                            startDate={null}
                            setShowWarningContainerMessage={''}
                            messageType={"informational"}
                            showButton={false}
                        />
                        : ''
                    }
                    {isDownloading ?
                        <ModalInfo
                            messageHeader={"Downloading your package..."}
                            messageBody={`Files will be saved on your ${isMobile ? 'mobile device' : 'computer'}.`}
                            messageType={"informational"}
                            showCloseButton={false}
                            showLoader={true}
                        />
                        : ''
                    }
                </div>
                {/* <div className="package-detail-container">
                    <div className="content">
                        <section id="package-detail-views">
                            <h2 className="h5">Views</h2>
                            <p>{numberOfViews}</p>
                        </section>
                    </div> */}
            </main>
        </div>
    )
}
