import React from 'react';
import getSvg from '../../utils/getSvg';
function UploadSection({header, subHeader, divId, fileType, selectedFiles, errorFileUploaded, handleFilesChange, getWrongFileFormatMessage, isMultiple, index, showLegend=true}) {
    let uploadType = 'file';
    if(fileType === 'scopeofwork') {
        uploadType = 'video';
    }
    return(
        <>
        <h2 className="h4"><span dangerouslySetInnerHTML={{ __html: `${header} ${subHeader}` }} /></h2>
        <fieldset key={`fildSet_${index}`}>
            {showLegend && (<legend key={`legend_${index}`}>Upload {uploadType}{isMultiple ? 's' : ''}</legend>)}
            <div className={`form-field file-input ${errorFileUploaded.length > 0 ? 'error' : ''}`} key={`div_${index}`}>
                <input type="file" id={divId} className="file-input__input" multiple={isMultiple} onChange={(e) => handleFilesChange(e, fileType)} />
                <label htmlFor={divId} className="button button-m button-tertiary fz-block-sm">
                    {getSvg('upload', `span_${index}`)}
                    <span className="text"  key={`span_choose_file_${index}`}>Choose {uploadType}{isMultiple ? 's' : ''}&hellip;</span>
                </label>
                {selectedFiles.length > 0 ?
                    <div className="file-input__filelist">
                       <small>{selectedFiles.length} file{selectedFiles.length > 1 ? 's' : ''}</small>
                    </div>
                : ''
                }
                { errorFileUploaded.length > 0 && (
                    <p className="validation-message">{getWrongFileFormatMessage(fileType)}</p>
                )}
            </div>
        </fieldset>
        </>
    )
}
export default UploadSection;