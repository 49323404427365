import React from 'react';
export default function ShowPackageDescription({pck, headerText, textLength, showReadMore, showMore, getPackageDescription, handleReadMore}) {
  return (
    <>
        <h3 className="h5">{headerText}</h3>
        <div className="package-scope">
            <p className="preformatted-text">
                {getPackageDescription(pck.scopeOfWork, pck.id)}
                
                {showReadMore ?
                    pck.scopeOfWork.length > textLength && (
                        <>
                            <br />
                            {' '}<a href="/" onClick={(e) => { e.preventDefault(); handleReadMore(pck.id); e.target.blur();}}>
                            {showMore[pck.id] ? 'Read less' : 'Read more'}
                            </a>
                        </>
                    )
                : ''
                }
            </p>
        </div>
    </>
  )
};
