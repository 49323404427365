import React, {useState, useEffect, useRef} from "react";
import {cleanMediaUrl} from '../../utils/clearMediaUrl';
import {copyToClipBoard} from "../../utils/copyToClipBoard";
import {S3} from '@aws-sdk/client-s3';
import {useNavigate} from 'react-router-dom';
import {Upload} from '@aws-sdk/lib-storage';
import AlertMessage from '../libs/AlertMessage';
import getSvg from "../../utils/getSvg";
import Axios from 'axios';
import checkUserProfile from '../../utils/checkUserProfile';
import CityFilter from "../libs/CityFilter";
import Header from '../libs/Header'
import LeadsOptions from "../libs/LeadsOptions";
import ModalInfo from '../libs/ModalInfo';
import PageTitle from "../libs/PageTitle";
import StateFilter from "../libs/StateFilter";
import Tabs from '../libs/Tabs';
import * as formValidator from "../../utils/formValidation";
import {uploadWithSignedUrl} from '../../utils/awsHelpers';
import EmptyProMedia from "./EmptyProMedia";
import NoCoverImage from "../../images/NoCoverScopeImage.png";

window.Buffer = window.Buffer || require("buffer").Buffer;

function ProFeedFree() {
    const videoRef = useRef(null);
    const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
    const [fileProgressSelfVideo, setFileProgressSelfVideo] = useState(0);
    const [fileSelectedName, setFileSelectedName] = useState('');
    const [freeVideos, setFreeVideos] = useState([]);
    const [hasVideoName, setHasVideoName] = useState(true);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [hasVideoDescription, setHasVideoDescription] = useState(true);
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [packageStates, setPackageStates] = useState([]);
    const [packageCities, setPackageCities] = useState([]);
    const [payedLeadsInital, setPayedLeadsInitial] = useState([]);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showMore, setShowMore] = useState({});
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showFormErrorMessage, setShowFormErrorMessage] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [singleFreeVideo, setSingleFreeVideo] = useState([]);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [showProReq, setShowProReq] = useState(false);
    const [videoDescription, setVideoDescription] = useState('');
    const [videoName, setVideoName] = useState('');
    const [isTooLongVideo, setIsTooLongVideo] = useState(false);

    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const userId = sessionStorage.getItem('userId');
    const userType = parseInt(sessionStorage.getItem('userType'));
    const userTypeToBeChecked = JSON.parse(process.env.REACT_APP_PROS_USER_TYPE_TO_BE_CHECKED);
    const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    sessionStorage.setItem('isPropertyOwner', 'false');
    const navigate = useNavigate();
    const handleGoToLogin = () => navigate('/login');
    let mime = '';
    const rd = new FileReader();
    let uploadFileName = '';
    const config = {
        bucketName: process.env.REACT_APP_PROJECT_VIDEO_SOURCE,
        region: process.env.REACT_APP_REGION,
    };

    useEffect(() => {
        checkLogin();
    }, []);

    useEffect(() => {
        filterCityStateData();
    }, [selectedCity, selectedState]);

    const filterCityStateData = () => {
        let filteredData = payedLeadsInital; // Start with the initial data

        // Filter by selected city, if applicable
        if (selectedCity && selectedCity !== 'All') {
            filteredData = filteredData.filter(item => item.city === selectedCity);
            setPackageStates([...new Set(filteredData.map(item => item.state))])
        } else {
            setPackageStates([...new Set(payedLeadsInital.map(item => item.state))])
        }

        // Filter by selected state, if applicable
        if (selectedState && selectedState !== 'All') {
            filteredData = filteredData.filter(item => item.state === selectedState);
            setPackageCities([...new Set(filteredData.map(item => item.city))]);
        } else {
            setPackageCities([...new Set(payedLeadsInital.map(item => item.city))]);
        }

        setFreeVideos(filteredData); // Update the displayed data
    };

    const handleCopyToClipboard = (textToCopy) => {
        copyToClipBoard(textToCopy);
    };

    const checkVideoName = (videoName) => {
        if (videoName.trim().length === 0) {
            setHasVideoName(false);
        } else {
            setHasVideoName(true);
        }
    }

    const checkVideoDescription = (videoDescription) => {
        if (videoDescription.trim().length === 0) {
            setHasVideoDescription(false);
        } else {
            setHasVideoDescription(true);
        }
    }

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const {isGoodToGo} = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (isGoodToGo) {
            getFreeLeads();
        } else {
            setShowNotAllowed(true);
        }
    }

    const checkLogin = () => {
        if (isLoggedIn === 'true') {
            getUserProfile(userId, userType, userTypeToBeChecked);
        } else {
            sessionStorage.setItem('pageCameFrom', 'profeed');
            handleGoToLogin();
        }
    }

    const getFreeLeads = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/allfreepackages`).then((response) => {
            setFreeVideos(response.data);
            setPackageStates([...new Set(response.data.map(item => item.state))]);
            setPackageCities([...new Set(response.data.map(item => item.city))]);
            setPayedLeadsInitial(response.data);
        }).catch(function (error) {
            console.log('error getting free videos = ', error);
        })
    }

    const saveVideoUploaded = (uploadFileName, uuids, uploadType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveFreeLeadVideosResponse`, {
            userId: userId,
            leadId: singleFreeVideo.id,
            uploadFileName: uploadFileName,
            packageName: uuids,
            singleVideo: singleFreeVideo,
            uploadType: uploadType
        }).then((response) => {
            setShowUploadVideo(false);
            setShowProcessing(false);
            setShowConfirmation(true);
        }).catch(function (error) {
            setShowProcessing(false);
            setShowUploadVideo(false);
            console.log('saveVideoUploaded error = ', error);
        })
    }

    const changeHandlerUploads = (event) => {
        event.preventDefault();
        const chosenFile = event.target.files[0].name;
        setFileSelectedName(chosenFile);
        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidVideo(false);
        } else {
            setIsValidVideo(true);
            let file = event.target.files[0];
            const fileNameArray = file.name.split('.');
            setSelectedUploads(event.target.files);
            if (fileNameArray[fileNameArray.length - 1].toLowerCase() === 'mp4') {
                mime = file.type;
                rd.onload = function (e) {
                    let blob = new Blob([e.target.result], {type: mime}),
                        url = URL.createObjectURL(blob), // create o-URL of blob
                        video = document.createElement("video");
                    video.preload = "metadata";
                    video.addEventListener("loadedmetadata", function () {
                        (URL).revokeObjectURL(url);
                    });
                    video.src = url;
                };
                rd.readAsArrayBuffer(file);
            }

            const video = document.createElement('video');
            video.src = window.URL.createObjectURL(file);
            video.addEventListener('loadedmetadata', () => {
                setShowErrorMessage(false);

                const videoDurationInSeconds = video.duration;
                const videoDurationInMinutes = videoDurationInSeconds / 60;

                if (videoDurationInMinutes > 15) {
                    setIsTooLongVideo(true);
                    return;
                }

                setIsTooLongVideo(false);
                setSelectedUploads(event.target.files);
            });

            videoRef.current.blur();

        }
    }

    const getPackageDescription = (packageDesc, index) => {
        if (!showMore[index]) {
            if (packageDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH) {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH) + '...';
            } else {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
            }
        } else {
            return packageDesc;
        }
    }

    const handleReadMore = (index) => {
        setShowMore({...showMore, [index]: !showMore[index]});
    }

    const handleUpload = async () => {

        if (isTooLongVideo) return;

        try {
            // if(selectedUploads.length > 0 && videoName.trim() !== '' && videoDescription.trim() !== ''){
            const filesArray = [...selectedUploads];
            setUploadStarted(false);
            setFileProgressSelfVideo(0);
            setShowErrorMessage(false);
            await Promise.all(
                filesArray.map(async (file) => {
                    uploadFileName = cleanMediaUrl(file.name);
                    uploadFileName = `${userId}/video-library/${uploadFileName}`;
                    const key = `${uploadFileName}`;

                    setUploadStarted(true);
                    await uploadWithSignedUrl(file, config.bucketName, key, (myTotal) => {
                        setFileProgressSelfVideo(myTotal);
                        if (parseInt(myTotal) === 100) {
                            setShowConfirmation(true);
                            setShowProcessing(true);
                        }
                    });

                    setTimeout(() => {
                        setUploadStarted(false);
                        setShowProcessing(false);
                        setShowUploadVideo(false);
                        // setVideoName('');
                        // setVideoDescription('');
                        saveVideoUploaded(uploadFileName, singleFreeVideo.packageName, 'upload');
                    }, process.env.REACT_APP_TIEMOUT);
                })
            );
            // } else {
            //     setShowErrorMessage(true);
            //     setShowProcessing(false);
            // } 
        } catch (e) {
            console.error(`Uploading Videos file error: ${e}`);
            setUploadStarted(false);
            setShowProcessing(false);
            setShowUploadVideo(false);
        }
    };

    const handleAlertButtonClick = () => {
        setShowFormErrorMessage(false);
    };

    const getVideoAddress = (video, type) => {
        let address = '';
        if (video.address1 !== '') {
            address = `${video.address}, `;
        }
        if (video.address2 !== '') {
            address = `${address}${video.address2}, `;
        }
        if (type !== 'copy') {
            address = `${address}<br />`;
        } else {
            address = `${address} `;
        }
        if (video.city !== '') {
            address = `${address}${video.city}, `;
        }
        if (video.state !== '') {
            address = `${address}${video.state}, `;
        }
        if (video.zip !== '') {
            address = `${address}${video.zip}`;
        }
        return address;
    }

    return (
        <div id="fazzad-site-container">
            <Header/>
            <main id="fazzad-main">
                <div id="app-container">
                    <section>
                        <Tabs
                            userType={userType}
                            setShowProReq={null}
                            currentTab={'leads'}
                        />
                        <PageTitle
                            headerText={"Free Leads"}
                            subHeaderText={"Leads uploaded by property owners."}
                            showSubHeader={true}
                        />
                        <fieldset className="border homefeed--interactive">
                            <legend>Filters</legend>
                            <div className="homefeed--details">
                                <LeadsOptions
                                    option1={"Paid leads"}
                                    option2={"Free leads"}
                                    navigateTo={"profeed"}
                                    filtersText={"Filters"}
                                    showFilters={false}
                                    paidChecked={false}
                                    freeChecked={true}
                                />
                                <div className="homefeed--details__filters">
                                    <CityFilter
                                        setSelectedCity={setSelectedCity}
                                        selectedCity={selectedCity}
                                        citiesArray={packageCities}
                                    />
                                    <StateFilter
                                        setSelectedState={setSelectedState}
                                        selectedState={selectedState}
                                        statesArray={packageStates}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        {freeVideos.length === 0 && (
                            // <AlertMessage
                            //     messageHeader={"No free leads available."}
                            //     messageBody={""}
                            //     startDate={null}
                            //     setShowWarningContainerMessage={''}
                            //     messageType={"informational"}
                            //     showButton={false}
                            // />

                            <EmptyProMedia
                                proMediaType={'free_leads'}
                                title={'Free leads feed'}
                                description={'Explore project leads from Property Owners on Fazzad.com. Click the \'Contact Property Owners\' button to reach out and gather more information about the opportunities that interest you.'}
                                typeName={'Free leads'}
                            />
                        )}
                        <div id="po-container" className="pro-freelead">
                            <div id="po-feed-container" className="reel-view">
                                {freeVideos.map((video, index) => {
                                    return (
                                        <div className="file-group" key={`file_group_${index}`}>
                                            {/*<div className="file-container fz-drop rounded" key={`file_container_${index}`}>*/}
                                            {/*<img*/}
                                            {/*    className="file-preview rounded"*/}
                                            {/*    src={`${s3BucketVideoPrefix}/${video.packageName}/free-lead-image/${video.videoFileName.replace('mp4', 'png')}`}*/}
                                            {/*    onError={(e) => e.target.src = NoCoverImage}*/}
                                            {/*    alt="Project Picture"*/}
                                            {/*    key={`img_${index}`}*/}
                                            {/*/>*/}
                                            {/*</div>*/}
                                            <div className="card">
                                                <h2 className="h4">{video.firstName} {video.lastName.substring(0, 1).toUpperCase()}.</h2>
                                                <strong>Scope of work</strong>
                                                <p className='preserve-html'>{getPackageDescription(video.projectDescription, video.id)}
                                                    {video.projectDescription.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH && (
                                                        <>
                                                            <br/>
                                                            <a href="/" onClick={(e) => {
                                                                e.preventDefault();
                                                                handleReadMore(video.id);
                                                                e.target.blur();
                                                            }}>
                                                                {showMore[video.id] ? 'Read less' : 'Read more'}
                                                            </a>
                                                        </>
                                                    )}
                                                </p>
                                                <div className="fz-block-sm">
                                                    <div className="fz-white-space">
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: getVideoAddress(video, 'show')}}/>&nbsp;
                                                        <button
                                                            title="Copy!"
                                                            className="button button-icon button-s"
                                                            onClick={() => handleCopyToClipboard(getVideoAddress(video, 'copy'))}
                                                        >
                                                            {getSvg('copy')}
                                                        </button>
                                                    </div>
                                                    <span>
                                                        {video.phone}&nbsp;
                                                        <button title="Copy!" className="button button-icon button-s"
                                                                onClick={() => handleCopyToClipboard(video.phone)}>
                                                            {getSvg('copy')}
                                                        </button>
                                                    </span><br/>
                                                    <span>
                                                        {video.email}&nbsp;
                                                        <button title="Copy!"
                                                                className="button button-icon icon-black button-s"
                                                                onClick={() => handleCopyToClipboard(video.email)}>
                                                            {getSvg('copy')}
                                                        </button>
                                                    </span>
                                                </div>
                                                <div className="button-group">
                                                    <button className="button-m button-primary" onClick={() => {
                                                        setSingleFreeVideo(video);
                                                        setShowUploadVideo(true)
                                                    }}>
                                                        {getSvg('person')}
                                                        <span className="text">Contact Property Owner</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            {showProReq && (
                <div id="modal-1" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 warning">This feature is for Premium customers only.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={(e) => {
                                        setShowProReq(false)
                                    }}>
                                {getSvg('close')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showConfirmation && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__content">
                            <p>Success! Your response was sent to the Property Owner via email.</p>
                            <div className='button-group fz-center block-xx'>
                                <button
                                    type='reset'
                                    aria-label='Exit'
                                    value='Exit'
                                    className='button-m button-primary'
                                    onClick={() => {
                                        setShowConfirmation(false);
                                        setFileProgressSelfVideo(0);
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showUploadVideo && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Contact Property Owner</h2>
                        </div>
                        <div className="modal__content">
                            {!showProcessing && !uploadStarted && (
                                <>
                                    {/* <div className={`form-field text-input ${!hasVideoName ? 'error' : ''}`}>
                                    <label htmlFor="project-description">Video name</label>
                                    <input
                                        name="videoName"
                                        id="videoName"
                                        type="text"
                                        aria-required="true"
                                        placeholder=""
                                        value={videoName}
                                        onFocus={(e) => { setHasVideoName(true); setShowFormErrorMessage(false); } }
                                        onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'videoName'); checkVideoName(e.target.value); } }
                                        onChange={(e) => { setVideoName(e.target.value); } }
                                    >
                                    </input>
                                    {!hasVideoName && (
                                        <p className="validation-message">Enter video name.</p>
                                    )}
                                </div>
                                <div className={`form-field text-area ${!hasVideoDescription ? 'error' : ''}`}>
                                    <label htmlFor="video-description">Video description</label>
                                    <div className="input-container">
                                        <textarea
                                            name="Video description"
                                            id="video-description"
                                            type="text"
                                            aria-required="true"
                                            placeholder=""
                                            value={videoDescription}
                                            onFocus={(e) => { setHasVideoDescription(true); setShowFormErrorMessage(false);} }
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'videoDescription'); checkVideoDescription(e.target.value); } }
                                            onChange={(e) => { setVideoDescription(e.target.value); } }
                                        >
                                        </textarea>
                                    </div>
                                    {!hasVideoDescription && (
                                        <p className="validation-message">Enter video description</p>
                                    )}
                                </div> */}
                                    <div
                                        className={`form-field file-input ${!isValidVideo || showErrorMessage || isTooLongVideo ? 'error' : ''}`}>
                                        <input type="file" id="fileupload" className="file-input__input"
                                               onChange={(e) => {
                                                   setShowErrorMessage(false);
                                                   setIsValidVideo(true);
                                                   setIsTooLongVideo(false);
                                                   changeHandlerUploads(e)
                                               }} ref={videoRef}/>
                                        <label htmlFor="fileupload" className="button button-m button-tertiary">
                                            {getSvg('upload')}
                                            <span className="text">Choose video&hellip;</span>
                                        </label>
                                        {fileSelectedName.trim().length > 0 && (
                                            <span className="message"><br/><br/>{fileSelectedName}</span>
                                        )}
                                        {showErrorMessage && (
                                            <p className="validation-message">Choose a video first.</p>
                                        )}
                                        {!isValidVideo && (
                                            <p className="validation-message">{videoFormatError}</p>
                                        )}
                                        {isTooLongVideo ?
                                            <p className="validation-message">The video
                                                must not exceed 15 minutes in length.</p>
                                            : ''
                                        }
                                    </div>
                                </>
                            )}

                            {!showProcessing && uploadStarted && (
                                <>
                                    <div className='fz-block-sm'>
                                        <AlertMessage
                                            messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                            messageBody={""}
                                            startDate={null}
                                            setShowWarningContainerMessage={''}
                                            messageType={"informational"}
                                            showButton={false}
                                        />
                                    </div>
                                    {errorUploadingVideo && (
                                        <div className="alert-container">
                                            <div className="alert alert--error">
                                                <div className="alert__content">
                                                    <p className="alert__heading">There was an error uploading the
                                                        video. Please try it again.</p>
                                                </div>
                                                <button type="button"
                                                        className="button-tertiary button-s button-icon alert__close"
                                                        onClick={() => setErrorUploadingVideo(false)}>
                                                    <span className="icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17 7L7 17M7 7L17 17" stroke="currentColor"
                                                                  strokeWidth="2" strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            <div className='file-input-container block'>
                                <div className='file-input-container block'>
                                    <div className="fz-block">
                                        {uploadStarted && parseInt(fileProgressSelfVideo) !== 100 ?
                                            <div className='fz-block'>
                                                <small className='fz-align-center'>
                                                    Uploading video&nbsp; &nbsp; &nbsp;
                                                    <progress className='progress-bar' value={fileProgressSelfVideo}
                                                              max='100'></progress>
                                                    &nbsp; &nbsp; &nbsp;
                                                    {parseInt(fileProgressSelfVideo)} of 100%
                                                    completed&nbsp;&nbsp;&nbsp;
                                                    <span className="loader loader--s"></span>
                                                </small>
                                            </div>
                                            :
                                            !fileProgressSelfVideo === 0 && uploadStarted ?
                                                <p className="fz-align-center">Preparing video to be
                                                    uploaded &nbsp;&nbsp;<span className="loader loader--s"></span></p>
                                                : ''
                                        }
                                    </div>
                                    {showProcessing && (
                                        <AlertMessage
                                            messageHeader={process.env.REACT_APP_PROCESSING_MESSAGE}
                                            messageBody={process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}
                                            startDate={null}
                                            setShowWarningContainerMessage={''}
                                            messageType={"informational"}
                                            showButton={false}
                                            showLoader={true}
                                        />
                                    )}
                                </div>
                            </div>
                            {showFormErrorMessage && (
                                <AlertMessage
                                    messageHeader={`Video name and description are required.`}
                                    messageType={"error"}
                                    showButton={true}
                                    messageBodyContinuation={null}
                                    onButtonClick={handleAlertButtonClick}
                                />
                            )}
                        </div>
                        {!showProcessing && !errorUploadingVideo && (
                            <div className="modal__action">
                                <div className="button-group">
                                    <button
                                        type='button'
                                        id='uploadbutton'
                                        className='button button-m button-primary'
                                        onClick={() => {
                                            handleUpload()
                                        }}
                                    >
                                        {getSvg('upload')}
                                        Send video reply
                                    </button>

                                    <button
                                        type='reset'
                                        aria-label='Exit'
                                        value='Exit'
                                        className='button button-m butto-secondary'
                                        onClick={() => {
                                            setShowUploadVideo(false);
                                            setShowErrorMessage(false);
                                            // setVideoName('');
                                            // setVideoDescription('');
                                            setIsValidVideo(true);
                                            setIsTooLongVideo(false);
                                            setFileSelectedName('');
                                        }}
                                    >
                                        Exit
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {showNotAllowed && (
                <ModalInfo
                    messageHeader={"You are not allowed to see this page."}
                    messageBody={"This page is just for registered Pros and Vendors"}
                    messageType="error"
                />
            )}
        </div>
    )
}

export default ProFeedFree;
