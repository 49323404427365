import React from 'react';
import getSvg from '../../utils/getSvg';
function ModalAlertMessage({ messageHeader, messageType, showMessageBody=false, messageBody='' }) {
  return (
    <div className="alert-container">
        <div className={`alert alert--${messageType}`}>
            <div className="alert__content">
                <p className="alert__heading">{messageHeader}</p>
                {showMessageBody && (<p>{messageBody}</p>)}
            </div>
        </div>
           
    </div>
  );
}

export default ModalAlertMessage;
