import React from 'react';
import designTags from '../../utils/designTags.json';
import constructionTags from '../../utils/constructionTags.json';
import getSvg from '../../utils/getSvg';
function FilterTags({handleFilters, setSelectedValues, setShowFilters, filterCityStateData, getPayedLeads, selectedValues, userId}) {
    return (
        <div id="modal-1" className="modal-container">
            <div className="modal">
                <div className="modal__header">
                    <h2 className="modal-title h4">Filters</h2>
                    <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={(e) => {setShowFilters(false)}}>
                        <span className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                        </span>
                    </button>
                </div>
                <div className="modal_content">
                    <p>Narrow down your search by applying filters.</p>
                    <section className="form-tags">
                        <div className="form-row col-2">
                            <fieldset className="border">
                                <legend>
                                    {getSvg('palette')}
                                    Design
                                </legend>
                                <p className="helper-text">Check all that apply.</p>
                                {designTags.map((tag, index) => {
                                    const isChecked = selectedValues && selectedValues.includes(tag.type);
                                    return (
                                        <div className="form-field checkbox" key={`design_${index}`}>
                                            <input type="checkbox" id={tag.id} name={tag.name} value={tag.value} onChange={handleFilters} checked={selectedValues.includes(tag.tagType)} />
                                            <label htmlFor={tag.id}>{tag.labelText}</label>
                                        </div>
                                    )
                                })}
                            </fieldset>
                            <fieldset className="border">
                                <legend>
                                    {getSvg('construction')}
                                    Construction
                                </legend>
                                <p className="helper-text">Check all that apply.</p>
                                {constructionTags.map((tag, index) => {
                                    return (
                                        <div className="form-field checkbox" key={`design_${index}`}>
                                            <input type="checkbox" id={tag.id} name={tag.name} value={tag.value} onChange={handleFilters} checked={selectedValues.includes(tag.tagType)} />
                                            <label htmlFor={tag.id}>{tag.labelText}</label>
                                        </div>
                                    )
                                })}
                            </fieldset>                            
                        </div>
                    </section>
                    <div className='modal__action'>
                        <div className="button-group">
                            <button
                                type="button"
                                className="button-m button-primary"
                                onClick={(e) => {filterCityStateData(); setShowFilters(false); e.preventDefault(); }}
                            >
                                    <span className="text">Apply</span>
                            </button>
                            <button
                                type="button"
                                className="button-m button-tertiary"
                                onClick={(e) => {e.preventDefault(); setSelectedValues([]); getPayedLeads(userId)}}
                            >
                                <span className="text">Clear</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default FilterTags;