import React from 'react';
import {useNavigate} from 'react-router-dom';

function BreadCrumbs({
                       header1,
                       isLink1Clickable = false,
                       link1,
                       header2,
                       link2,
                       isLink2Clickable,
                       isLink2Navigation,
                       link3,
                       isLink3Clickable,
                       isLink3Navigation,
                       header3,
                       header4,
                       goToFeed,
                       userType
                     }) {
  const navigate = useNavigate();
  const notClickableLink = () => {
    const urlWithoutQueryParams = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.pushState({path: urlWithoutQueryParams}, '', urlWithoutQueryParams);
    window.location.reload();
  }

  return (
      <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
        <ol>
          <li>
            {isLink1Clickable ? <a href="" onClick={(e) => {
              e.preventDefault();
              navigate(`/${link1}`)
            }}>{header1}</a> : <span>{header1}</span>}
            {isLink1Clickable ?
                <span className="separator">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </span>
                : ''
            }
          </li>
          <li>
            {isLink2Navigation ?
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  navigate(`/${link2}`)
                }}>{header2}</a>
                :
                isLink2Clickable ?
                    <a href="" onClick={(e) => {
                      notClickableLink()
                    }}>{header2}</a>
                    :
                    <span>{header2}</span>
            }
              {header3 && <span className="separator">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </span>}
          </li>
          <li>
            {isLink3Clickable ? <a href="" onClick={(e) => {
              e.preventDefault();
              navigate(`/${link3}`)
            }}>{header3}</a> : <span>{header3}</span>}
            {header4 && <span className="separator">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </span>}
          </li>
          <li aria-current="page">
            <span>{header4}</span>
          </li>
        </ol>
      </nav>
  )
}

export default BreadCrumbs;
