import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../libs/Header';
import Axios from 'axios';
import { S3 } from "@aws-sdk/client-s3";
import uuid from 'react-uuid';
import { Upload } from "@aws-sdk/lib-storage";
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { uploadWithSignedUrl } from "../../utils/awsHelpers";

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function ProVideosGallery() {
    const prerecordedRef = useRef(null);
    const handleGoToLogin = () => navigate('/login');
    const userId = sessionStorage.getItem('userId');
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [libraryVideos, setLibraryVideos] = useState([]);
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    const [showProcessing, setShowProcessing] = useState(true);
    const [showWait, setShowWait] = useState(0);
    const [currentVideoId, setCurrentVideoId] = useState(0);
    const [uploadedStarted, setUploadStarted] = useState(false);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [noFiles, setNoFiles] = useState(false);
    const [showLengthCheck, setShowLengthCheck] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showLengthMessage, setShowLengthMessage] = useState(false);
    const [currentUploadIndex, setCurrentUploadIndex] = useState(0);
    const [fileProgress, setFileProgress] = useState(0);
    const [countVideo, setCountVideo] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showNotAuthorized, setShowNotAuthorized] = useState(true);
    const [canUpload, setCanUpload] = useState(true);
    const [videoDescription, setVideoDescription] = useState('');
    const [videoName, setVideoName] = useState('');
    const [errorVideoDescription, setErrorVideoDescription] = useState(false);
    const rd = new FileReader();
    const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
    let mime = '';
    const userType = parseInt(sessionStorage.getItem('userType'));
    const navigate = useNavigate();
    let uploadFileName = '';
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    let tempCounter = 0;

    useEffect(() => {
        checkLogin();
    }, []);

    const checkLogin = () => {
        if (isLoggedIn === 'false') {
            handleGoToLogin();
        } else if (parseInt(userType) !== 500002) {
            setShowNotAuthorized(false);
        } else {
            getProsVideosFromLibrary(userId);
        }
    }

    const getProsVideosFromLibrary = (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getProsVideosFromLibrary`, { userId: userId }).then((response) => {
            setLibraryVideos(response.data);
            if (response.data.length < 5) {
                setCanUpload(true);
            } else {
                setCanUpload(false);
            }
        }).catch(function (error) {
            console.log('error = ', error);
        });
    }

    const changeHandlerUploads = (event) => {
        setNoFiles(false);
        setIsValidVideo(true);
        event.preventDefault();
        const chosenFile = event.target.files[0].name;
        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidVideo(false);
        } else {
            setVideoName(chosenFile);
            setIsValidVideo(true);
            let file = event.target.files[0];
            const fileNameArray = file.name.split('.');
            setSelectedUploads(event.target.files);
            if (fileNameArray[fileNameArray.length - 1].toLowerCase() === 'mp4') {
                setShowLengthCheck(true);
                setShowLengthMessage(false);
                mime = file.type;
                rd.onload = function (e) {
                    let blob = new Blob([e.target.result], { type: mime }),
                        url = URL.createObjectURL(blob), // create o-URL of blob
                        video = document.createElement("video");
                    video.preload = "metadata";
                    video.addEventListener("loadedmetadata", function () {
                        if (video.duration > 200) {
                            setShowLengthCheck(false);
                            setShowLengthMessage(true);
                        } else {
                            setShowLengthCheck(false);
                            setShowLengthMessage(false);
                        }
                        (URL).revokeObjectURL(url);
                    });
                    video.src = url;
                };
                rd.readAsArrayBuffer(file);
            }
        }
    }

    const deleteVideo = (id) => {
        setLibraryVideos([]);
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteVideoLibrary`, {
            id: id
        }).then((response) => {
            getProsVideosFromLibrary(userId);
            setShowDeleteModal(false)
        }).catch(function (error) {
            console.log('delete video library error = ', error);
        });
    }

    const saveVideoUploaded = (uploadFileName, videoDescription, userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveProPreRecordedVideos`, {
            uploadFileName: uploadFileName,
            userId: userId,
            videoDescription: videoDescription,
        }).then((response) => {
            setVideoDescription('');
            setSelectedUploads([]);
            setFileProgress(0);
            setSelectedUploads([]);
            setVideoName('');
            getProsVideosFromLibrary(userId);
        }).catch(function (error) {
            console.log('tags error = ', error);
        });
    }

    const handleUpload = async (bucketName) => {
        try {
            setNoFiles(false);
            if (selectedUploads.length > 0 && videoDescription.trim() !== '') {
                const filesArray = [...selectedUploads];
                await Promise.all(
                    filesArray.map(async (file) => {
                        uploadFileName = cleanMediaUrl(file.name);
                        uploadFileName = `${userId}/video-library/${uploadFileName}`;
                        setUploadStarted(true);
                        await uploadWithSignedUrl(file, bucketName, uploadFileName, (myProgress) => {
                            setFileProgress(myProgress);
                            if (parseInt(myProgress) === 100) {
                                setShowConfirmation(true);
                                setCountVideo(1);
                                tempCounter++;
                                setCurrentUploadIndex(tempCounter);
                                setShowProcessing(0);
                                setShowWait(1);
                                setFileProgress(100);
                                setShowProcessing(true);
                            }
                        });


                        setTimeout(() => {
                            if (prerecordedRef.current) {
                                prerecordedRef.current.value = '';
                            }
                            saveVideoUploaded(uploadFileName, videoDescription, userId);
                            setShowWait(0);
                            setUploadStarted(false);
                            setShowConfirmation(false)
                            setVideoDescription('');
                        }, process.env.REACT_APP_TIEMOUT);
                    })
                );
            } else {
                if (videoDescription.trim() === '') {
                    setErrorVideoDescription(true)
                }

                setShowProcessing(0);
                setShowWait(0);
                if (selectedUploads.length === 0) {
                    setNoFiles(true);
                }
            }
        } catch (e) {
            setShowWait(0);
            console.error(`Uploading Videos file error: ${e}`);
            setUploadStarted(false);
        }
    };

    const calculatePercentage = (max, current) => {
        let total = 0;
        total = ((current / max) * 100);
        return Math.ceil(total);
    }

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <div className='col eight'>
                        {showNotAuthorized ?
                            <main className='fz-feed'>
                                <div className="tabs">
                                    <div role="tablist" aria-labelledby="tablist-1" className="automatic block">
                                        <button id="tab-1"
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            aria-controls="tabpanel-1"
                                            onClick={(e) => {
                                                navigate(`/profeed`);
                                            }}
                                        >
                                            <span className="focus">
                                                <i title="Leads" className="icon-clipboard"></i>
                                                Leads
                                            </span>
                                        </button>
                                        <button id="tab-2"
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            aria-controls="tabpanel-2"
                                            tabIndex="-1"
                                            onClick={(e) => {
                                                navigate(`/proreplies`);
                                            }}
                                        >
                                            <span className="focus">
                                                <i title="Reply" className="icon-reply"></i>
                                                Reply
                                            </span>
                                        </button>
                                        <button id="tab-3"
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            aria-controls="tabpanel-3"
                                            tabIndex="-1"
                                            onClick={(e) => {
                                                navigate(`/proportfolio`);
                                            }}
                                        >
                                            <span className="focus">
                                                <i title="Portfolio" className="icon-list"></i>
                                                Portfolio
                                            </span>
                                        </button>
                                        <button id="tab-4"
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            aria-controls="tabpanel-4"
                                            tabIndex="-1"
                                            onClick={(e) => {
                                                navigate(`/testimonial`);
                                            }}
                                        >
                                            <span className="focus">
                                                <i title="Testimonial" className="icon-selfie"></i>
                                                Testimonial
                                            </span>
                                        </button>
                                        {/* <button id="tab-5"
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="true"
                                            aria-controls="tabpanel-5"
                                            tabIndex="-1"
                                            onClick={() => navigate(`/prolibrary`)}
                                        >
                                            <span className="focus">
                                                <i title="Video Library" className="icon-folder"></i>
                                                Video Library
                                            </span>
                                        </button> */}
                                    </div>
                                </div>
                                <div>
                                    {uploadedStarted ?
                                        <div className='block-xx'>
                                            <div className='fz-alert alert-warning'>
                                                <i className='icon-warning'></i>
                                                <strong>File upload progress will be lost!</strong>
                                                <p>{process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}</p>
                                            </div>
                                        </div> : ''
                                    }
                                    {showLengthCheck ?
                                        <div className='block-xx'>
                                            <div className='fz-alert alert-warning'>
                                                <i className='icon-warning'></i>
                                                <strong>Checking for file duration</strong>
                                            </div>
                                        </div> : ''
                                    }
                                    {showLengthMessage ?
                                        <div className='block-xx'>
                                            <div className='fz-alert alert-error'>
                                                <i className='icon-error'></i>
                                                <strong>Videos can not be longer than 5 minutes. Select a different file adn try it again.</strong>
                                            </div>
                                        </div> : ''
                                    }


                                    <article className='fz-container fz-delisted'>
                                        <div className="col six">
                                            <ul className='grid-full block'>
                                                <li className='fz-full-width block-xx'>
                                                    <label className='gray-800'>Video</label>
                                                    <div className={`form-field file-input ${!isValidVideo || noFiles ? 'error' : ''}`}>
                                                        <input type="file" id="vido" className="file-input__input" onClick={() => prerecordedRef.current.blur()} onChange={changeHandlerUploads} ref={prerecordedRef} />
                                                        <label htmlFor="vido" className="button button-m button-tertiary">
                                                            <span className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </span>
                                                            <span className="text">Choose video&hellip;</span>
                                                        </label>
                                                        {!isValidVideo ?
                                                            <p className="validation-message">{videoFormatError}</p>
                                                            : ''
                                                        }
                                                        {noFiles ?
                                                            <p className="validation-message">Please select a video to be uploaded</p>
                                                            : ''
                                                        }
                                                        {videoName !== '' ? <p className='normalMessage'>{videoName}</p> : ''}
                                                    </div>
                                                    <div className={`form-field text-area ${errorVideoDescription ? 'error' : ''}`}>
                                                        <label htmlFor='videodescription'>Video description</label>
                                                        <div className="input-container">
                                                            <textarea
                                                                placeholder="Enter a short description to identify this video"
                                                                value={videoDescription}
                                                                id='videodescription'
                                                                onChange={(e) => { setVideoDescription(e.target.value); }}
                                                                onFocus={(e) => { setErrorVideoDescription(false); }}
                                                            >
                                                            </textarea>
                                                        </div>
                                                        {errorVideoDescription ?
                                                            <p className="validation-message">Please enter a description for this video.</p>
                                                            : ''
                                                        }
                                                    </div>
                                                </li>
                                                <li>
                                                    {showNotAuthorized ?
                                                        <div className='fz-container'>
                                                            {/* {!hasTooManyFilesSelected ? uploadedStarted === 0 ? <button type='button' id="stillPictureBtn"  className='btn fz-btn-med' onClick={() => handleUpload('coverImage')}>Upload all files</button> : <span>Upload in progress</span> : ''} */}
                                                            <span className='file-input__files'></span>
                                                            {!showLengthCheck && !showLengthMessage ?
                                                                <button
                                                                    type='button'
                                                                    aria-label='Upload'
                                                                    value='Upload'
                                                                    className='button-primary button-m'
                                                                    onClick={() => handleUpload(process.env.REACT_APP_PROJECT_VIDEO_SOURCE)}
                                                                >
                                                                    Upload video
                                                                </button>
                                                                : ''
                                                            }
                                                        </div>
                                                        : ''
                                                    }
                                                </li>
                                            </ul>

                                            {libraryVideos.length > 0 ?
                                                <><hr /><h1>Library videos</h1><br /></>
                                                : ''
                                            }

                                            {libraryVideos.map((video, index) => {
                                                return (
                                                    <div className="fz-response-container fz-column" key={`container_${index}`}>
                                                        <video className="fz-video" width="100%" height="auto" controls key={`video_${index}`}>
                                                            <source src={`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX}/${video.videoFileName}`} type="video/mp4" />
                                                        </video>
                                                        <div className="block">
                                                            <div className="video-details">
                                                                <div className="video-description">
                                                                    <p>{video.videoDescription}</p>
                                                                </div>
                                                                <div className="button-wrapper">
                                                                    <div style={{ padding: '10px' }}>
                                                                        <button
                                                                            type="button"
                                                                            aria-label="Delete"
                                                                            value="Delete"
                                                                            className="btn fz-btn-small"
                                                                            onClick={() => { setCurrentVideoId(video.id); setVideoDescription(video.videoDescription); setShowDeleteModal(true) }}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </article>
                                </div>
                            </main>
                            :
                            <div className="alert-container">
                                <div className="alert alert--error">
                                    <div className="alert__content">
                                        <p className="alert__heading">This feature is for Premium customers only.</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {showDeleteModal ?
                    <div id="modal-5" className="modal-container">
                        <div className="modal">
                            <div className="modal__header">
                                <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                            </div>
                            <div className="modal__action">
                                <div className="button-group">
                                    <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false) }}>No, Cancel</button>
                                    <button className="button button-m button-destructive" type="button" onClick={() => { deleteVideo(currentVideoId) }}>Yes, Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
                }

                {uploadedStarted ?
                    <div className="modal-container">
                        <div className="modal">
                            <div className="modal__header">
                                <h2 className="modal-title h4">Video library upload</h2>
                            </div>
                            <div className="modal__content">
                                {selectedUploads.length > 0 ?
                                    <div className='file-input-container block'>
                                        <span>
                                            <span className='itemColor'>
                                                {
                                                    fileProgress > 0 && countVideo < 1
                                                        ?
                                                        <span>
                                                            <span className='normalMessage'>
                                                                &nbsp;Uploading video <br />
                                                            </span>

                                                            <small>
                                                                <span className='gray-600'>
                                                                    <progress className='progress-bar' value={fileProgress} max='100'></progress> &nbsp;
                                                                    {fileProgress} of 100% completed
                                                                </span><span className="loader loader--s"></span>
                                                            </small>
                                                        </span>
                                                        : ''
                                                }
                                            </span>
                                        </span>
                                        {selectedUploads.length > 0 && fileProgress === 0 ? <div>Preparing video to be uploaded <span className="loader loader--s"></span></div> : ''}
                                    </div> : ''
                                }
                                {showProcessing === 1 ?
                                    <div className="fz-alert alert-info">
                                        <i className="icon-info"></i>
                                        <strong>Info</strong>
                                        <br />
                                        <h4 className='h4-like'>{process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}</h4>
                                    </div>
                                    : ''
                                }
                                {showWait === 1 ?
                                    <div>
                                        <p>{process.env.REACT_APP_PROCESSING_VIDEO_MESSAGE} <span className="loader loader--s"></span></p>
                                    </div>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                    : ''
                }
            </main>
        </div>
    );
}
