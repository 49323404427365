import React from 'react';
import { useNavigate} from 'react-router-dom';
export default function TopPageBanner({userId, imageBanner, imageBannerText, h2Text, bodyText, buttonText, urlToGo,leadType}) {
    const navigate = useNavigate();
  return (
   <div className="cta-container">
        <div className="cta">
            <div className="cta__image">
                <img src={imageBanner} alt={imageBannerText} />
            </div>
            <div className="cta__content">
                <h2 className="h4">{h2Text}</h2>
                <p>{bodyText}</p>
                <div className="button-group">
                    <button type="button" className="button-m button-primary"
                        onClick={() => {
                            if(leadType !== 1){
                                navigate(`${urlToGo}/${userId}`);
                            }else{
                                window.location.href = `https://www.fazzad.com`;
                            }
                        }}   
                    >
                        <span className="text">{buttonText}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
};
