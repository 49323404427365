import React, {useCallback, useEffect, useState} from 'react';
import styles from './modal.module.scss';
import {LoadingSpinner} from "./loading-spinner/loading-spinner";
import debounce from 'lodash/debounce';
import * as pdfjs from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.mjs';
import {useNavigate, useParams} from "react-router-dom";

function Modal({children, onClose}) {
  return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <button className={styles.closeButton} onClick={onClose}>×</button>
          {children}
        </div>
      </div>
  );
}

/**
 *
 * @param selectedPdfData
 * @param onClose
 * @param allUsedPhotos string[]
 * @returns {JSX.Element}
 * @constructor
 */
const PdfThumbnailsModal = ({selectedPdfData, onClose, allUsedPhotos}) => {
  const pdfId = selectedPdfData.item.Key;
  const pdfData = selectedPdfData.data?.[pdfId.replace(/\//g, '__').replace('.pdf', '')] ?? selectedPdfData.item;
  const url = `https://fazzad-projects.s3.us-west-1.amazonaws.com/${pdfId}`;
  const {packageName} = useParams();
  const navigate = useNavigate();
  const [pdf, setPdf] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedPages, setSelectedPages] = useState(pdfData?.pdfPages ?? []);
  const [editingThumbnail, setEditingThumbnail] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    const loadPdf = async () => {
      setIsLoading(true);
      if (pdf) await pdf.destroy();

      // Fetch the PDF file and create a blob
      const response = await fetch(url);
      const pdfBlob = await response.blob();

      const arrayBuffer = await pdfBlob.arrayBuffer();
      const loadingTask = pdfjs.getDocument({data: arrayBuffer});
      const loadedPdf = await loadingTask.promise;
      setPdfBlob(pdfBlob)
      setPdf(loadedPdf);
    };

    loadPdf().then(() => setTimeout(() => setIsLoading(false), 2000));
  }, [url]);

  useEffect(() => {
    const renderThumbnails = async () => {
      if (pdf) {
        const pages = [];
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          const page = await pdf?.getPage?.(pageNumber);
          const viewport = page.getViewport({scale: 0.2});
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          await page.render(renderContext).promise;

          pages.push({
            id: pageNumber,
            pageNumber,
            src: canvas.toDataURL(),
            pageName: selectedPages?.find(p => pageNumber === p.pageNumber)?.pageName ?? `Page ${pageNumber}`
          });
        }
        setThumbnails(pages);
      }
    };

    renderThumbnails();
  }, [pdf]);

  const handleThumbnailImageClick = (thumbnail) => {
    setSelectedPages(prev =>
        prev.some(t => t.pageNumber === thumbnail.pageNumber)
            ? prev.filter(t => t.pageNumber !== thumbnail.pageNumber)
            : [...prev, thumbnail]
    );
  };

  const handleNameClick = (thumbnail) => {
    setEditingThumbnail(thumbnail.id);
    setEditingName(thumbnail.pageName);
  };

  const saveName = useCallback((pageNumber, newName) => {
    setThumbnails(prev =>
        prev.map(t => t.pageNumber === pageNumber ? {...t, pageName: newName} : t)
    );
    setSelectedPages(prev =>
        prev.map(t => t.pageNumber === pageNumber ? {...t, pageName: newName} : t)
    );
    setEditingThumbnail(null);
  }, []);

  const handleNameChange = (e, id) => {
    const newName = e.target.value;
    setEditingName(newName);
  };

  const handleKeyDown = (e, pageNumber) => {
    if (e.key === 'Escape') {
      setEditingThumbnail(null);
    }
    if (e.key === 'Enter') {
      saveName(pageNumber, editingName);
    }
  };


  const handleContinue = () => {
    navigate(`/editpackagefolderspictures/${packageName}/planandpicture/${pdfId.replace(/\//g, '__').replace('.pdf', '')}`, {
      state: {
        viewType: 'edit',
        pdfBlob,
        selectedPages: selectedPages.map(p => {
          const {src, ...rest} = p;
          return rest;
        })
      }
    })
  };

  return (
      <Modal onClose={onClose}>
        <div>
          {!isLoading && <h2 className={`modal-title h4 ${styles.title}`}>Select page to start</h2>}
          {isLoading && <h2 className={`modal-title h4 ${styles.title}`}>Loading...</h2>}
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', margin: '-8px'}}>
              {isLoading && <LoadingSpinner/>}
              {!isLoading && thumbnails.map((thumbnail, idx) => (
                  <div
                      tabIndex={0}
                      key={thumbnail.id}
                      style={{
                        padding: '.5rem',
                        border: selectedPages.some(p => p.pageNumber === thumbnail.pageNumber) ? '1px solid blue' : '1px solid #A8A8A8',
                        // width: 'calc(10em - 16px)', // Subtracting the total horizontal margin
                        textAlign: 'center',
                        borderRadius: '12px',
                        margin: '8px' // This creates 16px gap between items (8px on each side)
                      }}
                  >
                    <img
                        src={thumbnail.src}
                        alt={thumbnail.pageName}
                        style={{cursor: 'pointer', margin: '0', width: '250px'}}
                        onClick={() => handleThumbnailImageClick(thumbnail)}
                    />
                    {editingThumbnail === thumbnail.id ? (
                        <input
                            type="text"
                            value={editingName}
                            onChange={(e) => handleNameChange(e, thumbnail.pageNumber)}
                            onKeyDown={(e) => handleKeyDown(e, thumbnail.pageNumber)}
                            onBlur={(e) => {
                              saveName(thumbnail.pageNumber, editingName);
                            }}
                            autoFocus
                            style={inputStyle}
                            placeholder="Enter page name"
                        />
                    ) : (
                        <p
                            onClick={() => handleNameClick(thumbnail)}
                            style={{
                              ...nameStyle,
                              cursor: 'pointer'
                            }}
                        >
                          {thumbnail.pageName}
                        </p>
                    )}
                  </div>
              ))}
            </div>
          </div>
          {!isLoading && (
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
                <button
                    disabled={!selectedPages.length}
                    style={{
                      backgroundColor: selectedPages.length ? '#7c3aed' : '#aaa',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      border: 'none',
                      cursor: selectedPages.length ? 'pointer' : 'not-allowed',
                      marginTop: '20px',
                    }}
                    onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
          )}
        </div>
      </Modal>
  );
};

const nameStyle = {
  width: '100%',
  height: '36px', // Set a fixed height
  padding: '8px',
  margin: '0',
  fontSize: '14px',
  lineHeight: '20px',
  border: '1px solid transparent',
  borderRadius: '4px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  alignContent: 'center',
  fontWeight: '800'
};

const inputStyle = {
  ...nameStyle,
  border: '1px solid #ccc',
  backgroundColor: '#f8f8f8'
};

export default PdfThumbnailsModal;
