import React, { useEffect, useState, useRef } from 'react';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { S3 } from '@aws-sdk/client-s3';
import { useNavigate } from 'react-router-dom';
import { Upload } from '@aws-sdk/lib-storage';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import Header from '../libs/Header'
import trashicon from '../../images/icon-trash-03.svg';
import uuid from 'react-uuid';
import { getPutObjectSignedUrl } from "../../utils/awsHelpers";

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function MediaGallery() {
    const navigate = useNavigate();
    const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
    const [fileToBeUploaded, setFileToBeUploaded] = useState('');
    const [fileProgressSelfVideo, setFileProgressSelfVideo] = useState(5);
    const [hasMediaName, setHasMediaName] = useState(true);
    const [hasMediaTypeDescription, setHasMediaTypeDescription] = useState(true);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [mediaType, setMediaType] = useState('Portfolio');
    const [mediaName, setMediaName] = useState('');
    const [mediaDescription, setMediaDescription] = useState('');
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [showMedia, setShowMedia] = useState(true);
    const [singleVideo, setSingleVideo] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [showMore, setShowMore] = useState({});
    const [uploadStarted, setUploadStarted] = useState(false);
    const [videoCount, setVideoCount] = useState(0);
    const [videosResponses, setVideosResponses] = useState([]);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const [uploadType, setUploadType] = useState('portfolio');
    const proTestimonialProfileBucketName = process.env.REACT_APP_S3_BUCKET_MEDIA_VIDEOS_PREFIX;
    const processingMessage = process.env.REACT_APP_PROCESSING_MESSAGE;
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_BUCKET_PROS_VIDEOS_PREFIX;
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    const userId = sessionStorage.getItem('userId');
    const handleGoToLogin = () => navigate('/login');
    const videoRef = useRef(null);
    const uuids = uuid();
    const config = {
        bucketName: process.env.REACT_APP_PROJECTS_BUCKET_NAME,
        region: process.env.REACT_APP_REGION
    };

    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            getProsVideos('portfolio', userId, 2);
        }
    }, []);

    const checkProjectDescription = (mediaDescription) => {
        if (mediaDescription.trim().length === 0) {
            setHasMediaTypeDescription(false);
        } else {
            setHasMediaTypeDescription(true);
        }
    }

    const sendUploadError = (err) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendGenericErrorEmail`, { errorSubject: 'Error Uploading Testimonial video', errorMessage: 'Check the cors issues' }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('error sending generic error email = ', error);
        })
    }

    const checkPortfolioName = (mediaName) => {
        if (mediaName.trim().length === 0) {
            setHasMediaName(false);
        } else {
            setHasMediaName(true);
        }
    }

    const getProsVideos = (videoType, userId, userType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, { videoType: videoType, userId: userId, userType: userType }).then((response) => {
            setVideosResponses(response.data);
        }).catch(function (error) {
            console.log('error getting pros videos = ', error);
        })
    }

    const getPackageDescription = (packageDesc, index) => {
        if (!showMore[index] && packageDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH) {
            return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH) + '...';
        } else {
            return packageDesc;
        }
    }

    const handleReadMore = (index) => {
        setShowMore({ ...showMore, [index]: !showMore[index] });
    }

    const checkUploadForm = () => {
        let isValid = true;
        if (mediaDescription.trim().length === 0) {
            setHasMediaTypeDescription(false);
            isValid = false;
        } else {
            setHasMediaTypeDescription(true);
        }
        if (mediaName.trim().length === 0) {
            setHasMediaName(false);
            isValid = false;
        } else {
            setHasMediaTypeDescription(true);
        }
        return isValid;
    }

    async function uploadWithSignedUrl(fileData, bucketName, key, onPrgress) {
        const signedUrl = await getPutObjectSignedUrl(bucketName, key, fileData.type);

        if (!signedUrl) {
            throw new Error("cannot generate signed url");
        }

        const xhr = new XMLHttpRequest();

        xhr.open('PUT', signedUrl, true);
        // Set the appropriate headers
        xhr.setRequestHeader('Content-Type', fileData.type);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                const myTotal = progress.toString().substring(0, 4);
                onPrgress && onPrgress(myTotal);
                console.log(myTotal);
            }
        };

        xhr.send(fileData);

        // Handle errors
        xhr.onerror = function (ev) {
            console.error('Error uploading file.', ev);
        };

        xhr.onload = function () {
            if (xhr.status === 200) {
                console.log('Request completed successfully:', xhr.responseText);
            } else {
                console.error('Request failed with status:', xhr.status);
                throw new Error(xhr.error);
            }
        };
    }

    const changeHandlerUploads = (event) => {
        event.preventDefault();
        setShowErrorMessage(false);
        const chosenFile = event.target.files[0].name;
        setFileToBeUploaded(chosenFile);
        const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;

        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidVideo(false);
        } else {
            setIsValidVideo(true);
            setSelectedUploads(event.target.files);
        }
        videoRef.current.blur();
    }

    const uploadVideo = async (file, config) => {
        let fileName = '';
        if (checkUploadForm() && selectedUploads.length > 0) {
            try {
                setUploadStarted(true);

                setVideoCount(0);
                setFileProgressSelfVideo(0);
                setShowErrorMessage(false);
                setShowProcessing(false);
                setErrorUploadingVideo(false);
                fileName = cleanMediaUrl(file.name);
                const key = `${uuids}/${fileName}`;
                await uploadWithSignedUrl(file, proTestimonialProfileBucketName, key, setFileProgressSelfVideo);
            } catch (error) {
                setUploadStarted(false);
                setShowProcessing(false);
                console.error('Upload error:', error);
            } finally {
                // saveVideoUploaded(userId, uploadType, file, fileName, mediaDescription, uuids);
                setShowProcessing(true);
                setTimeout(() => {
                    setSelectedUploads([]);
                    setMediaName('');
                    setMediaDescription('');
                    setFileToBeUploaded('');
                    setErrorUploadingVideo(false);
                    setShowInfo(true);
                    setShowProcessing(false);
                    setShowMedia(true)
                    setShowUploadVideo(false);
                    getProsVideos(uploadType, userId, 500002);
                    setUploadStarted(false);
                }, process.env.REACT_APP_TIEMOUT);
            }
        } else {
            setShowErrorMessage(true);
        }
    }

    const clearEmojis = (string, type) => {
        switch (type) {
            case 'mediaDescription':
                setMediaDescription(string.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''));
                break;
            case 'mediaName':
                setMediaName(string.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''));
                break;
        }
    }

    const deletePortfolio = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteProUploadsVideos`, { singleVideo: singleVideo }).then((response) => {
            setShowDeleteModal(false);
            getProsVideos('portfolio', userId, 500002);
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <main className="fz-feed">
                        <div className="tabs">
                            {showMedia ?
                                <>
                                    <div role="tablist" aria-labelledby="tablist-1" className="automatic block">
                                        <button id="tab-1"
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            aria-controls="tabpanel-1"
                                            tabIndex="-1"
                                            onClick={(e) => {
                                                navigate(`/profeed`);
                                            }}
                                        >
                                            <span className="flat">
                                                <i title="Leads" className="icon-clipboard"></i>
                                                Leads
                                            </span>
                                        </button>
                                        <button id="tab-2"
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            aria-controls="true-2"
                                            onClick={(e) => {
                                                navigate(`/proreplies`);
                                            }}
                                        >
                                            <span className="focus">
                                                <i title="Reply" className="icon-reply"></i>
                                                Reply
                                            </span>
                                        </button>
                                        <button id="tab-3"
                                            onClick={(e) => { setMediaType('Portofolios'); setUploadType('portfolio') }}
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="true"
                                            aria-controls="tabpanel-3">
                                            <span className="focus">
                                                <i title="Portfolios" className="icon-list"></i>
                                                Portfolio
                                            </span>
                                        </button>
                                        <button id="tab-4"
                                            className="flat"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            aria-controls="tabpanel-4"
                                            tabIndex="-1"
                                            onClick={(e) => { setMediaType('Testimonial'); setUploadType('testimonial') }}
                                        >
                                            <span className="focus">
                                                <i title="Testimonial" className="icon-selfie"></i>
                                                Testimonial
                                            </span>
                                        </button>
                                    </div>
                                    <div id="tabpanel-1" role="tabpanel" tabIndex="0" aria-labelledby="tab-1">
                                        <div className="col six fz-video-item">
                                            <div className="fz-header-title fz-container fz-between">
                                                <div className="inner block fz-start">
                                                    <h2 className="h1-like">{mediaType}</h2>
                                                </div>
                                                <div className="inner button-group fz-end">
                                                    <button type="button" className="button-m button-primary" onClick={(e) => { setShowUploadVideo(true) }}>
                                                        <span className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span className="text">Upload {uploadType} video</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <br />
                                            <p className="block fz-start">Make videos of your best work and upload them here.</p>
                                            {videosResponses.length > 0 ?
                                                videosResponses.map((video, index) => {
                                                    return (
                                                        <div className="fz-response-container fz-column" key={`container_${index}`}>
                                                            <video className="fz-video" width="100%" height="auto" controls key={`video_${index}`}>
                                                                <source src={`${s3BucketVideoPrefix}/${video.referenceNumber}/${video.videoFileName}`} type='video/mp4' />
                                                            </video>
                                                            <div className="fz-padding-def fz-start fz-column">
                                                                <h3 className="gray-700">{video.projectName}</h3>
                                                                <h4 className="gray-600">Scope of work</h4>

                                                                <p className='preserve-html'>{getPackageDescription(video.projectDescription, video.id)}</p>
                                                                {video.projectDescription.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH && (
                                                                    <>
                                                                        <br />
                                                                        <a href="/" onClick={(e) => { e.preventDefault(); handleReadMore(video.id); e.target.blur(); }}>
                                                                            {showMore[video.id] ? 'Read less' : 'Read more'}
                                                                        </a>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="button-group">
                                                                <button
                                                                    title="Delete"
                                                                    className="button button-icon"
                                                                    onClick={(e) => { setSingleVideo(video); setShowDeleteModal(true) }}
                                                                >
                                                                    <img src={trashicon} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <AlertMessage
                                                    messageHeader={`You don't have any ${mediaType} video uploaded.`}
                                                    messageBody={""}
                                                    startDate={null}
                                                    setShowWarningContainerMessage={''}
                                                    messageType={"informational"}
                                                    showButton={false}
                                                />
                                            }
                                        </div>
                                    </div>
                                </>
                                : ''
                            }
                        </div>
                    </main>
                    {showDeleteModal ?
                        <div id="modal-5" className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                </div>
                                <div className="modal__action">
                                    <div className="button-group">
                                        <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false) }}>No, Cancel</button>
                                        <button className="button button-m button-destructive" type="button" onClick={() => deletePortfolio()}>Yes, Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                    {showUploadVideo && !uploadStarted ?
                        <>
                            <div id="modal-5" className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <h2 className="modal-title h4">{mediaType} file upload</h2>
                                    </div>
                                    <div className="modal__content">
                                        {!showProcessing && showInfo && !errorUploadingVideo ?
                                            <>
                                                <div className={`form-field text-input ${!hasMediaName ? 'error' : ''}`}>
                                                    <label htmlFor="mediaName">{mediaType} name</label>
                                                    <input
                                                        name="mediaName"
                                                        id="mediaName"
                                                        type="text"
                                                        aria-required="true"
                                                        placeholder=""
                                                        value={mediaName}
                                                        onFocus={(e) => { setHasMediaName(true); }}
                                                        onBlur={(e) => { clearEmojis(e.target.value, 'mediaName'); checkPortfolioName(e.target.value); }}
                                                        onChange={(e) => { setMediaName(e.target.value); }}
                                                    >
                                                    </input>
                                                    {!hasMediaName ?
                                                        <p className="validation-message">Enter {mediaType.toLowerCase()} name.</p>
                                                        : ''}
                                                </div>
                                                <div className={`form-field text-area ${!hasMediaTypeDescription ? 'error' : ''}`}>
                                                    <label htmlFor="project-description">Description</label>
                                                    <div className="input-container">
                                                        <textarea
                                                            name="Project description"
                                                            id="project-description"
                                                            type="text"
                                                            aria-required="true"
                                                            placeholder=""
                                                            value={mediaDescription}
                                                            onFocus={(e) => { setHasMediaTypeDescription(true); }}
                                                            onBlur={(e) => { clearEmojis(e.target.value, 'mediaDescription'); checkProjectDescription(e.target.value); }}
                                                            onChange={(e) => { setMediaDescription(e.target.value); }}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    {!hasMediaTypeDescription ?
                                                        <p className="validation-message">Enter {mediaType} description</p>
                                                        : ''
                                                    }
                                                </div>
                                            </>
                                            : ''
                                        }

                                        {!showProcessing && showInfo && !errorUploadingVideo ?
                                            <>
                                                <div className={`form-field file-input ${!isValidVideo || showErrorMessage ? 'error' : ''}`}>
                                                    <input type="file" id="fileupload" className="file-input__input" onChange={(e) => { setShowErrorMessage(false); setIsValidVideo(true); changeHandlerUploads(e) }} ref={videoRef} />
                                                    <label htmlFor="fileupload" className="button button-m button-tertiary">
                                                        <span className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span className="text">Choose video&hellip;</span>
                                                    </label>
                                                    {showErrorMessage ?
                                                        <p className="validation-message">Choose a video first.</p>
                                                        : ''
                                                    }
                                                    {!isValidVideo ?
                                                        <p className="validation-message">{videoFormatError}</p>
                                                        : ''
                                                    }
                                                    {fileToBeUploaded !== '' ? <p>{fileToBeUploaded}</p> : ''}
                                                </div>
                                                <div className="modal__action">
                                                    <div className="button-group">
                                                        <button
                                                            type='button'
                                                            id='uploadbutton'
                                                            className='button button-m button-primary'
                                                            onClick={() => { uploadVideo(selectedUploads[0], config) }}
                                                        >
                                                            Upload video
                                                        </button>

                                                        <button
                                                            type='reset'
                                                            aria-label='Exit'
                                                            value='Exit'
                                                            className='button button-m butto-secondary'
                                                            onClick={() => {
                                                                setShowUploadVideo(false);
                                                                setShowErrorMessage(false);
                                                                setMediaName('');
                                                                setMediaDescription('');
                                                                setIsValidVideo(true);
                                                            }}
                                                        >
                                                            Exit
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : errorUploadingVideo ?
                                                <div className="alert-container">
                                                    <div className="alert alert--error">
                                                        <div className="alert__content">
                                                            <p className="alert__heading">There was an error uploading the video. Please try it again.</p>
                                                        </div>
                                                        <button type="button" className="button-tertiary button-s button-icon alert__close" onClick={() => setErrorUploadingVideo(false)}>
                                                            <span className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        : ''
                    }
                    {uploadStarted ?
                        <div className="modal-container">
                            <div className="modal">
                                {!showProcessing ?
                                    <>
                                        <div className="modal__header">
                                            <h2 className="modal-title h4">File Upload Progress</h2>
                                        </div>
                                        <div className="modal__content">
                                            <AlertMessage
                                                messageHeader={process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}
                                                messageBody={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                                startDate={null}
                                                setShowWarningContainerMessage={''}
                                                messageType={"informational"}
                                                showButton={false}
                                            />
                                            <div className="file-uploads">
                                                {selectedUploads.length > 0 ?
                                                    <section>
                                                        <h5>{mediaType}</h5>
                                                        {fileProgressSelfVideo > 0 && videoCount < 1 ?
                                                            <>
                                                                <div className="message">Uploading video<progress className='progress-bar' value={fileProgressSelfVideo} max="100"></progress>  {fileProgressSelfVideo} of 100% completed  <span className="loader loader--s"></span></div>
                                                            </>
                                                            : ''
                                                        }
                                                        {selectedUploads.length > 0 && fileProgressSelfVideo === 0 ? <div className="message">Preparing video{selectedUploads.length > 1 ? 's' : ''} to be uploaded <span className="loader loader--s"></span></div> : ''}
                                                    </section>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="modal__header">
                                            <h2 className="modal-title h4 success">Video successfully updated.</h2>
                                        </div>
                                        <div className="modal__content">
                                            <p>{processingMessage}<span className="loader loader--s"></span></p>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        : ''
                    }
                </div>
            </main>
        </div>
    )
}


