import moment from "moment-timezone";

const formatDate = (date) => {
    if (date !== undefined) {
      return moment.utc(date).format('MM/DD/YYYY');
    }
};


const formatDateTime = (date) => {
  if (date !== undefined) {
    return moment(date).format('MM/DD/YYYY h:mm a');
  }
};

const dateField = (date) => {
  if (date !== undefined) {
    return moment(date).format('YYYY-MM-DD');
  }
};

export { formatDate, formatDateTime, dateField };
