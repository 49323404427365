/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import Header from '../libs/Header';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [emailTemp, setEmailTemp] = useState('');
    const [password, setPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showConfirmPassowrdError, setShowConfirmPassowrdError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showNoUpdate, setShowNoUpdate] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(true);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showWrongEmailFormat, setShowWrongEmailFormat] = useState(false);
    const {referencenumber} = useParams();


    useEffect(() => {
        checkTheReferencenumber(referencenumber);
    }, []);

    const checkTheReferencenumber = (referencenumber)  => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/checkReferenceNumber`, {referenceNumber: referencenumber}).then((response) => {
            if(response.data.length === 0){
                setShowConfirmPassowrdError(false);
                setConfirmPassword(false);
                setPasswordsMatch(true);
            } else {
                setEmail(response.data[0].email);
                setShowResetPassword(false);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const confirmEmail = () => {
        setShowWrongEmailFormat(false);
        setShowErrorMessage(false);
        const isValidEmail =  email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
       
        if(isValidEmail !== null){
            setEmailTemp(email);
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/checkEmail`, {email:email, sendEmailConfirmation: true}).then((response) => {
                if(response.data.recordsInserted === 1){
                    setShowErrorMessage(false);
                    setShowSuccessMessage(true);
                    setEmail('');
                } else {
                    setShowErrorMessage(true);
                }
                
            }).catch(function (error) {
                console.log(error);
            })
        } else{
            setShowWrongEmailFormat(true);
        }
    }

    const checkForm = () => {
        if(password.length === 0){
            setShowPasswordError(true);
        }
        if(password !== confirmPassword){
            setPasswordsMatch(false);
        }
        if(confirmPassword.length === 0){
            setShowConfirmPassowrdError(true);
        }
    }

    const deleteReferenceNumbers = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteReferenceNumber`, {referenceNumber: referencenumber}).then((response) => {
            return true;
        }).catch(function (error) {
            console.log(error);
        })
    }

    const handleAlertButtonClick = () => {
        navigate('/');
    };

    const sendPassword = () => {
        checkForm();
        if(password.length > 0 && confirmPassword.length > 0 && password === confirmPassword){
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/updatePassword`, {email:email, password:password, referencenumber: referencenumber}).then((response) => {
                if(response.data.affectedRows === 1){
                    setShowSuccessMessage(false);
                    setShowSuccess(true);   
                    deleteReferenceNumbers();
                    setShowNoUpdate(false);
                } else {
                    setShowNoUpdate(true);
                    setShowSuccess(false);
                }
                
            }).catch(function (error) {
                console.log(error);
            })
        }
    };
    

return(
    <div id="fazzad-site-container">
        <Header />
        <main id="fazzad-main">
            <div id="app-container">
                <div id="page-title">
                    <h1 className="h3">Reset your password</h1>
                </div>
                {showSuccess && (
                    <AlertMessage
                        messageHeader={`Password reset successfully.`}
                        messageBody={"Please use the new password on the <a href='/login'>login</a> page."}
                        startDate={null}
                        setShowWarningContainerMessage={setShowSuccess}
                        messageType={"success"}
                        showButton={true}
                        onButtonClick={handleAlertButtonClick}
                        showLoader={false}
                    />
                )}
                {showSuccessMessage && (
                    <AlertMessage
                        messageHeader={`We sent you a reset password email to ${emailTemp}`}
                        messageBody={"Please check your email inbox and click on the link to reset your password."}
                        messageType={"success"}
                        onButtonClick={handleAlertButtonClick}
                        showButton={true}
                        showLoader={false}
                    />
                )}
                {showNoUpdate && (
                    <AlertMessage
                        messageHeader={`There was an error trying to update your password. Please try it again.`}
                        messageBody={"If the problem persists, please contact us."}
                        startDate={null}
                        setShowWarningContainerMessage={null}
                        messageType={"error"}
                        onButtonClick={handleAlertButtonClick}
                        showButton={true}
                        showLoader={false}
                    />
                )}
                {!showSuccess && (
                    <div className="form-container">
                        <form action="">
                        {showResetPassword ?
                            <section>
                                <div className="form-row">
                                    <div className={`form-field text-input ${showWrongEmailFormat || showErrorMessage ? 'error' : ''}`}>
                                        <label htmlFor="first-name">Email</label>
                                        <input
                                            id='email'
                                            type='email'
                                            autoComplete='email'
                                            value={email}
                                            required
                                            onBlur={(e) => setEmail(e.target.value)}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onFocus={(e) => {setShowErrorMessage(false); setShowWrongEmailFormat(false); setShowSuccessMessage(false);}}
                                        />         
                                        {showErrorMessage ?
                                            <p className="validation-message">Login not found with the credentials provided. Please verify the information and try it again.</p>
                                        : ''
                                        }
                                        {showWrongEmailFormat ?
                                            <p className="validation-message">Please enter a valid email address</p>
                                        : ''
                                        }
                                        {showSuccess ?
                                            <div className="fz-alert alert-success">
                                                <span><i className="icon-sucess"></i><span>Password updated</span></span>
                                            </div>
                                        : ''
                                        }
                                    </div>
                                </div>
                                <div className="button-group">
                                    <button
                                        id='fz-submit'
                                        aria-label='Continue'
                                        className="button-l button-primary"
                                        onClick={(e) => {confirmEmail();}}
                                        type='button'
                                    >
                                        Continue
                                    </button>
                                    <button
                                        id='fz-submit'
                                        aria-label='Login credentials'
                                        className="button-l button-tertiary"
                                        onClick={() => navigate('/')}
                                        type='button'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </section>
                        :
                            <section className="emails">
                                <div className="form-row">
                                    <div className={`form-field text-input ${showPasswordError ? 'error' : ''}`}>
                                        <label htmlFor='new-password'>New password</label>
                                        <input id='new-password' type='password' value={password}  
                                            onBlur={(e) => {setPassword(e.target.value)}}
                                            onChange={(e) => {setPassword(e.target.value)}}
                                            onFocus={(e) => {setPasswordsMatch(true); setShowPasswordError(false)}}
                                        />
                                        {showPasswordError ?
                                            <p className="validation-message">Enter password.</p>
                                        : ''
                                        }
                                    </div>
                                </div>  
                                <div className="form-row">
                                    <div className={`form-field text-input ${!passwordsMatch || showPasswordError ? 'error' : ''}`}>
                                        <label htmlFor='confirm-new-password'>Confirm new password</label>
                                        <input id='confirm-new-password' type='password' value={confirmPassword}  
                                            onBlur={(e) => {setConfirmPassword(e.target.value)}}
                                            onChange={(e) => {setConfirmPassword(e.target.value)}}
                                            onFocus={(e) => {setPasswordsMatch(true); setShowConfirmPassowrdError(false)}}
                                        />
                                        {!passwordsMatch && !showPasswordError && !showConfirmPassowrdError ?
                                            <p className="validation-message">Passwords don't match.</p>
                                        : ''
                                        } 
                                        {showConfirmPassowrdError ? 
                                            <p className="validation-message">Enter confirm password.</p>
                                        : ''
                                        } 
                                    </div>
                                </div>  
                                <div className="button-group">
                                    <button
                                        id='fz-submit'
                                        aria-label='Reset your password'
                                        className="button-l button-primary"
                                        onClick={() => {sendPassword();}}
                                        type='button'
                                    >
                                        Reset password
                                    </button>
                                    <button
                                        id='fz-submit'
                                        aria-label='Login credentials'
                                        className="button-l button-tertiary"
                                        onClick={() => navigate('/')}
                                        type='button'
                                    >
                                        Cancel
                                    </button>
                                </div>
                                
                            </section>
                        }
                        </form>
                    </div>
                )}
            </div>
        </main>
    </div>
  )
}