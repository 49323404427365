import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import checkForMobile from '../../utils/checkForDeviceType';
import CustomHeader from '../libs/CustomHeader'
import ModalInfo from '../libs/ModalInfo';
import PageTitle from '../libs/PageTitle';

export default function HomeOwnersFeed() {
    const [isMobile, setIsMobile] = useState(false);
    const [packages, setPackages] = useState([]);
    const [showMore, setShowMore] = useState({});
    const [leadId, setLeadId] = useState(0);
    const [showNoPackages, setShowNoPackages] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const {packageName} = useParams();
    const s3BucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
   
    useEffect(() => {
        sessionStorage.setItem('userType', 500003);
        setIsMobile(checkForMobile());
        getHomeOwnerPackages(packageName, 0);
        document.body.classList.add('dark-mode');
        return () => {
            document.body.classList.remove('dark-mode');
        };
    }, []);

    const getPackageDescription = (packageDesc, index) => {
		if(!showMore[index]){
            if(packageDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH){
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH) + '...';
            } else {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
            }
		} else {
            return packageDesc;
		}
	}

    const handleReadMore = (index) => {
		setShowMore({...showMore, [index]: !showMore[index]});
	}

    const getHomeOwnerPackages = (packageName, type)  => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/homeOwnersPackages`, {packageName: packageName}).then((response) => {
            if(response.data.length > 0){
                setShowNoPackages(false);
                sessionStorage.setItem("leadId", response.data[0].id);
                sessionStorage.setItem('packageName', response.data[0].packageName);
                setPackages(response.data);
            } else {
                setShowNoPackages(true);
            } 
        }).catch(function (error) {
            console.log('tags error = ',error);
        })
    }

    return(
    <div id="fazzad-site-container">
        <CustomHeader
            packageName={packageName}
            isFreeLead={true}
            leadId={leadId}
        />
        <main id="fazzad-main">
            <div id="app-container">
                <div id="tabpanel-1" role="tabpanel" tabIndex="0" aria-labelledby="tab-1" >
                    <PageTitle
                        headerText={"Welcome to Fazzad!"}
                        subHeaderText={""}
                        showSubHeader={true}
                    />
                    
                    {showNoPackages ?
                        <AlertMessage
                            messageHeader={"The survey package is not available."}
                            messageBody={""}
                            startDate={null}
                            setShowWarningContainerMessage={''}
                            messageType={"informational"}
                            showButton={false}
                        />
                    : ''}
                    
                    <div id="homefeed">
                        {packages.map((pck, index) => {  
                            return(                                                   
                                <div className="homefeed-item" key={`maindiv_${index}`}>
                                    <div className="homefeed-item__content" key={`maindivfeed_${index}`}>
                                        <h2 className="h4 package-name">{packages.length > 0 ? <span>{packages[0].address}{packages[0].address2.length > 0 ? ` ${packages[0].address2}` : ''}<br />{packages[0].city}, {packages[0].state} {packages[0].zip}</span>: ''}</h2>
                                        <h3 className="h5">Scope of work</h3>
                                        <div className="package-scope" key={`scope${index}`}>
                                            <p>
                                                {getPackageDescription(pck.projectDescription, pck.id)}
                                                {pck.projectDescription.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH && (
                                                    <>
                                                    <br />
                                                    <a href="/" onClick={(e) => { e.preventDefault(); handleReadMore(pck.id); e.target.blur();}}>
                                                        {showMore[pck.id] ? 'Read less' : 'Read more'}
                                                    </a>
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="homefeed-item__image">
                                        <img className='rounded' src={`${s3BucketPrefix}/${pck.packageName}/free-lead-image/${pck.videoFileName}`} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </main>
        {isDownloading && ( 
            <ModalInfo
                messageHeader={"Downloading your package..."}
                messageBody={`Files will be saved on your ${isMobile ? 'device' : 'computer'}.`}
                messageType={"informational"}
                showCloseButton={false}
                showLoader={true}
            />
        )} 
    </div>
  )
}
