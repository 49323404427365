import Axios from 'axios';
const likeVideo = async (pck) => {
    try {
        const response = await Axios.post(`${process.env.REACT_APP_LOCALHOST}/likeVideo`, { package: pck });
        let className = 'thumb-like';
        if (response.data.msg === 'disliked') {
            className = '';
        } else {
            className = 'thumb-like';
        }
        return {"msg": className};
    } catch (error) {
        console.log(error);
        return {"msg": ""};
    }
};

export default likeVideo;