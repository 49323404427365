import React, { useRef, useEffect, useState } from 'react';
import { getObjectSignedUrl } from "../../utils/awsHelpers";
import { ReactComponent as ImagePlaceholder } from "../../images/image-placeholder.svg";

export const VideoWithPresignedUrl = ({ bucketName, fileKey, fileType, videoUrl, postUrl, setPreSignedUrl }) => {
    const vidRef = useRef(null);
    const [url, setUrl] = useState(videoUrl);

    const fetchPresignedUrl = async (bucketName, fileKey, fileType) => {
        const _url = await getObjectSignedUrl(bucketName, fileKey, fileType);
        setUrl(_url);
        setPreSignedUrl(_url);
    }


    // Fetch the initial pre-signed URL on component mount
    useEffect(() => {
        if (!videoUrl) {
            fetchPresignedUrl(bucketName, fileKey, fileType);
        }

    }, [bucketName, fileKey, fileType]);

    return <>
        {url && <video ref={vidRef} loading="lazy" src={url || ''} alt={fileKey} className='rounded'
            poster={postUrl}
            controls />}
        {!url && <div style={{ backgroundColor: '#EFF1F3', position: "relative" }}><ImagePlaceholder /></div>}
    </>;
}