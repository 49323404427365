const checkEmail = (email) => {
    if(email.trim().length > 0){
        const matchEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(Array.isArray(matchEmail) && matchEmail.length > 0){
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

const checkBothPasswords = (pwd1, pwd2) => {
    let isValid = true;
    if(pwd1 !== pwd2){
        isValid = false;
    }
    return isValid;
}

const checkCompany = (companyName) => {
    let isValid = true;
    if(companyName.trim().length === 0){
        isValid = false;
    }
    return isValid;
}

const checkAddress = (address) => {
    let isValid = true
    if(typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean'){
        if(address.trim().length === 0){
            isValid = false
        }
    }else {
        isValid = false;
    }
    return isValid;
}

const checkFirstName = (firstName) => {
    let isValid = true
    if(firstName.trim().length === 0){
        isValid = false;
    }
    return isValid; 
}

const checkLastName = (lastName) => {
    let isValid = true;
    if(lastName.trim().length === 0){
        isValid = false;
    }
    return isValid;
}

const checkLicense = (license) => {
    let isValid = true;
    if(license.trim().length === 0){
        isValid = false
    }
    return isValid;
}

const checkCity = (city) => {
    let isValid = true
    if(city.trim().length === 0){
       isValid = false
    }
    return isValid;
}

const checkPassword = (password) => {
    let isValid = true
    if(password.trim().length === 0){
        isValid = false;
    }
    return isValid;
}

const checkConfirmPassword = (confirmPassword) => {
    let isValid = true
    if(confirmPassword.trim().length === 0){
       isValid = false;
    }
    return isValid;
}

const checkZip = (zip) => {
    let isValid = true;
    if(zip.trim().length > 0){
        if(zip.trim().length < 5){
            isValid = false;
        } else {
            if(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) === false){
                isValid = false;
            } else {
                isValid = true;
            }
        }
    }else {
        isValid = false;
    }
    return isValid;
};

const checkState = (state) => {
    let isValid = true;
    if(state.trim().length === 0){
       isValid = false;
    }
    return isValid;
}

const checkPhoneNumber = (phone) => {
    let isValid = true;
    if(phone.trim().length > 0){
        if(!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)){
            isValid = false;
        }
    } else {
        isValid = false;
    }
    return isValid;
};

const formatPhoneNumber = (phone, callback) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const formattedNumber = ['(', match[2], ') ', match[3], '-', match[4]].join('');
        if (callback && typeof callback === 'function') {
            callback(formattedNumber); // Call the callback if it's a function
        } else {
            return formattedNumber; // Return the formatted number directly
        }
    } else {
        return phone; // Return the original phone number if it doesn't match the pattern
    }
}


const clearEmojis = (string) => {
    return string.replace(/\p{Emoji_Presentation}|\p{Extended_Pictographic}/gu, '');
}

const checkStartDate = (datestart) => {
    let isValid = true;
    if(datestart.trim().length > 0){
        const startDateArray = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(datestart);
        if(startDateArray){
            isValid = true
        }else{
            isValid = false;
        }
    } else {
        isValid = false;
    }
    return isValid;
}

const checkDropBoxLink = (link) => {
    let isValid = true;
    if(link !== ''){
        const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        const isValid = urlPattern.test(link);
        if(isValid){
            const url = new URL(link);
            if(url.host !== 'www.dropbox.com'){
                isValid = false;
            } else{
                isValid = true;
            }
        }else {
            isValid = false;
        }
    } else{
        isValid = false;
    }
    return isValid;
}

const checkScopeOfWork = (scopeofwork) => {
    let isValid = true;
    if(scopeofwork.trim().length === 0){
        isValid = false;
    }else {
        isValid = true;
    }
    return isValid;
}

export {
    checkEmail,
    checkBothPasswords,
    checkCompany,
    checkAddress,
    checkFirstName,
    checkLastName,
    checkLicense,
    checkCity,
    checkPassword,
    checkConfirmPassword,
    checkZip,
    checkState,
    checkPhoneNumber,
    formatPhoneNumber,
    clearEmojis,
    checkStartDate,
    checkDropBoxLink,
    checkScopeOfWork,
};
