import React from 'react';
function ProgressBar({ fileProgress, fileType='video' }) {
    return (
        <small className='fz-align-center'>
            Uploading {fileType}&nbsp; &nbsp; &nbsp;
            <progress className='progress-bar' value={fileProgress} max='100' />&nbsp; &nbsp; &nbsp;
            {parseInt(fileProgress)} of 100% completed&nbsp;&nbsp;&nbsp;
            <span className="loader loader--s"></span>
        </small> 
    );
}

export default ProgressBar;
