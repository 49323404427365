import React, { useEffect, useState, useRef } from 'react';
import AlertMessage from './AlertMessage';
import Axios from 'axios';

import * as formValidator from '../../utils/formValidation';
import uuid from 'react-uuid';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { S3 } from '@aws-sdk/client-s3';
import { useNavigate } from 'react-router-dom';
import { Upload } from '@aws-sdk/lib-storage';
import { getPutObjectSignedUrl } from "../../utils/awsHelpers";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function MediaLibrary(props) {
    const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
    const [fileProgressSelfVideo, setFileProgressSelfVideo] = useState(5);
    const [fileToBeUploaded, setFileToBeUploaded] = useState('');
    const [hasVideoDescription, setHasVideoDescription] = useState(true);
    const [hasVideoName, setHasVideoName] = useState(true);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [showVideos, setShowVideos] = useState(true);
    const [singleVideo, setSingleVideo] = useState([]);
    const [showProcessingVideo, setShowProcessingVideo] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showNoVideos, setShowNoVideos] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [showFormErrorMessage, setShowFormErrorMessage] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [videosResponses, setVideosResponses] = useState([]);
    const [videoName, setVideoName] = useState('');
    const [videoDescription, setVideoDescription] = useState('');
    const proTestimonialProfileBucketName = 'fazzad-pros-portfolio-testimonial-videos-source';
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_BUCKET_PROS_VIDEOS_PREFIX;
    let tempVideoCounter = 0;
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    const videoRef = useRef(null);

    const config = {
        bucketName: proTestimonialProfileBucketName,
        region: process.env.REACT_APP_REGION
    };

    const saveVideoUploaded = (userId, uploadType, uploadFileName, portfolioName, projectDescription, referenceNumber) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveProVideos`, { userId: userId, videoType: uploadType, uploadFileName: uploadFileName, portfolioName: portfolioName, projectDescription: projectDescription, referenceNumber: referenceNumber }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    useEffect(() => {
        getVideos(props.userId, props.videoType.toLowerCase());
    }, []);


    const checkUploadForm = () => {
        let isValid = true;
        if (videoDescription.trim().length === 0) {
            setHasVideoDescription(false);
            isValid = false;
        } else {
            setHasVideoDescription(true);
        }
        if (videoName.trim().length === 0) {
            setHasVideoName(false);
            isValid = false;
        } else {
            setHasVideoName(true);
        }
        return isValid;
    }

    const deleteVideo = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteProUploadsVideos`, { singleVideo: singleVideo }).then((response) => {
            setShowDeleteModal(false);
            getVideos(props.userId, props.videoType.toLowerCase());
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const sendUploadError = (err) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendGenericErrorEmail`, { errorSubject: 'Error Uploading Testimonial video', errorMessage: 'Check the cors issues' }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('error sending generic error email = ', error);
        })
    }

    const changeHandlerUploads = (event) => {
        event.preventDefault();
        setShowErrorMessage(false);
        const chosenFile = event.target.files[0].name;
        setFileToBeUploaded(chosenFile);
        const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;

        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidVideo(false);
        } else {
            setIsValidVideo(true);
            setSelectedUploads(event.target.files);
        }
        videoRef.current.blur();
    }

    async function uploadWithSignedUrl(fileData, bucketName, key, onPrgress) {
        const signedUrl = await getPutObjectSignedUrl(bucketName, key, fileData.type);

        if (!signedUrl) {
            throw new Error("cannot generate signed url");
        }

        const xhr = new XMLHttpRequest();

        xhr.open('PUT', signedUrl, true);
        // Set the appropriate headers
        xhr.setRequestHeader('Content-Type', fileData.type);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                const myTotal = progress.toString().substring(0, 4);
                onPrgress && onPrgress(myTotal);
                console.log(myTotal);
            }
        };

        xhr.send(fileData);

        // Handle errors
        xhr.onerror = function (ev) {
            console.error('Error uploading file.', ev);
        };

        xhr.onload = function () {
            if (xhr.status === 200) {
                console.log('Request completed successfully:', xhr.responseText);
            } else {
                console.error('Request failed with status:', xhr.status);
                throw new Error(xhr.error);
            }
        };
    }

    const handleUpload = async () => {
        setShowFormErrorMessage(false);
        const uuids = uuid();
        tempVideoCounter = 0;
        let selfVideoArray = [];
        try {
            if (videoName.trim().length > 0 && videoDescription.trim().length > 0) {
                if (selectedUploads.length > 0) {
                    if (checkUploadForm()) {
                        setFileProgressSelfVideo(0);
                        setShowErrorMessage(false);
                        setShowProcessingVideo(true);
                        setShowProcessing(false);
                        setErrorUploadingVideo(false);
                        selfVideoArray = [...selectedUploads];
                        let videoArray = [];
                        videoArray = selfVideoArray.splice(0, 1);
                        try {
                            await Promise.all(
                                videoArray.map(async (file) => {
                                    const fileName = cleanMediaUrl(file.name);
                                    const key = `${uuids}/${fileName}`;
                                    await uploadWithSignedUrl(file, proTestimonialProfileBucketName, key, setFileProgressSelfVideo);
                                    setShowProcessingVideo(false);
                                    setShowInfo(false);
                                    setShowProcessing(true);
                                    setFileProgressSelfVideo(0);
                                    saveVideoUploaded(props.userId, props.videoType.toLowerCase(), fileName, videoName, videoDescription, uuids);
                                    setTimeout(() => {
                                        setShowInfo(true);
                                        setShowProcessing(false);
                                        setShowVideos(true)
                                        setShowUploadVideo(false);
                                        getVideos(props.userId);
                                    }, process.env.REACT_APP_TIEMOUT);
                                })
                            );
                        } catch (e) {
                            setShowProcessingVideo(false);
                            setVideoName('');
                            setVideoDescription('');
                            setFileToBeUploaded('');
                            setSelectedUploads([]);
                            setErrorUploadingVideo(true);
                            sendUploadError(e);
                        }
                    }
                } else {
                    setShowErrorMessage(true);
                }
            } else {
                setShowFormErrorMessage(true);
            }
        } catch (e) {
            setShowInfo(true);
            setShowUploadVideo(false);
            setShowProcessing(false);
            console.error(`Uploading Videos file error: ${e}`);
        }
    };

    const closeAlertMessage = () => {
        setShowFormErrorMessage(false);
    }

    const getVideos = (userId, videoType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, { userId: userId, videoType: videoType }).then((response) => {
            setVideosResponses(response.data);
            if (response.data.length === 0) {
                setShowNoVideos(true);
            } else {
                setShowNoVideos(false);
            }
        }).catch(function (error) {
            console.log('error getting pros videos = ', error);
        })
    }

    const checkVideoName = (videoname) => {
        if (videoname.trim().length === 0) {
            setHasVideoName(false);
        } else {
            setHasVideoName(true);
        }
    }

    const checkVideoDescription = (videodescription) => {
        if (videodescription.trim().length === 0) {
            setHasVideoDescription(false);
        } else {
            setHasVideoDescription(true);
        }
    }

    return (
        <div>
            {showVideos ?
                <>
                    <button type="button" className="fz-block-sm button-m button-primary" onClick={(e) => { setShowUploadVideo(true) }}>
                        <span className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        <span className="text">Upload {props.videoType.toLowerCase()} video</span>
                    </button>
                    <p>Upload your {props.videoType.toLowerCase()} videos here.</p>
                    <div id="po-container">
                        <div id="po-feed-container" className="reel-view">
                            {videosResponses.map((pck, index) => {
                                return (
                                    <div className="po-feed-item" key={`resp_${pck.id}`}>
                                        <div className="video rounded" key={`video_${pck.id}`}>
                                            <video
                                                className='rounded'
                                                src={`${s3BucketVideoPrefix}/${pck.referenceNumber}/${pck.videoFileName}`}
                                                poster={`${s3ThumbnailBucketPrefix}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.referenceNumber}/${pck.videoFileName.replace('mp4', 'jpg')}`}
                                                controls
                                            />
                                        </div>
                                        <div className="content">
                                            <div className="info">

                                                <p>{pck.projectName}</p>
                                                <p>{pck.projectDescription}</p>
                                            </div>
                                            <div className="actions">
                                                <a href=""
                                                    onClick={(e) => { e.preventDefault(); setSingleVideo(pck); setShowDeleteModal(true); }}
                                                    className="icon-black button button-s button-destructive button-icon delete" title="Delete">
                                                    <span className="icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            }
                            {showDeleteModal ?
                                <div id="modal-5" className="modal-container">
                                    <div className="modal">
                                        <div className="modal__header">
                                            <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                        </div>
                                        <div className="modal__action">
                                            <div className="button-group">
                                                <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false) }}>No, Cancel</button>
                                                <button className="button button-m button-destructive" type="button" onClick={() => deleteVideo()}>Yes, Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            {showNoVideos ?
                                <AlertMessage
                                    messageHeader={`You don't have any ${props.videoType} videos uploaded.`}
                                    messageBody={""}
                                    startDate={null}
                                    setShowWarningContainerMessage={''}
                                    messageType={"informational"}
                                    showButton={false}
                                />
                                : ''
                            }
                        </div>
                    </div>
                </>
                : ''
            }
            {showUploadVideo ?
                <>
                    <div id="modal-5" className="modal-container">
                        <div className="modal">
                            <div className="modal__header">
                                <h2 className="modal-title h4">{props.videoType} file upload</h2>
                            </div>
                            <div className="modal__content">
                                {
                                    !showProcessingVideo ?
                                        <AlertMessage
                                            messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                            messageBody={""}
                                            startDate={null}
                                            setShowWarningContainerMessage={''}
                                            messageType={"informational"}
                                            showButton={false}
                                        />
                                        : ''
                                }
                                {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                    <>
                                        <div className={`form-field text-input ${!hasVideoName ? 'error' : ''}`}>
                                            <label htmlFor="videoName">{props.videoType} name</label>
                                            <input
                                                name="videoName"
                                                id="videoName"
                                                type="text"
                                                aria-required="true"
                                                placeholder=""
                                                value={videoName}
                                                onFocus={(e) => { setHasVideoName(true); setShowFormErrorMessage(false); }}
                                                onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'videoName'); checkVideoName(e.target.value); }}
                                                onChange={(e) => { setVideoName(e.target.value); }}
                                            >
                                            </input>
                                            {!hasVideoName ?
                                                <p className="validation-message">Enter {props.videoType} name.</p>
                                                : ''}
                                        </div>
                                        <div className={`form-field text-area ${!hasVideoDescription ? 'error' : ''}`}>
                                            <label htmlFor="video-description">{props.videoType} description</label>
                                            <div className="input-container">
                                                <textarea
                                                    name="Video description"
                                                    id="video-description"
                                                    type="text"
                                                    aria-required="true"
                                                    placeholder=""
                                                    value={videoDescription}
                                                    onFocus={(e) => { setHasVideoDescription(true); setShowFormErrorMessage(false); }}
                                                    onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'videoDescription'); checkVideoDescription(e.target.value); }}
                                                    onChange={(e) => { setVideoDescription(e.target.value); }}
                                                >
                                                </textarea>
                                            </div>
                                            {!hasVideoDescription ?
                                                <p className="validation-message">Enter video description</p>
                                                : ''
                                            }
                                        </div>
                                    </>
                                    : ''
                                }

                                {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                    <div className={`form-field file-input ${!isValidVideo || showErrorMessage ? 'error' : ''}`}>
                                        <input type="file" id="fileupload" className="file-input__input" onChange={(e) => { setShowErrorMessage(false); setIsValidVideo(true); changeHandlerUploads(e) }} ref={videoRef} />
                                        <label htmlFor="fileupload" className="button button-m button-tertiary">
                                            <span className="icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="text">Choose video&hellip;</span>
                                        </label>
                                        {showErrorMessage ?
                                            <p className="validation-message">Choose a video first.</p>
                                            : ''
                                        }
                                        {!isValidVideo ?
                                            <p className="validation-message">{videoFormatError}</p>
                                            : ''
                                        }
                                        {fileToBeUploaded !== '' ? <p>{fileToBeUploaded}</p> : ''}
                                    </div>
                                    : errorUploadingVideo ?
                                        <div className="alert-container">
                                            <div className="alert alert--error">
                                                <div className="alert__content">
                                                    <p className="alert__heading">There was an error uploading the video. Please try it again.</p>
                                                </div>
                                                <button type="button" className="button-tertiary button-s button-icon alert__close" onClick={() => setErrorUploadingVideo(false)}>
                                                    <span className="icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        : ''
                                }

                                <div className='file-input-container block'>
                                    <div className='file-input-container block'>
                                        {
                                            showProcessingVideo ?
                                                <span>
                                                    <small>
                                                        <span className='gray-600'>
                                                            Uploading video  <progress className='progress-bar' value={fileProgressSelfVideo} max='100'></progress> &nbsp;
                                                            {fileProgressSelfVideo} of 100% completed
                                                        </span>&nbsp;&nbsp;&nbsp;<span className="loader loader--s"></span>
                                                    </small>

                                                </span>
                                                : ''
                                        }
                                        {showProcessing ?
                                            <AlertMessage
                                                messageHeader={process.env.REACT_APP_PROCESSING_VIDEO_MESSAGE}
                                                messageBody={process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}
                                                startDate={null}
                                                setShowWarningContainerMessage={''}
                                                messageType={"success"}
                                                showButton={false}
                                                showLoader={true}
                                            />
                                            : ''
                                        }
                                    </div>
                                </div>
                                {showFormErrorMessage ?
                                    <AlertMessage
                                        messageHeader={`${props.videoType} name and description are required.`}
                                        messageType={"error"}
                                        startDate={null}
                                        messageBodyContinuation={null}
                                        showButton={true}
                                        setShowWarningContainerMessage={null}
                                        onButtonClick={closeAlertMessage}
                                        showLoader={false}
                                    />
                                    : ''
                                }
                            </div>
                            {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                <div className="modal__action">
                                    <div className="button-group">
                                        <button
                                            type='button'
                                            id='uploadbutton'
                                            className='button button-m button-primary'
                                            onClick={() => { handleUpload() }}
                                        >
                                            Upload video
                                        </button>

                                        <button
                                            type='reset'
                                            aria-label='Exit'
                                            value='Exit'
                                            className='button button-m butto-secondary'
                                            onClick={() => {
                                                setShowUploadVideo(false);
                                                setShowErrorMessage(false);
                                                setVideoName('');
                                                setVideoDescription('');
                                                setIsValidVideo(true);
                                            }}
                                        >
                                            Exit
                                        </button>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </>
                : ''
            }
        </div>
    )
}